import React,{ useState, useEffect, useRef }  from 'react';
import {Header,Form,Popup,Menu,Segment, Label} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import TabSources from './TabSources'

import {  Switch,Route, useHistory, useParams} from "react-router-dom";
import {useRoot} from '../../lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'



const DListEdit=(props)=>
{

    const {UID,sources,number,functions,achievements,setModalParams,timestamp}=props

    const {tab}= useParams()
    const texts=useTexts('/edit/dlist')
    const history=useHistory()
    

    const [root]=useRoot()
    const {apiSelect, apiInsert, apiDelete}=useApi()
      const handleItemClick = (e, { name }) =>
    {

            history.push(`/edit/dlist/${name}/${UID}`)
    }


    

    const deleteFilter=async (ev,{name})=>
    {
        if(window.confirm(texts.confirmDeleteFilter))
        {
            const result=await apiDelete('/kpe20/filter/'+name)

        }
    }

 
  

    return(
    <>
        <Header as='h3' content={texts.HeaderEditList}/>

        
            <>
                <Menu pointing stackable attached='top'>
         
                <>
                    <Menu.Item
                        content={<>
                                    {texts.TabSource}
                                    {number.include=== 0 ? '' : <Label  content={number.include}/>}
                            </>}
                        name='sources'
                        active={tab === 'sources'}
                        onClick={handleItemClick}
                        key='1'

                    />


                    {! number.include>0 ? '' : <>
                        <Menu.Item
                            content={<>
                                {texts.TabExclude}
                                {number.exclude=== 0 ? '' : <Label  content={number.exclude}/>}
                        </>}
                            name='remove'
                            active={tab === 'remove'}
                            onClick={handleItemClick}
                            key='2'

                        />
                        <Menu.Item
                            content={<>
                                {texts.TabIntersect}
                                {number.intersect=== 0 ? '' : <Label  content={number.intersect}/>}
                            </>}
                            name='filter'
                            active={tab === 'filter'}
                            onClick={handleItemClick}
                            key='3'
                        />
                </>}
            </>
         
        </Menu>
        <Segment attached='bottom'>
            <Switch>    
                <Route exact path={'/edit/dlist/sources/:UID'}>
                    <TabSources 
                        timestamp={timestamp} 
                        {...props}
                        filterType='include'
                        deleteFilter={deleteFilter}
                        sources={sources.filter(el=>(el.Type==='include'))}

                        />
                </Route>
                <Route exact path={'/edit/dlist/remove/:UID'}  >
                    <TabSources 
                        timestamp={timestamp} 
                        {...props}
                        filterType='exclude'
                        deleteFilter={deleteFilter}
                        sources={sources.filter(el=>(el.Type==='exclude'))}

                    />
                </Route>
                <Route exact path={'/edit/dlist/filter/:UID'} >
                    <TabSources 
                        timestamp={timestamp} 
                        {...props}
                        filterType='intersect'
                        deleteFilter={deleteFilter}
                        sources={sources.filter(el=>(el.Type==='intersect'))}

                        />
                </Route>
            
            </Switch>
        </Segment>
        </>


        </>

    )
}
export default DListEdit