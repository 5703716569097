import React, {useState, useEffect} from 'react'
import {Container, Button, Icon, Form, Image, Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js'
import useTexts from '../../../lib20/useTexts'
import {api,baseUrl} from '../../../config.jsx'
import { useConfig, useSetConfig } from '../../../lib20/KPE20context'
/*const mpFaceDetection = window;

const onFaceDetected = (results)=>
{
    // crop now image for round avatar here
    console.log(results)

}*/


const EditAvatar = (props) => {
    const config = useConfig()
    const setConfig = useSetConfig()

    const {apiSelect,apiGetFile,apiDelete, apiPutFile,apiUpdate}=useApi()
    const [loading, setLoading]=useState(false)
    const [avatar,setAvatar]=useState()
    const [filename,setFilename]=useState('')
    const texts=useTexts('/show/person')
    const {children, modalParams, resetModal, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]
    const uploadAvatar=async (e)=> {
        setLoading(true)
       const avatarFile = e.target.files[0]
       setAvatar(avatarFile)
       setFilename(avatarFile.name)
   /*    // wir machen noch den small avatar
        const faceDetection = new mpFaceDetection.FaceDetection(
            {
                locateFile: (avatarFile) => 
                {
                    return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${avatarFile}`;
                }
          })
          faceDetection.onResults(onFaceDetected)*/
         setLoading(false)
    }



    const deleteAvatar=async (e) => {
        //  delete all avatar files
        const current=await apiSelect(`/kpe20/files/${UID}?prefix=avatar`)
        const res=await apiDelete(`/kpe20/files/${UID}`,{files:current.files.map(el=>el.name.replace(UID+'/',''))})
        if(!res.success)
        {
            window.alert('error delete file: '+res.message)
        }

     //   apiDeleteFile('/kpe20/files/'+UID+'/avatar/avatar.png')
        const res2=await apiUpdate('/kpe20/person/'+UID,{avatar:null})
        setAvatar(null)
        setFilename('')
        resetModal()
        if(!res2.success)
            window.alert('error update person: '+res2.message)

    }

    useEffect(()=>
    {
        const getFile=async ()=>
        {
            const fileName=person.Data.avatar.replace(`https://${api}.${baseUrl}/kpe20/files/${UID}/`,'')
            const result=await apiGetFile(`/kpe20/files/${UID}/${fileName}`)
            if(result && result.size)
                setAvatar(result)
        }
        if(person && person.Data && person.Data.avatar)
            getFile()
       
    },[person])

    modalSubmit.current=async ()=> {
        const result=await apiPutFile('/kpe20/files/avatar/'+UID,avatar,filename,'','kpe20') 
        const timestamp = Date.now()
        await apiUpdate('/kpe20/person/'+UID,{avatar:result.url+'?='+timestamp})

        // Funktioniert irgendwie nicht, sollte aber. Moritz

        if (UID === config.orgaUser.UID) {
            setConfig({...config, 
                orgaUser:{...config.orgaUser,
                    Data:{...config.orgaUser.Data, 
                        avatar:result.url
                    }
                }
            })
        }

        resetModal()
    }
    


    return(<>

        <Modal.Header as='h3'>{texts.EditAvatar} {person.Display}</Modal.Header>
        <Modal.Content>
            <Image src={/*`https://kpe20.${baseUrl}/kpe20/files/${person.Data.avatar}`*/ 
                    avatar && avatar.size>0 
                    && URL.createObjectURL(avatar) 
                } 
                size='small' disabled={loading} bordered centered style={{marginBottom:'20px'}}/>

            <Button.Group widths={2}>
                <Button  htmlFor='file2' type='button' as='label' width={1} loading={loading}>
                    {loading===false && <>
                        <input type='file' id='file2' accept='image/*' style={{display: 'none'}} onChange={uploadAvatar}/>
                        <Icon name='upload' color='green'/> Bild hochladen
                    </>}
                </Button>
                <Button content='Bild löschen' onClick={deleteAvatar} width={1} disabled={loading} negative/>
            </Button.Group>
        </Modal.Content>
        {children}
    </>)
}
export default EditAvatar
