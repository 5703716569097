import React, {useState, useEffect,useMemo} from 'react'
import {Form, Modal, Item,Segment} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../../lib20/useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useCheckForm} from '../../../../Hooks/useCheckForm'


export const FeeAddressFamily = (props) =>
{
    const {modalParams} = props
    const {UIDperson} = modalParams[0]
    const [family,setFamily]=useState(null)
    const {apiSelect}=useApi()

    useEffect(()=>{
        const getFamily= async()=>
        {
            const result=await apiSelect('/kpe20/family/'+UIDperson,'kpe20')
            if(result.success)
            {
                setFamily(result.result)
            }
            else
            {
                const rtitle =await apiSelect(`/kpe20/Value2Title/${UIDperson}`)
                setFamily({fees:[], feeAddress:{UIDperson,type:'person',Display: rtitle[0].title}})
            }
        }
        if(UIDperson)
            getFamily()
    },[UIDperson])

    const newProps= {
        ...props,
        modalParams: modalParams.map((mp,i)=> (i>0 ? mp : {...mp,family: family}))
    }

    if(family)
        return <ModalFeeAddress {...newProps}/>
    else
        return  <></>

}


const ModalFeeAddress =(props)=> {

    const {children,setModalParams, resetModal, modalParams, modalSubmit}=props

    const {apiSelect, apiUpdate,apiInsert,apiDelete}=useApi()
    const texts=useTexts('/show/person/family')
    const {family:providedFamily, UIDperson, exitHook}=modalParams[0]
   
    const [family,setFamily]=useState(providedFamily)


    modalSubmit.current=async ()=> {
        if(!family.feeAddress)
        {
            window.alert(texts.noFeeAddress)
            return
        }
        if(family.UID)
        {
            const res=await apiUpdate(`/kpe20/family/${family.UID}`,{feeAddress: family.feeAddress})
            if(res.success)
            {
                if(exitHook)
                exitHook()
                resetModal()
            }
        }
        else
        {
            const res= await apiInsert(`/kpe20/family/null/${UIDperson}`,{family})
            if(res.success)
            {
                resetModal()
                if(exitHook)
                exitHook()

            }
        }
        

    }
    const addressatOptions= useMemo(()=>(
        family.fees.length>0 ?
        [
            ...family.fees.filter(fee=>fee).map(fee=>({
                key: 'parents'+fee.UID,
                text: `${texts.parentsFrom} ${fee.Display}`,
                value: 'parents'+fee.UID
            })),
            ...family.fees.filter(fee=>fee).map(fee=>({
                key: fee.UID,
                text: fee.Display,
                value: fee.UID
            })),
         
        ]
        : 
        [
            {
                key: family.feeAddress.UID,
                text: family.feeAddress.Display,
                value: family.feeAddress.UID
            }
        ]
        ),[family])

    const {adressatIntern, adressatExtern}=useMemo(()=>{
        // calculates the selection value for the dropdown from the given data
        const familyAdressat=family ? family.fees?.filter(f=>f).find(f=>f.UID===family.feeAddress.UID) : {UID:UIDperson}
       
        return { adressatIntern: 
                    familyAdressat ? family.feeAddress?.type!=='parents' ? familyAdressat.UID : 'parents'+familyAdressat.UID :family.feeAddress?.type==='person' ? family.feeAddress?.UID : null ,
                 adressatExtern : 
                    familyAdressat ? null : family ? family.feeAddress.UID: null
                }
    },[family])
   
    const onChange=async (ev,{value,name})=>{
        if(name==='adressatDropdown')
        {
            if(value)
            {
                const {type,pUID}=/^(?<type>parents|)(?<pUID>.*)/gm.exec(value).groups
                const fAdressat=family.fees.filter(f=>f).find(f=>f.UID===pUID)
                setFamily(fam=>({...fam,feeAddress:{
                    UID:pUID,
                    type: type,
                    Display: type==='parents' ?  `${texts.parentsFrom} ${fAdressat.Display}` : fAdressat.Display
                }}))
               
                   
            }
            else
            {
                setFamily(family=>({...family,feeAddress:{}}))
            }
        }
        if(name==='adressatSearch')
        {
            if(value)
            {
                const rtitle =await apiSelect(`/kpe20/Value2Title/${value}`)
                setFamily(fam=>({...fam,feeAddress:{
                    UID:value,
                    type: 'person',
                    Display: rtitle[0].title
                }}))

            }
        }
    }
    console.log(family)
    return(<>

        <Modal.Header as='h3'>{texts.EditFamily}</Modal.Header>
        <Modal.Content>
            <Form>
                <Segment>
                    {texts.hintAdressatIntern}
                </Segment>
                <Form.Dropdown
                    name='adressatDropdown'
                    value={adressatIntern}
                    options={addressatOptions}
                    placeholder={texts.placeholderAdressatIntern}
                    label={texts.labelAdressatIntern}
                    selection
                    clearable
                    onChange={onChange}
                />
                 <Segment>
                    {texts.hintAdressatExtern}
                </Segment>
                <Form.Field
                        label={texts.labelAdressatExtern}
                        placeholder={texts.placeholderAdressatExtern}
                        control={SearchSelect}
                        api="kpe20"
                        SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['person','extern'])}
                        Value2TitleUrl='/kpe20/Value2Title'
                        value={adressatExtern}
                        autoSelect
                        onChange={onChange}
                        name="adressatSearch"    
                        SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                />


            
                
            </Form>
        </Modal.Content>
        {children}
    </>)
}
export default ModalFeeAddress