import React, { useEffect, useState, useMemo } from 'react'
import {Container, Form, Button, Dropdown, Flag, Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useConfig} from '../../../../lib20/KPE20context'
import useTexts from '../../../../lib20/useTexts'
import parsePhoneNumber, {AsYouType} from 'libphonenumber-js/max' //https://www.npmjs.com/package/libphonenumber-js
import countries from '../../../../lib20/countries.json'
import {useCheckFormArray} from '../../../../Hooks/useCheckFormArray'
import {telTypes} from './types.js'


const EditPhone =(props)=> {

    const {apiUpdate}=useApi()
    const config=useConfig()

    const texts=useTexts('/show/person')
    const [phone, setPhone]=useState([])
    const [familySaved,setFamilySaved]=useState(false)

    const {children, resetModal,modalParams, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]
    const [lang,countryOrga]=config.locale.split('-')
    const intlCompare=new Intl.Collator(lang).compare
    const countryOptions=countries.sort((first,second)=> (intlCompare(first[lang],second[lang]))).map(c=>({key:c.id,value:c.alpha2.toUpperCase(),text:c[lang], flag:c.alpha2}))

    

    const telOptions=Object.keys(telTypes).map(option=>({
        key:option,
        icon:telTypes[option],
        content:texts[option],
        value:option
    }))
    
    const {getError,checkSubmit}=useCheckFormArray(phone,
        [
            {name:'type', error: texts.invalidPhoneType,test:(val,index)=>(
                val!=='')},
            {name:'number', error: texts.invalidPhone, test:(val,index)=>(
                phone[index].object && phone[index].object.country && phone[index].object.isValid())
            },
        
        ]
    )


    modalSubmit.current=async ()=> {
        if(checkSubmit()) {
            
            const phoneSave=phone.map(p=>({type:p.type,number:p.object.
                formatInternational()}))
            const family=phoneSave.find(p=>p.type==='family')    
            if(!family || family.number === familySaved || window.confirm(texts.ConfirmFamilyPhone) ) {
                     
                const result= await apiUpdate('/kpe20/person/'+UID,{phone:phoneSave})
                if(result.success)
                    resetModal()
            }
        }
    }
    
    

    const handleChange= (ev, {name, value, index}) => {
        if (name==='number')
            setPhone((p) => (p.map((val, i) => {
               if (i===index )
               {
                   const number= parsePhoneNumber(value,val.country)
                   const myYouType = new AsYouType(val.country)
                   return{...val,number: myYouType.input(value) ,  object:number}
               }
               else
                  return  val
            }
            )))
        if (name==='countryCode')
        {
            setPhone((p) => (p.map((val, i) => (i===index ?  {...val,country:value}: val))))
        }
        if (name==='type')
        {
            if(value==='family')
            {
                setPhone((p) => (p.map((val, i) => (i===index ?  
                        {...val,type:'family'}:{...val,type: val.type==='family'?'home' : val.type}))))
            }
            else
                setPhone((p) => (p.map((val, i) => (i===index ?  {...val,type:value}: val))))
        }
    }


    const add=() => {
        setPhone((p) => ([...p, {type:'mobile', number:'',country:countryOrga,object:{}}]))
    }

    const remove=(e, {index:removeIndex}) => {
        setPhone(phone.filter((phone, index) => index !== removeIndex))
    }


    useEffect(() => {
        let myPhone=person.Data.phone.filter(p=>p.number).map(p=>{
            
                    const number=parsePhoneNumber(p.number,countryOrga)
                    return {type:p.type,number: number.formatNational(number.country), country:number.country, object:number}
              
            })
        if(myPhone.length===0)
            myPhone=[{type:'mobile', number:'',country:countryOrga,object:{}}]
        setPhone(myPhone)
        const family=myPhone.find(p=>p.type==='family')
        setFamilySaved(family ? family.object.formatInternational() : null)
    }, [person])

   

    return (<>
        <Modal.Header as='h3'>{texts.EditPhone+person.Display}</Modal.Header>
        <Modal.Content>
            <Form>
                {phone.map((tel, index) => (
                    <Form.Input
                        name='number'
                        index={index}
                        label={texts.telephone}
                        placeholder={texts.telephone}
                        value={tel.number}
                        onChange={handleChange}
                        error={getError('type',index) || getError('number',index) ? [getError('type',index),getError('number',index)].join(' ') : false}
                    >
                        <Dropdown 
                            as={Button}
                            value={tel.type}
                            icon={telTypes[tel.type]} 
                            options={telOptions} 
                            onChange={handleChange}
                            name='type'
                            index={index}
                            compact 
                            basic
                        />
                        <Dropdown
                            options={countryOptions}
                            placeholder={texts.countryCode}
                            value={tel.country}
                            width={3}
                            name='countryCode'
                            onChange={handleChange}
                            index={index}
                            trigger={
                                <Flag name={tel.country.toLowerCase()}/>
                            }
                            icon=''
                            pointing='top left'
                            scrolling
                            button
                            basic
                            compact
                            //search
                        />

                        <input/>

                        <Button 
                            icon='trash'
                            name={phone}
                            index={index}
                            onClick={remove}
                            negative
                        />

                    </Form.Input>
                ))}
                <Form.Button icon='add' content={texts.add} onClick={add} positive />
                
            </Form>
        </Modal.Content>
        {children}
    </>)
}
export default EditPhone