

import React, { useState, useEffect } from 'react'
import { Dropdown, Loader, Icon } from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import AddModalList from '../edit/AddModalList'
import OutputLabels from './OutputLabels'
import OutputXls from './OutputXls'
import useOutputXLS from './useOutputXLS'
import useOutputPDF from './useOutputPDF'
import useOutputEmailClipboard from './useOutputEmailClipboard'


const OutputMenu = (props) => {


    const { isDesktop, tabRef, filename,setModalParams,list,types } = props

    const [isLoading,setLoading]=useState(false)
  //  const outputXls=useOutputXLS({tabRef,list,types,filename,setLoading})
    const outputPdf=useOutputPDF({tabRef,list,types,filename,setLoading})
    const outputEmail2Clip = useOutputEmailClipboard({tabRef,types,list}) 

    const texts = useTexts(['OutputMenu','/show/person'])


    const onClick = (ev,{name}) => {
        if(name==='emailClip')
        {
            outputEmail2Clip()
        }
        if(name==='xls')
        {
            setModalParams({
                addLevel:{
                   component: OutputXls,
                   tabRef: tabRef,
                   list: list,
                   types: types,
                   filename: filename,
                   isLoading: isLoading,
                   setLoading: setLoading
                }
            })

            //outputXls()
        }
        if(name==='pdf')
        {
            
            outputPdf()
        }
        if(name==='email')
        {
            setModalParams({
                addLevel:{
                    component:AddModalList,
                    createNew:true,
                    tabRef: tabRef,
                    type:'email',
                    closeIcon:true
                }
            })
        }
        if(name==='list')
        {
            setModalParams({
                addLevel:{
                    component:AddModalList,
                    createNew:true,
                    tabRef: tabRef,
                    type:'list',
                    closeIcon:true
                }
            })
        }
        if(name==='label')
        {
            setModalParams({
                addLevel:{
                   component: OutputLabels,
                   tabRef: tabRef,
                   list: list,
                   types: types
                }
            })
        }
      
    }

 


    return (
        <Dropdown item simple={isDesktop} text={texts.Output} key='groupOutput'>
            <Dropdown.Menu>
            <Dropdown.Item
                    onClick={onClick}
                    text={texts.EmailClip}
                    name='emailClip'
                    icon='clipboard'
                    key='emailClip'
                />
                <Dropdown.Divider key='a'/>
                <Dropdown.Item
                    onClick={onClick}
                    text={texts.addToMail}
                    name='email'
                    icon='mail'
                    key='groupOutputEmail'
                />
                 <Dropdown.Item
                    onClick={onClick}
                    text={texts.addToList}
                    name='list'
                    icon='list'
                    key='groupOutputList'
                />
                <Dropdown.Divider key='b'/>
                <Dropdown.Item
                    onClick={onClick}
                    text={texts.Pdf}
                    name='pdf'
                    icon='file pdf'
                    key='groupOutputPDF'
                />
                <Dropdown.Item
                    onClick={onClick}               
                    name='xls'
                    key='groupOutputXLS'
                >
                    <Icon  name='file excel'/>
                    {texts.XLS}  
                    { isLoading &&
                            <Loader active inline/>
                    }
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={onClick}
                    text={texts.label}
                    name='label'
                    icon='file text'
                    key='groupFileLabel'
                />
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default OutputMenu
