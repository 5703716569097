
import React,{ useState, useEffect, useRef }    from    'react';
import ReactDOMServer                           from    'react-dom/server';
import {Loader,Button, Segment}                                 from    'semantic-ui-react'
import {useApi,usePageSelect,useWindowDimensions}      from    '@wollo-lib/kpe-context'    //'../../../kpe-context/src/index.js' //
import {bannerFormatter}                        from    '../../../lib/formatters'
import EditFilter                               from    '../modals/EditFilter'
import MyTabulator,{
    autoNumFormatter,
    autoNumEditFormatter
}                                               from    '@wollo-lib/wollo-tabulator' //'../../../lib/MyTabulator'

import useTexts                                 from    '../../../lib20/useTexts'
import {useHistory,useParams}                   from    "react-router-dom";
import OutputMenu                               from    '../../../output/OutputMenu';
import useContextMenu                           from    '../modals/ContextMenu';
import { useConfig }                            from    '../../../lib20/KPE20context';
import { getExtraField }                        from    '../../../edit/list/extraCols';
import Addon                                    from    '../../../lib/Addon'



const Table=(props)=>
{

    const {UID}= useParams()
    const {timestamp,setModalParams, modalParams,functions,achievements, tabRef, wsTime}= props
    const [myTime,setMyTime]=useState(Date.now())
    const texts=useTexts('/show/group')
    const refTexts=useRef(texts)
    const {apiSelect,apiUpdate}=useApi()
    const [reset,setReset]=useState(false)
    const [filterOpen,setFilterOpen]=useState(false)
   
    const history= useHistory()
    const [members,setMembers]=useState([{}])
    const groups=useRef([])
    const {height}=useWindowDimensions()
    const [isLoading,setIsLoading]=useState(false)
    const [totalCount,setTotalCount]=useState(0)
    const extraFields=useRef([])
    const [tabBuilt, setTabBuilt]=useState(false)
    const config= useConfig()


    useEffect(()=>
    {
        if(tabBuilt && config.groupExtraFields)
        {
            const extraCols=[]
            if( config.groupExtraFields && Array.isArray(config.groupExtraFields))
            {
                config.groupExtraFields.forEach((para,Index)=>
                {
                    if(!extraFields.current.some(f=>f.alias===para.alias))
                        extraFields.current.push(para)
                    extraCols.push(getExtraField(para,texts.locale,texts.decimalPoint, texts.currencySymbol))
                })
            }
            tabRef.current.setColumns([...listColumns,...extraCols])
            setTabBuilt(false)
          
        }
      
    },[config,tabBuilt])
   
    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'},
        ...config.groupExtraFields && Array.isArray(config.groupExtraFields )?
             config.groupExtraFields.map(field=>(
            {
                path: field.path, alias:field.alias
            }
        )): []
    ])

    const rowContextMenu=useContextMenu({setModalParams})
    const [filterState,setFilterState]=useState({
        filterSource:null,
        filterAlgo:{},
        types: {person:true, guest: true, extern : false, job:true}
     })

    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo
 
    })
 

    const ajaxParams={
        timestamp:timestamp,
        myTime:myTime,
        type:JSON.stringify(["person","job","guest"]),
        grouped:true,
        Data:jsonFields,
        dataFilter: JSON.stringify(algo)
    }

    useEffect(()=>{
        if(reset)
        {
            tabRef.current.replaceData(members)
            setReset(false)
        }
    },[reset])

   useEffect(()=>{
        const getData=async ()=>
        {
            setIsLoading(true)
            const myQueryString=Object.entries(ajaxParams).filter(([key,entry])=>(entry!==null && entry!==undefined)).map(([key,entry])=>`${key}=${entry}`).join('&')
            const result=await apiSelect(`/kpe20/persons/${UID}/?${myQueryString}`)
            if(result.success)
            {
                setMembers(result.result)
                setTotalCount(result.result.length)
               
            }
            setIsLoading(false)
        }

        if(UID)
            getData()

    },[UID,timestamp,wsTime,filterState, jsonFields])


    /*const {onScroll,isLoading,totalCount}=
        usePageSelect({
            select:`/kpe20/persons/${UID}`,
            urlPara:ajaxParams, 
            setData:setMembers, 
            pageSize:50, 
            clientFilter:true
        })*/



    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        const field=cell.getField()
        if(['Title','banner'].includes(field))
        {
            history.push('/show/person/main/'+rowData.UID)
        }
      
        else if(['firstName','lastName'].includes(field))
        {
            history.push('/show/person/main/'+rowData.UIDBelongsTo)
        }
        else
        {
            const column=cell.getColumn()
            const extraField= extraFields.current.find(f=>f.alias===column.getField())
            if(extraField?.urlPrefix)
                window.open(extraField.urlPrefix+rowData[extraField.alias])
            else if (extraField?.addonUrl)
            {
                {
                    setModalParams({
                        addLevel:
                        {
                            component: Addon,
                            UID: rowData.UIDBelongsTo,
                            addon:{
                                UID: rowData.UIDBelongsTo,
                                url: extraField.addonUrl
                            },
                            closeIcon: true,
                            size: 'large'
                        
                        }
                    })
                }
            }
        }
        
    }


    useEffect(()=>
    {
        if(UID)
            setFilterState(state=>({...state,filterSource:UID}))
    },
    [UID])
  
    const toggleFilterOpen=()=>
    {
        setFilterOpen(state=>!state)
    }

    
    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{filterState:filterState, functions: functions, achievements: achievements}})
        }
    },[filterState, functions, achievements])
    




   
    
    const groupHeader= (value, count, data, group)=>
    {
        return (
          
                data[0].pGroup ? data[0].pGroup : ''

            
        )
    }
  
    const groupClick=(e,group)=>
    {
        const rows=group.getRows()
        const first=rows[0].getData()
        history.push(`/show/group/${first.UIDgroup}`)

    }

    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, hozAlign:"right", frozen:true, field:'UID', download:false}, //incrementing row number
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner', download: false},
      //  {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:38, hozAlign:"center", frozen:true}, // edit 
        { title:  texts.MemberType, field: 'Title', width: 150,  headerFilter:"input"},        
        { title:  texts.FirstName, field: 'firstName', minWidth: 120,  headerFilter:"input"},
        { title:  texts.LastName, field: 'lastName', minWidth: 120,  headerFilter:"input"},
      //  { title:  texts.pGroup, field: 'pGroup',   headerFilter:"input"},
        { title:  texts.Age, field: 'dindex',  width:90, headerFilter:"input"}
    ]
         return(
           
             <>
                <Segment secondary>
                    {texts.TabMemberExplanation}
                </Segment>
                <Button 
                    icon='filter' 
                    circular
                    basic
                    onClick={toggleFilterOpen}
                /> 
                {isLoading ? <Loader active inline size='small'/> : totalCount}&nbsp;{texts.memberCount}
                {filterOpen &&
                    <EditFilter
                        functions={functions}
                        achievements={achievements}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        type={Object.entries(filterState.types).filter(([type,val])=>val===true).map(([type,val])=>type)}
                        UID={UID}

                    />
                }
                <MyTabulator
                    rowContextMenu= {rowContextMenu}
                    index='UID'
                    columns={listColumns}
                    headerFilterPlaceholder={texts.headerFilterPlaceholder}
                    data={members}
                    onClick={cellClick}
                    height={Math.max(height-250,500)}                    
                    compact="very"
                    layout="fitColumns"  
                    tabRef={tabRef}
                    groupBy={['UIDgroup']}
                    groupHeader={groupHeader}
                    on={[
                        {name:"groupClick",function:groupClick},
                        {name: 'tableBuilt', function: ()=>{
                                setTabBuilt(true)
                            }
                        }
                    ]}

                
 
                />
            </>

        )


}

export default Table

