import { useApi, useUser } from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { useConfig } from '../lib20/KPE20context'
import useTexts from '../lib20/useTexts'
import useClipboard from '../lib20/useClipboard'

const useOutputEmailCliboard=(props)=>
{

    const {tabRef,types,list} = props
    const texts = useTexts(['OutputMenu'])
    const [clip,setClip,updateClip]=useClipboard({alwaysUpdate:false, copyAlert: texts.EmailClipOk})
    const {apiUpdate}=useApi()
    const config=useConfig()


    const list2Clipboard=async ()=>
    {
        const UIDs=tabRef.current.getData().map(o=>o.UID)
        let data=[]

        const jsonFields= JSON.stringify([
            {path:'$.firstName',alias:'firstName'},
            {path:'$.lastName', alias: 'lastName'},
            {path:'$.email[0].email',alias:'email'},
          
        ])
    
        if(list)
        {
            const result=await apiUpdate(`/kpe20/persons?Data=${jsonFields}&type=["entry"]`,UIDs)
            if(result.success)
            {
                data=result.result.map(m=>(`${m.firstName} ${m.lastName}<${m.email}>`))
            }
        }
        else
        {
            const result=await apiUpdate(`/kpe20/persons?Data=${jsonFields}&type=${JSON.stringify(types)}`,UIDs)
            if(result.success)
            {
                data=result.result.map(m=>(`${m.firstName} ${m.lastName}<${m.email}>`))
            }
        }
        setClip(data.join(';'))
    }
    return list2Clipboard

    
}

export default useOutputEmailCliboard
