import React, { useState, useEffect, useMemo , Suspense}  from 'react'
import {Container, Segment, Icon, Header, Grid, Loader, Label, Dropdown, Menu, Popup} from 'semantic-ui-react'

import {useApi,useWindowDimensions} from '@wollo-lib/kpe-context'   //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useWs,useConfig} from '../../lib20/KPE20context'
import {ReactComponent as Crucifix} from   '../../css/crucifix.svg'
import RailContainer from '../../lib20/Rails/RailContainer'
import useTexts         from '../../lib20/useTexts'
import {Switch, Route, useHistory, useParams, Redirect} from 'react-router-dom'


import EditPerson       from './modals/ModalEditPerson'


import MenuEdit         from './MenuEdit'
import MenuDev          from './MenuDev'
import SubMenu          from './TabMenu'




function Person(props) {


    const {apiSelect}=useApi()
    const texts=useTexts('/show/person')
    const {UID}=useParams()
    const history=useHistory()
    const config=useConfig()
    const {width}=useWindowDimensions()


    const {setModalParams, modalParams, setModalDefaults,modalDefaults, setExtraMenu, functions,achievements,setRailPath, setContainerWidth}=props

    const [person, setPerson]=useState({Data:{email:[], phone:[], messenger:[], address:[]}, parent:{}, parentS:[]})
    const [group,setGroup]=useState()
    const [type,setType]=useState()
    const [admin,setAdmin]=useState(false)  // is the user admin for this person,  or is it a job or guest, then this one is false (read only mode)
    const [displayed,setDisplayed]=useState('PersonMain')
    const [addon,setAddon] = useState({})

    const addons = config.addons?.filter(a => a.menus?.includes('person/tabMenu'))
    const PopupHide=(props)=>
    (
        // create a popup component, which hides on read only objects
        ['person','extern'].includes(type) && admin &&
            <Popup {...props}/>
    
    )
    
    const Displayed=useMemo(()=>
        React.lazy(()=>import('./tabs/'+displayed))
    )

    const messageHandler=(value) => // websocket message handler
    {
        // it is not delivering back full data set, so merge new data into existing
        if(value.person)
        {
            setPerson(old=>(
                {
                ...old,
                ...value.person,
                parent:value.parent ? value.parent : old.parent
                }))
            if(value.person.Type)
                setType(value.person.Type)
        }
        if(value.parent)
            setGroup(value.parent)
        if(value.data)
        {
            setPerson(old=>(
                {
                    ...old,
                    Data: value.data
                }))
        }
    }

    const {timestamp}=useWs({UIDmonitor:person.UIDBelongsTo,UIDchanged:UID,cb:messageHandler, updateTimeLimit: 5000})
 

    useEffect(()=>{
        if(person.UID)
        {
            setExtraMenu({
                action: 'replace',
                menus: 
                [
                    {admin:false,menu:MenuEdit,props:{person,UID,group,timestamp,functions,achievements, modalDefaults,admin, key: 'PersonEdit'}},
        //          {admin:false,menu:MenuView,props:{person,UID,type,group,timestamp,functions}},
                    {admin:true,menu:MenuDev,props:{person,UID,type,group,timestamp,functions, key:'PersonDev'}}
                ]
            })
            return ()=>setExtraMenu({action:'replace',menus:[]})
        }

    },
    [group,person,timestamp,functions,type])

    useEffect(()=>{
        setModalDefaults({
            UIDdefault: person.UIDBelongsTo,
            defaultType:'person',
            defaultDisplay: person.Display
        })
    },[person])

   // get  data for the person group
    useEffect(()=>{
        const getPersonData = async ()=> 
        {
            const result= await apiSelect(`/kpe20/person/${UID}/?parent=true`)  
            if (result.success) {
                
               
                const myPerson=result.result    
                if(!myPerson.Data.phone)
                    myPerson.Data.phone=[]
                if(!myPerson.Data.email)
                    myPerson.Data.email=[]
                if(!myPerson.Data.address)
                    myPerson.Data.address=[]
                setPerson(myPerson)
                setGroup(myPerson.parent)
                setRailPath(myPerson.parent.UID)
                setType(myPerson.Type)
            }
        }
        const getPersonAdmin=async()=>
        {
            const result= await apiSelect(`/kpe20/person/admin/${UID}`)
            if(result.success) {
                setAdmin(result.result)
            }
        }
        getPersonData()

        getPersonAdmin()
        // delete defaults, when view is closed 
        return ()=>{setModalDefaults({})}
    }, [UID])

    useEffect(()=>{
        setContainerWidth(1200)
    })

    useEffect(()=>{
        setModalParams({modify:{myGroup:group,myPerson:person}})
        setExtraMenu(old=>
            old.map((m,Index)=> Index===0 ? {admin:false,menu:MenuEdit,props:{person,UID,group,timestamp,functions,achievements, modalDefaults,admin}}:m))
    },[group,person])



    const MainPart=()=>
    (
        <Segment>
            <Header as ="h2">
                <PopupHide 
                    content={texts.edit} 
                    trigger={
                        <Icon 
                            name='edit' 
                            onClick={ev=>{
                                setModalParams({
                                    addLevel:{
                                        component:EditPerson,
                                        UID:UID,
                                        myPerson:person,
                                        myGroup:group,
                                        Type: type,
                                        admin:admin
                                    }
                                })
                            }} 
                            className='editIconColor'
                        />
                    }
                />
                 {person.Data.firstName} {person.Data.lastName} {person.Data.nickname && '/'+person.Data.nickname} 
                 {
                     person?.Data?.category?.includes('died') &&
                     <Crucifix width={40} height={40}/>
                 }
            </Header>
            <Label color={
                    person.Type==='person' ? config?.UIStageColor[person.Data.stage] ?? 'black'
                    : 'black'
                } basic size='large' ribbon> 

               {person?.Title} 
            </Label>


            <Header as="h3"
                onClick={e=>history.push('/show/group/members/'+person.parent.UID)}
                style={{cursor:'pointer'}}
                color='blue'>
                    {person?.parent?.Title} {person?.parent?.Display}
                
            </Header>
            <Suspense fallback={<Loader loading/>}>

                <Switch>
                    <Route exact path={`/show/person/main/:UID`} >
                        {() => {setDisplayed('PersonMain'); return null}}
                    </Route>
                    <Route exact path={`/show/person/achievements/:UID`} >
                        {() => {setDisplayed('PersonAchievements'); return null}}
                    </Route>
                    <Route exact path={`/show/person/family/:UID`} >
                        {() => {setDisplayed('PersonFamily'); return null}}
                    </Route>
                    <Route exact path={`/show/person/files/:UID`} >
                        {() => {setDisplayed('PersonFiles'); return null}}
                    </Route>
                    <Route exact path={`/show/person/jobs/:UID`} >
                        {() => {setDisplayed('PersonJobs'); return null}}
                    </Route>
                    <Route exact path={`/show/person/lists/:UID`} >
                        {() => {setDisplayed('PersonLists'); return null}}
                    </Route>
                    {addons && addons.map((addon,index) => (
                        <Route exact path={`/show/person/addon${index}/:UID`}>
                            {() => {setDisplayed('Addon'); setAddon(addon); return null}}
                        </Route>
                    ))}
                    <Route default>
                        <Redirect to={`/show/person/main/${UID}`} />
                    </Route>
                    
                </Switch>
                    
                <Displayed
                    person={person}
                    UID={UID}
                    group={group}
                    setModalParams={setModalParams}
                    PopupHide={PopupHide}
                    functions={functions}
                    achievements={achievements}
                    timestamp={timestamp}
                    admin={admin}
                    addon={addon}

                />

            </Suspense>
        </Segment>
    )

    


    return (<>
        < >
        { width <1000 ?
            <>
               <Dropdown item icon='bars' simple>
                   <Dropdown.Menu>
                    <SubMenu
                        person={person}
                        UID={UID}
                        type={type}
                        group={group}
                        timestamp={timestamp}
                        functions={functions}
                        achievements={achievements}
                        modalDefaults={modalDefaults}
                        modalParams={modalParams}
                        setModalParams={setModalParams}
                        setDisplayed={setDisplayed}
                        displayed={displayed}

                    />
                    </Dropdown.Menu>
               </Dropdown>
               <MainPart/>
            </>
        
        :
            <Grid>
                <Grid.Column width={3} key={1}>
                    <Menu  vertical tabular fluid>
                    <SubMenu
                        person={person}
                        UID={UID}
                        type={type}
                        group={group}
                        timestamp={timestamp}
                        functions={functions}
                        achievements={achievements}
                        modalDefaults={modalDefaults}
                        modalParams={modalParams}
                        setModalParams={setModalParams}
                        setDisplayed={setDisplayed}
                        displayed={displayed}

                    />
                    </Menu>
                </Grid.Column>
                <Grid.Column width={13} key={2}>
                   <MainPart/>
                </Grid.Column>
            </Grid>
        }
               

        
        </>

    </>)
}

export default Person
