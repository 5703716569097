import React, { useEffect, useState } from 'react'
import {Container,Rail, Grid, Header, List, Loader, Segment, Icon, Image} from 'semantic-ui-react'
import { ReactComponent as Logo } from './css/lilie.svg'
import useTexts from './lib20/useTexts'
import { useWindowDimensions, useApi} from  '@wollo-lib/kpe-context'    // './kpe-context/src/index.js'
import {useConfig} from './lib20/KPE20context'
import {Link, useHistory} from 'react-router-dom'
import ModalHome from './ModalHome'
import RailContainer from './lib20/Rails/RailContainer'


const Home=(props)=> {

    const {setModalParams, setContainerWidth, setRailPath}=props
    const texts=useTexts('Home')
    const {apiSelect,apiGetFile}=useApi()
    const [myGroups,setMyGroups]=useState([]) 
    const [myLists,setMyLists]=useState([])
    const config=useConfig()
    const [loading,setLoading]=useState(true)
    const [loadingGroups,setLoadingGroups]=useState(true)

    const {width}=useWindowDimensions()
    useEffect(()=>{
        const getMyGroups=async()=>
        {
            const resJobs=await apiSelect(`/kpe20/person/jobgroups/${config.orgaUser.UID}`,'kpe20')
            if(resJobs.success)
            {
                setMyGroups(resJobs.result)
                setLoadingGroups(false)
            }
            else
            {
                window.alert('error loading groups: '+resJobs.message)
            }
            
        }

        if(config.orgaUser)
            getMyGroups()
    },[config])



    useEffect(()=>{
        const getLists=async ()=>
        {
            const result=await 
                apiSelect(`/kpe20/list`)
           
            if(result.success)
            { 
                setMyLists(result.result)
                setLoading(false)
            }
            else
            {
                window.alert('error loading lists: '+result.message)
            }
        }
        if(config.orgaUser)
            getLists()
    },[config])

    const openModal=(type)=>
    {
        setModalParams( {
            addLevel: {
                component: ModalHome,
                type:type,
                myLists: myLists,
                closeIcon:true,
                size:'small'
            }
        })
    }

    useEffect(()=>{
        setContainerWidth(1000)
        setRailPath('db')
    },[width])

    return(
       
    <>
        <Segment raised textAlign='left'>
            <Header align='center' as='h1' >{texts.hello+', '+config.orgaUser.Data.firstName+' '+config.orgaUser.Data.lastName}</Header>
            <Segment >
                <Header as='h3'>
                    <span><Logo width="20px" height="20px" style={{marginRight:'10px'}} />{texts.headerMyGroups}</span>
                </Header>
                <List size='large'>
                    {myGroups.length === 0 
                        ? <>
                            <List.Icon name='users'/>&nbsp;
                            <Loader active inline size='small'/>
                        </>
                        : myGroups.map(group=>(
                            <List.Item key={'home'+group.UID}>
                                <List.Icon name='users'/>
                                <List.Content as={Link} to={`/show/group/${group.UID}`}>{group.Title} {group.Display}</List.Content>
                            </List.Item>
                        ))
                        
                    }
                </List>
            </Segment>
        </Segment>

        <Grid columns={3} stackable celled divided textAlign='left'>

            <Grid.Column compact >
                <Header style={{cursor:'pointer'}} >
                    <a onClick= {()=>openModal('list')} as='h2'>
                        <Icon name='list'/>
                        {texts.headerMyLists}

                    </a>
                </Header>
                <ul as={List}>
                    {loading ? <Loader active inline='centered'/> : <>
                        {
                            myLists.filter(list=>list.Type==='list').filter((list,Index)=>Index<17).map(list=>(
                                <li as='a' key={'home'+list.UID}>
                                    <Link to={`/edit/list/${list.UID}`}>{list.Display}</Link>
                                </li>
                            ))
                        }
                        <List.Item content='...' as='a' onClick={()=>openModal('list')} />
                    </>}
                </ul>
            </Grid.Column>


            <Grid.Column compact>
                <Header style={{cursor:'pointer'}} >
                    <a onClick= {()=>openModal('dlist')} as='h2'>
                        <Icon name='database'/>
                        {texts.headerMyDlists}
                    </a>
                </Header>
                <ul>
                    {myLists.length === 0 ? <Loader active inline='centered'/> : <>
                        {
                            myLists.filter(list=>list.Type==='dlist').filter((list,Index)=>Index<17).map(list=>(
                                <li key={'home'+list.UID}>
                                    <Link to={`/edit/dlist/sources/${list.UID}`} >{list.Display}</Link>
                                </li>
                            ))
                            
                        }
                        <List.Item content='...' as='a' onClick={()=>openModal('dlist')} />
                    </>}
                </ul>
            </Grid.Column>


            <Grid.Column compact>
                <Header style={{cursor:'pointer'}} >
                    <a onClick= {()=>openModal('email')} as='h2'>
                        <Icon name='mail'/>
                        {texts.headerMyEMails}
                    </a>
                </Header>
                <ul>
                    {myLists.length === 0 ? <Loader active inline='centered'/> : <>
                        {
                            myLists.filter((list)=>list.Type==='email').filter((list,Index)=>Index<17).map(list=>(
                                    <li key={'home'+list.UID}>
                                        <Link to={`/edit/email/${list.UID}`}>{list.Display}</Link>
                                    </li>
                            ))
                        }
                        <List.Item content='...' as='a' onClick={()=>openModal('email')} />
                    </>}
                </ul> 
            
            </Grid.Column>
        </Grid> 
    </>

    )
}

export default Home
