import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Form,Button,Segment,Popup} from 'semantic-ui-react'
import useTexts from '../../../lib20/useTexts'
import FilterElement from '../../../lib20/FilterSources/FilterElement'
import useClipboard from '../../../lib20/useClipboard';


const EditFilter=(props)=>
{

    
    const texts=useTexts('/show/group')
    const {UID,functions,achievements,filterState,setFilterState,type}=props

    const [clip,setClip,updateClip]=useClipboard({alwaysUpdate:false})



    const algo={}
    algo[type]=filterState.filterAlgo

    const changeAlgo=(ev,{value})=>
    {
        if(JSON.stringify(value)!==JSON.stringify(filterState.filterAlgo))
        setFilterState(old=>({...old,filterAlgo:value}))
    }


    const copyFilter=(ev)=>
    {
        if(type!=='group')
        setClip({
            type: 'filteredSource',
            data:  { 
                        UIDBelongsTo: UID,
                        Data:{job:filterState.filterAlgo}
                    }
        })
    }
    
    return(
    <>
        <Segment>
            <Form>
                <Form.Group>    
                    {
                        type!=='group' &&
                        <Popup
                            trigger={
                                    <Button
                                        icon='copy'
                                        color='blue'
                                        size='midi'
                                        onClick={copyFilter}
                                    />}
                            content={texts.clipboardCopy}
                        />
                    }
                    
                        <Form.Field
                            control={FilterElement}
                            onChange={changeAlgo}
                            type={type}
                            value={filterState.filterAlgo}
                            functions={functions}
                            achievements={achievements}
                            name='filterAlgo'
                        />
             
                </Form.Group>
            </Form>

        </Segment>
        

    </>
    )
}
export default EditFilter