import React, {useState, useEffect, useRef}  from 'react'
import {Container, Header, Form, Button, Modal} from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect/*, useCheckForm*/} from '@wollo-lib/wollo-react'
//import {SearchSelect} from '../lib/SearchSelect'
import useTexts from '../lib20/useTexts'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {useRoot, useConfig} from '../lib20/KPE20context' 

const EditGuest=(props)=> {

    const texts=useTexts('/edit/guest')
    const [member,setMember]=useState()
    const [group,setGroup]=useState()
    const [template,setTemplate]=useState()
    const [templates,setTemplates]=useState([])
    const [errors,setErrors]=useState({})
    const [groupQuery,setGroupQuery]=useState('')
    const [memberQuery,setMemberQuery]=useState('')
    const config=useConfig()
    const {apiSelect,apiInsert}=useApi()
    const {children, resetModal, modalSubmit, modalParams, modalDefaults}=props
    const {createNew,UID,functions}=modalParams[0]
    const {defaultType,UIDdefault}=modalDefaults


    useEffect(() => {

        const initializeGuest=async ()=>
        {
            if(defaultType==='person')
            {
                const result=await apiSelect('/kpe20/person/'+UIDdefault)
                if(result.success) 
                {
                    setMember(result.result.UIDBelongsTo)
                }
            }
            if(defaultType==='group')
            {
                setGroup(UIDdefault)
            }
        }
        if(createNew)
            initializeGuest()
    },[UID,createNew])
   
    modalSubmit.current=async ()=> {
        if(member && group ) {
           const res=await apiInsert(`/kpe20/guest/${member}/${group}`,{empty:true},'kpe20')
           if(res.success)
           {
                resetModal()
           }
           else
           {
               window.alert(res.message)
           }
        }
    }
    

    return(<>
        <Modal.Header>{texts.Header}</Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field
                    label={texts.Person}
                    placeholder={texts.Person}
                    control={SearchSelect}
                    api="kpe20"
                    SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['person','guest','extern','job'])+memberQuery}
                    Value2TitleUrl='/kpe20/Value2Title'
                    value={member}
                    autoSelect
                    onChange={(ev,{value})=>{setMember(value)}}
                    SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                />
                 <Form.Field
                    label={texts.GuestGroup}
                    placeholder={texts.Group}
                    control={SearchSelect}
                    api="kpe20"
                    SuggestUrl={'/kpe20/SearchData?types=group'}
                    Value2TitleUrl='/kpe20/Value2Title'
                    name="UIDgroup"
                    value={group}
                    autoSelect
                    onChange={(ev,{value})=>{setGroup(value)}}
                />
            </Form>
        </Modal.Content>
        {children}
    </>)
}

export default EditGuest