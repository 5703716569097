
import React,{useState, useEffect}  from 'react';
import {  Modal, Form, Loader}  from 'semantic-ui-react'
import useOutputXLS from './useOutputXLS';
import useTexts from '../lib20/useTexts'
import countries from '../lib20/countries.json'
import {countriesShort} from '../lib20/countriesShort'
import { useConfig, useSetConfig } from '../lib20/KPE20context'
import { useApi} from '@wollo-lib/kpe-context' 

const OutputXls=(props)=>
{
    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}= props
    const {tabRef, types, list, filename, isLoading, setLoading}= modalParams[0]
    const createOutput=useOutputXLS({tabRef,types,list,filename,setLoading})
    const config=useConfig()
    const setConfig=useSetConfig()
    const [lang,countryOrga]=config.locale.split('-')
    const {apiUpdate}=useApi()

    const texts = useTexts(['OutputMenu','/show/person', '/edit/address'])
    const [cols, setCols]= useState([
        'title',
        'greeting',
        'firstName',
        'lastName',
        'birthday',
        'group',
        'phoneMobile',
        'phoneFamily',
        'phoneHome',
        'emailPersonal',
        'emailFamily',
        'roadHouseNumber',
        'road2',
        'postcode',
        'city',
        'country',
        'openBillsSum',
    ])


    useEffect(()=>{
        if(list )
        {
            if(list.xlsCols)
            {
                setCols(list.xlsCols)
            }
        }
        else
        {
            if(config.orgaUser.Data.settings?.xlsCols)
            {
                setCols(config.orgaUser.Data.settings.xlsCols)
            }
        }
        
    },[list])

    const getCountryFromCodeShort=(code)=>
    {
        if(code)
        {
            const country=countriesShort.find(c=>c.ISO2.toLowerCase()===code)
            return country ? country.CIndex : null
        }
        else
            return null
    }
    const getCountryFromCode=(code)=>
    {
        if(code)
        {
            const country=countries.find(c=>c.alpha2===code)
            return country ? country[lang] : null
        }
        else
            return null
    }

    const colRequests=[
        {
            key: 'title',         // key for extraction of the data from objectT, if no ref function is supplied
            title: texts.Title,
            value: 'title'
        },
        {
            key: 'greeting',         // key for extraction of the data from objectT, if no ref function is supplied
            title: texts.Greeting,
            value: 'greeting'
        },
        {
            key: 'firstName',         // key for extraction of the data from objectT, if no ref function is supplied
            title: texts.FirstName,
            value: 'firstName'
        },
        {
            key: 'lastName',         // key for extraction of the data from objectT, if no ref function is supplied
            title: texts.LastName,
            value: 'lastName'
        },
        {
            title: texts.Birthday,
            ref: (m)=> new Date(m.Data?.birthday*1000),
            width: 16,
            value:'birthday'
        },
        {
            title: texts.Group,
            ref: (m)=> m.pGroup,
            value: 'group'
        },
        {
            title: `${texts.telephone} ${texts.mobile}`,
            ref: (m)=> m.Data?.phone?.find(el=>el.type==='mobile')?.number,
            value: 'phoneMobile'

        },
        {
            title: `${texts.telephone} ${texts.family}`,
            ref: (m)=> m.Data?.phone?.find(el=>el.type==='family')?.number,
            value:'phoneFamily'

        },
        {
            title: `${texts.telephone} ${texts.home}`,
            ref: (m)=> m.Data?.phone?.find(el=>el.type==='home')?.number,
            value: 'phoneHome'

        },
        {
            title: `${texts.email} ${texts.personal}`,
            ref: (m)=> m.Data?.email?.find(el=>el.type==='personal')?.email,
            value: 'emailPersonal'

        },
        {
            title: `${texts.email} ${texts.family}`,
            ref: (m)=> m.Data?.email?.find(el=>el.type==='family')?.email,
            value: 'emailFamily'

        },
        {
            title: texts.road,
            ref: (m)=> m.Data?.address[0]?.road ?? '',
            value: 'road'

        },
        {
            title: texts.houseNumber,
            ref: (m)=> m.Data?.address[0]?.houseNumber ?? '',
            value: 'houseNumber'
        },
        {
            title: texts.roadHouseNumber,
            ref: (m)=> `${m.Data?.address[0]?.road ?? ''} ${m.Data?.address[0]?.houseNumber ?? ''}`,
            value: 'roadHouseNumber'
        },
        {
            title: texts.road2, 
            ref: (m)=> m.Data?.address[0]?.road2 ?? '',
            value: 'road2'
        },
        {
            title: texts.postcode,
            ref: (m)=> m.Data?.address[0]?.postcode ?? '',
            value: 'postcode'
        },
        {
            title: texts.city,
            ref: (m)=> m.Data?.address[0]?.city ?? '',
            value: 'city'
        },
        {
            title: texts.country,
            ref: (m)=> getCountryFromCode(m.Data?.address[0]?.countryCode),
            value: 'country'
        },
        {
            title: texts.countryShort,
            ref: (m)=> getCountryFromCodeShort(m.Data?.address[0]?.countryCode),
            value: 'countrySort'
        },
        {
            title: texts.countryISO,
            ref: (m)=> m.Data?.address[0]?.countryCode,
            value: 'countryISO'
        },
        {
            title: texts.openBillsSum,
            ref: (m)=> isNaN(m.Data?.openBillsSum) ? 0 : parseFloat(m.Data?.openBillsSum),
            value: 'openBillsSum'
        }     


    ]


    const downloadXls=()=>
    {
        if(list)
            apiUpdate(`/kpe20/${list.Type}/${list.UID}`,{xlsCols:cols})
        else
        {
            apiUpdate(`/kpe20/person/${config.orgaUser.UID}`,{settings:{xlsCols:cols}})
            setConfig({...config,orgaUser:{...config.orgaUser,Data:{...config.orgaUser.Data,settings:
                {...config.orgaUser.Data.settings,xlsCols:cols}}}})
        }
        createOutput(
            cols.map(col=>colRequests.find(c=>c.value===col)))
        resetModal()
    }

    const selectOptions= colRequests.map(c=>({key:c.value,value:c.value,text:c.title}))


    return(
        <>
            <Modal.Content>
                
                <Form>
                    <Form.Select 
                        multiple 
                        options={selectOptions}
                        value={cols}
                        onChange={(ev,{value})=>setCols(value)}
                        selection
                        label={texts.xlsCols}
                    />
                    <Form.Button icon={'file excel'}
                        color='blue'
                        content='Download' 
                        onClick={downloadXls}
                    />
                </Form>
            </Modal.Content>
        </>
    ) 
}
export default OutputXls