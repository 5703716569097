
import { useApi, useUser } from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'

import useTexts from '../lib20/useTexts'

const useOutputXLS=(props)=>
{

    const {tabRef,types,list,filename,setLoading} = props

    const {apiUpdate}=useApi()

    const texts = useTexts(['OutputMenu','/show/person', '/edit/address'])

    const XLSX=window.XLSX


   
 

    




    const outputXls=async( colRequest)=>
    {


        const mainXlsData=(m)=>
        {
            return colRequest.reduce((result,col)=>{
                if(col.key)
                {
                    return {   
                            ...result,
                            [col.title]:m.Data ?  m.Data[col.key]  : '' ?? ''
                        }
                }
                else if(col.ref)
                {
                    return {
                        ...result,
                        [col.title]: col.ref(m)
                    }
                }
                else
                {
                    return result 
                }
            }, {})

        }

        if(!types)
            tabRef.current.download("xlsx", filename+".xlsx", {sheetName:texts.xlsSheetName})
        else
        {
            const UIDs=tabRef.current.getData().map(o=>o.UID)
            let data=[]
            setLoading(true)
            if(list)
            {
                const result=await apiUpdate('/kpe20/persons?Data=all&ExtraData=1&type=["entry"]',UIDs)
                if(result.success)
                {
                    data=result.result.map(m=>{
                        const myResult= mainXlsData(m)
                        if(list.extraParameter)
                        {
                            list.extraParameter.forEach(e=>{
                                const extraPara=e.name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
                                return   myResult[e.name]=m.ExtraData[extraPara]?.toString()
                            })
                        }
                        return myResult
                    })
                }
            }
            else
            {
                const result=await apiUpdate(`/kpe20/persons?Data=all&type=${JSON.stringify(types)}`,UIDs)
                if(result.success)
                {
                    data=result.result.map(mainXlsData)
                }
            }
            const worksheet = XLSX.utils.json_to_sheet(data);
            const cols= Object.keys(data[0])
            const wchs= colRequest.map(col=>{
                    return data.reduce((width, row) => {
                        return Math.max(width, col.width? col.width : row[col.title] && typeof row[col.title]==='string' ? row[col.title].length :5)
                    }, col?.title?.length)
                })
                .map(w=>({wch:w}))
            worksheet["!cols"] =wchs
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet,texts['xlsSheetName']);
            setLoading(false)
            XLSX.writeFile(workbook, filename+".xlsx", { compression: true });
        }
    }
    return outputXls
}

export default useOutputXLS

