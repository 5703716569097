import React, { useState, useEffect, useRef } from 'react';
import { Container, Icon, Header, Popup, Form, Segment, Grid, GridColumn } from 'semantic-ui-react'
import { useApi,usePageSelect, useWindowDimensions } from '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { useConfig, useWs } from '../../lib20/KPE20context'
import useTexts from '../../lib20/useTexts'
import { BrowserRouter as Router, Switch, Route, useHistory, useParams } from "react-router-dom";
import ModalList from '../ModalLists'
import ListResults from './ListResults'
import MenuEdit from './MenuEdit.jsx';
import { CKEditorUpload as TextEditor } from '../../lib20/CKeditor/CKInlineEditor'
import { baseUrl, api } from '../../config.jsx'
import useObjectActions from '../../Hooks/useObjectActions';
import OutputMenu from '../../output/OutputMenu';
import HistoryButton from './ModalHistory';
import { nameReplace } from './extraCols';


const EditList = (props) => {
    const {modalParams, setModalParams, functions, achievements, setExtraMenu, actions, tabRef, setRailPath, setContainerWidth } = props
    const config = useConfig()
    const { apiSelect, apiUpdate } = useApi()
    const texts = useTexts('/edit/list')
    const [list, setList] = useState({})
    const [shares, setShares] = useState([])
    const [listOwner, setListOwner] = useState({})
    const [listMember, setListMember] = useState({})
    const [listData,setListData]=useState([])
    const [admin, setAdmin]=useState(false)
    const [fullRead,setFullRead]= useState(true)
    const { width } = useWindowDimensions()
    const history = useHistory()
    const { UID } = useParams()
    const { objectActions, actionsMessageHandler } = useObjectActions(UID)
    const [historyTime, setHistoryTime]= useState(null)
    const [totalCount,setTotalCount]= useState(0)
    const [isLoading, setIsLoading]= useState(false)

    const PopupHide = (props) =>
    (
        // create a popup component, which hides on read only objects
        (admin) &&
        <Popup {...props} />

    )

    const messageHandler = (value) => {

        if (value.added)
        {
           setListData(old=>{
               
                const addedArray=value.added
                if(addedArray.UIDalready)
                    return old
                const newList=[...addedArray.map(added=>({
                    UID:added.UIDentry,
                    Type:added.BaseType,
                    UIDBelongsTo:added.UIDBelongsTo,
                    Title: added.Title,
                    Display: added.Display,
                    SortName: added.SortName,
                    SortBase: added.SortBase,
                    UIDgroup: added.UIDgroup,
                    hierarchie: added.hierarchie,
                    gender: added.gender,
                    stage: added.stage,
                    dindex: added.dindex,
                    pGroup: added.pGroup,
                    firstName: added.Data.firstName,
                    lastName: added.Data.lastName,
                    banner: added.Data.banner}
                ))
                ,...old]
                return newList
                
           }) 
        }
        else if(value.deleted)
        {
            setListData(old=>{
                return old.filter((om)=>{
                    return !value.deleted.some(deleted=>{
                        return deleted.UID===om.UIDBelongsTo
                    })
                })
            })
        }
        else if (value.Data) {
            setList({ ...value.Data.Data })
            setListOwner(value.Data.owner)
            setListMember(value.Data.member)
            setAdmin(value.Data.admin)
            console.log(value.Data)
        }
        else if (value.shareAdded) {
            setShares(old => [...old, value.shareAdded])
        }
        else if (value.sharesDeleted) {
            setShares(old => (old.filter(el => !value.sharesDeleted.find(f => el.UID === f.UID))))
        }
        else if(value.changed )
        {
            setListData(old=>old.map(ld=> {
               return ld.UIDBelongsTo===value.changed.UIDperson ? {...ld,...value.changed.Data} : ld
            }))
        }
        else {
            actionsMessageHandler(value)
        }
    }


    const { timestamp, setTimestamp } = useWs({ UIDmonitor: UID, UIDchanged: UID, cb: messageHandler, updateTimeLimit: 2000 })


    useEffect(() => {

        const getList = async () => {
            const myList = await apiSelect('/kpe20/list/' + UID, 'kpe20')
            setList({ ...myList.result.Data, Type: 'list'})
            setListOwner(myList.result.owner)
            setListMember(myList.result.member)
            setAdmin(myList.result.admin)
            setFullRead(myList.result.writeable)
            setRailPath(myList.result.UIDgroup)
        }
        if (UID && config.UID &&  list.UID !==UID) {
            getList()
        }

    }, [UID, config, timestamp, shares, modalParams])

    useEffect(()=>{
        setContainerWidth(1600)
    },[width])


    useEffect(() => {
        const getShares = async () => {
            const result = await apiSelect(`/kpe20/list/shares/${UID}`)
            if (result && result.success) {
                setShares(result.result)
            }
        }
        if (UID)
            getShares()
    }, [UID, timestamp])

    const listChange = (ev, { name, value }) => {
        const newList = { ...list, [name]: value }
        setList(newList)
        apiUpdate('/kpe20/list/' + UID, newList)
    }



    useEffect(() => {
        if (admin)
            setExtraMenu({
                action: 'replace',
                menus:
                [
                    {
                        admin: false, menu: MenuEdit, props: {
                            UID, timestamp, functions, achievements, shares, type: 'list',
                            actions, objectActions,
                            key:'ListEdit'
                        }
                    },
                    {   admin:false, menu:OutputMenu, props: {
                        tabRef,filename:`${list.name}`, key: 'ListOutput', list, types:['entry']}
                    }
                ]
            })
        else {
            setExtraMenu({action:'replace',menus:[]})
        }
        return () => setExtraMenu({action:'replace',menus:[]})
    },
    [UID, functions, achievements, timestamp, shares, objectActions, actions,list])

    const extraFields= JSON.stringify(
        !list || (!list.extraParameter && !list.extraFields) ? null :
        
            list.extraParameter.map(para=>(
                {
                    path: '$.'+nameReplace(para.name), alias:'__extra_'+nameReplace(para.name)
                }
            ))
              
    )
    

    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'},
        ...list.extraFields ? list.extraFields.map(field=>(
            {
                path: field.path, alias:field.alias
            }
        )): []
    
    ])

    const ajaxParams={timestamp:historyTime ? historyTime : timestamp,Data:jsonFields,ExtraData:extraFields,type:JSON.stringify(['entry'])}
    //const {onScroll,totalCount,isLoading}= usePageSelect({select:`/kpe20/list/persons/${UID}`,urlPara:ajaxParams, setData:setListData, pageSize:50, clientFilter:true})
    const onScroll=()=>{}
    useEffect(()=>
    {
        const getEntries=async ()=>
        {
            const myTimestamp=historyTime ? `&timestamp=${historyTime}` : ''
            setIsLoading(true)
            const result= await apiSelect(`/kpe20/list/persons/${UID}?ExtraData=${extraFields}&Data=${jsonFields}${myTimestamp}&type=["entry"]`)
            setIsLoading(false)
            if(result.success)
            {
                setListData(result.result)
                setTotalCount(result.result.length)
            }

        }
        if(list)
            getEntries()
    }
    ,[list,timestamp,historyTime])



    const revertTimestamp=async ()=>{
        window.confirm(texts.confirmRevert)
        await apiUpdate(`/kpe20/list/revert/${UID}/${historyTime}`)
        setHistoryTime(null)
    }

    return (
        <>
            <Grid>
                <Grid.Column width={13} key='g1'>
                    <Header as='h2'>
                        <PopupHide
                            content={texts.edit}
                            trigger={
                                <Icon
                                    name='edit'
                                    onClick={ev => {
                                        setModalParams({
                                            addLevel: {
                                                component: ModalList,
                                                UID: UID,
                                                type: 'list',
                                                functions: functions,
                                                achievements: achievements,
                                                admin: admin,
                                                tabRef
                                            }
                                        })
                                    }}
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.HeaderEdit}: {list.name}
                    </Header>
                </Grid.Column>
                <Grid.Column width={3} key='g2'>
                    <Form.Group>
                       
                        <HistoryButton
                            setModalParams={setModalParams}
                            historyTime={historyTime}
                            setHistoryTime={setHistoryTime}
                        />
                
                        {
                            historyTime &&
                            <Popup
                                trigger={
                                        <Form.Button
                                            icon='undo'
                                            content={texts.revertButton}
                                            color="green"
                                            onClick={revertTimestamp}
                                        />
                                }
                                content= {texts.revertToTimestamp}
                            />
                        }
                    </Form.Group>
                </Grid.Column>
            </Grid>
            <Header as='h4'>{texts.Group}: <a onClick={e => { history.push('/show/group/members/' + listOwner.UID) }}>{listOwner.Title} {listOwner.Display}</a></Header>
            <Header as='h4'>{texts.creator}: <a onClick={e => { history.push('/show/person/main/' + listMember.UID) }}>{listMember.Title} {listMember.Display}</a></Header>
           
            <Segment>
              
                    <Form>
                        <Form.Field
                            label={texts.Description}
                            control={TextEditor}
                            uploadUrl={'https://' + api + '.' + baseUrl + '/kpe20/files/editor/365/' + UID}
                            onChange={listChange}
                            value={list.description}
                            name='description'
                            readOnly={!admin}
                            autoSave={0}

                        />
                    </Form>
            </Segment>




         {   <ListResults
                fullRead={fullRead}
                timestamp={ historyTime }
                list={list}
                setList={setList}
                setModalParams={setModalParams}
                listOwner={listOwner}
                functions={functions}
                achievements={achievements}
                tabRef={tabRef}
                ajaxParams={ajaxParams}
                onScroll={onScroll}
                listData={listData}
                isLoading={isLoading}
                totalCount={totalCount}

            />}


        </>
    )

}
export default EditList
