import React, {useState, useEffect}  from 'react'
import {Dropdown} from 'semantic-ui-react'

import useTexts from '../../lib20/useTexts'


const MenuEdit=(props) => {
    const texts=useTexts('/edit/list')
    const {isDesktop,shares,setModalParams,modalParams,timestamp,resetModal,objectActions,functions,achievements,actions}=props

    const clickHandler=async (ev,{name,size,closeIcon})=>
    {
        const module=await import('../'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size: size,
                closeIcon:closeIcon,
                functions,
                actions,
                achievements,
                objectActions,
                objectType:'list',
                ...props
            }
        })
    }

    // make shure, that changes to shares are passed through

    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{shares:shares,timestamp:timestamp}})
        }
    },[shares,timestamp])

    useEffect(()=>{
        if(modalParams.length>0 )
        {
            setModalParams({modify:{objectActions:objectActions}})
        }
    },[objectActions])


    return(

        <Dropdown item simple={isDesktop} text={texts.MenuEdit} >
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={clickHandler}
                    name='share/ModalShare'
                    closeicon size='large'
                    key='listMenuEditShare'
                >
                    {texts.ModalShare}
                </Dropdown.Item>
                <Dropdown.Item 
                    onClick={clickHandler} 
                    size='large' 
                    name='actions/Actions'
                    text={texts.Actions}
                    closeIcon
                    key='listMenuEditActions'

                />
            </Dropdown.Menu>
        </Dropdown>

    )

}  

export default MenuEdit
