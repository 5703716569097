
import React, {useState, useEffect, useRef, useMemo}  from 'react'
import { Icon, List, Segment,Form,Popup, Header, Accordion, Modal} from 'semantic-ui-react'
import {usePageSelect,useApi} from '@wollo-lib/kpe-context' //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {MyAccordion} from '@wollo-lib/wollo-react'
import { useConfig } from '../../../lib20/KPE20context'
import {showDatum} from '@wollo-lib/wollo-react'

import useTexts from '../../../lib20/useTexts'
import EditFamily from '../modals/ModalEditFamily.jsx'
import ModalFeeAddress from './modals/ModalFeeAddress'


const FamilyList=(props)=>
{
    const {UID,admin,person,year, family}=props

    const texts=useTexts('/show/person/family')

  


    return(<>
            
           
            {
                !family || ! family.fees ? '' :
                <>
                    <Header as ="h4" content={`${person?.Data.firstName} ${person?.Data.lastName} ${texts.Header2} ${family?.fees[1]?.Display}`}/>

                    <List>  
                    {
                        family.fees.map((p)=>
                        (
                            
                            !p ? '' :
                            <List.Item  key={'FP'+p.UID}>
                                <MyAccordion title={`${p.Title} ${p.Display}`} style={{padding:1}}>
                                    <Accordion.Content >
                                    {
                                        ! p.fees ?'':
                                        <List bulleted style={{paddingLeft:'23px'}}>
                                        {
                                            p.fees.map(fee=>
                                            (
                                                    <List.Item key={p.UID+fee.group}>
                                                        <List.Content>
                                                            { fee.name}: {fee.fee} €
                                                        </List.Content>
                                                    </List.Item>
                                            )

                                            )
                                        }
                                        </List>
                                    }
                                    </Accordion.Content>
                                </MyAccordion>
                            </List.Item>

                        ))
                    }
                    </List>
                </>
            }
       
    </>)
}



export const ModalFamily=(props)=>
{
    const {modalParams}=props
    const texts=useTexts('/show/person/family')
    const {UID,person}=modalParams[0]
    return(<>
        <Modal.Header as ="h2" content={texts.Header}/>
        <Modal.Content>
            <FamilyList
                UID={UID}
                person={person}
            />
        </Modal.Content>
   </> )

}

const Family=(props)=>
{
    const {setModalParams,UID, person,timestamp,PopupHide,group}=props
    

    const [family,setFamily]=useState({fees:[], feeAddress:{UID,type:'person',Display:person.Display}})
    const texts=useTexts('/show/person/family')
    const [year,setYear]= useState(null)
    const config=useConfig()
    const dateFormat={year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false,locale:config.locale,jsTime:true}
    const {apiSelect}=useApi()
 
    useEffect(()=>{
        const getFamily= async()=>
        {
            const yearGet=year ? `?year=${year}` :''
            const result=await apiSelect('/kpe20/family/'+UID+yearGet,'kpe20')
            if(result.success)
            {
                setFamily(result.result)
            }
        }
        if(UID)
       
            getFamily()
    },[UID,year])


    const yearOptions=useMemo(()=>{
            const result=[]
            for(let i=0; i<=new Date().getFullYear()-config.fees.historicFirstYear; ++i)
        
                result.push(
                {
                    key:i,
                    text: (parseInt(config.fees.historicFirstYear) +i).toString(),
                    value: parseInt(config.fees.historicFirstYear)+i
                })
            return result
        },[config]
    )
    

    return(<>
        <Header as ="h4">
            <PopupHide
                        content={texts.edit} 
                        trigger={
                            <Icon 
                                name='edit' 
                                className='editIconColor'
                                onClick={()=> setModalParams({
                                    addLevel: {
                                        component: EditFamily,
                                        UID:UID,
                                        person: person,
                                        UIDparent: group.UID,
                                    }
                                })}             
                            />
                        }
            />
            {texts.edit}
        </Header>
        
        <Segment>
            
 
            <Form>
                    <Form.Dropdown
                        selection
                        label={texts.feeYear}
                        options={yearOptions}
                        value={year}
                        clearable
                        onChange={(ev,{value})=>setYear(value)}
                        placeholder={texts.feeYearPlaceholder}
                        width={4}
                    />
                    {
                        ! year ? '':
                        <p>
                            {texts.yearExplanation?.before} 
                            &nbsp;{showDatum(new Date(`${year}-${config.fees.enterTreshold}`).valueOf(),dateFormat)}&nbsp;
                            {texts.yearExplanation?.between} 
                            &nbsp;{showDatum(new Date(`${year}-${config.fees.leaveTreshold}`).valueOf(),dateFormat)}&nbsp;
                            {texts.yearExplanation?.after} 
                        </p>
                    }
            </Form>

            <Header as ="h2" content={texts.Header}/>
           
            <FamilyList
                UID={UID}
                person={person}
                year={year}
                family={family}
                
            />
            <Header as ="h4">
                <PopupHide 
                    content={texts.FeeAddressPopup} 
                    trigger={
                        <Icon 
                            name='edit' 
                            className='editIconColor'
                            onClick={()=> setModalParams({
                                addLevel: {
                                    component: ModalFeeAddress,
                                    UID:family.UID,
                                    family: family,
                                    UIDperson: UID,
                                    person: person
                                }
                            })}                                           
                        />
                    }
                />
                {texts.FeeAddress}
            </Header>
            {  family?.feeAddress?.Display}

        </Segment>

    </>)

}

export default Family
