import React, {useState, useEffect, useRef}  from 'react'
import {Container, Header, Form, Button, Modal} from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect, useCheckForm,showDatum,DatePicker} from '@wollo-lib/wollo-react'


import useTexts from '../lib20/useTexts'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {useRoot, useConfig} from '../lib20/KPE20context' 

const EditJob=(props)=> {

    const texts=useTexts('/edit/job')
    const [member,setMember]=useState()
    const [group,setGroup]=useState()
    const [template,setTemplate]=useState()
    const [templates,setTemplates]=useState([])
    const config=useConfig()
    const {apiSelect,apiInsert}=useApi()
    const {children, resetModal, modalSubmit, modalParams, modalDefaults, setBlockSave}=props
    const {createNew,UID,functions}=modalParams[0]
    const {defaultType,UIDdefault}=modalDefaults
    const [timestamp,setTimestamp]=useState({timestamp:null})
    const [minDate,setMinDate]=useState(null)

    useEffect(() => {

        const initializeJob=async ()=>
        {
            if(defaultType==='person')
            {
                const result=await apiSelect('/kpe20/person/'+UIDdefault)
                if(result.success) {
                    setMember(result.result.UIDBelongsTo)
                }
            }
            if(defaultType==='group')
            {
                setGroup(UIDdefault)
            }

        }
        if(createNew)
            initializeJob()
    },[UID,createNew])
   

    useEffect(()=>{
        if(!member || !group)
            setBlockSave(true)
        else
            setBlockSave(false)
    },[member,group])

    useEffect(()=>{
        const templatesFromMember=async ()=> 
        {
            setTemplates([])
            setTemplate(null)
        }
        if(!member && !group )
            templatesFromMember()
    },[member,group])

    useEffect(()=>{
        const templatesFromMember=async ()=> 
        {
            const result=await apiSelect('/kpe20/member/'+member,'kpe20')
            if(result.success)
            {
                const actMember=result.result
                const actTemplates=functions.filter(t=>(
                    (actMember.gender===t.Data.genderPerson || t.Data.genderPerson==='B')
                ))
                setTemplates(actTemplates.map((el,index)=>({text:el.Display,value:el.UID,key:el.UID})))
            }
        }
        if(member && !group &&  functions.length>0)
            templatesFromMember()
    },[member,group, functions])

    useEffect(()=>{
        const templatesFromGroup=async ()=> {
            const {result:actGroup}=await apiSelect('/kpe20/group/'+group,'kpe20')
            const actTemplates=functions.filter(t=>
            {
                if(t.hierarchies)
                {
                    return (t.hierarchies.includes(actGroup.hierarchie) &&
                            (actGroup.gender===t.Data.genderGroup || actGroup.gender==='C' || actGroup.gender==='B')
                                && (actGroup.stage===0 || actGroup.stage===t.Data.stage)) 
                }
                else
                {
                    return false
                }
            })
            setTemplates(actTemplates.map((el,index)=>({text:el.Display,value:el.UID,key:index})))
            setMinDate(actGroup.validFrom*1000)
        }
        if(group&& !member &&  functions.length>0)
           templatesFromGroup()
    }, [member,group,functions])




    useEffect(()=>{
        const templatesMemberGroup=async ()=> {
            const [{result:actMember},{result:actGroup}]=await Promise.all([apiSelect('/kpe20/object/'+member,'kpe20'),apiSelect('/kpe20/object/'+group,'kpe20')])
            const actTemplates=functions.filter(t=>{
                    // filtering the selectable functions
                    // depending on what is already entered
                    if(actMember)
                    {
                        if(!t.Data.genderPerson.includes(actMember.gender))
                            return false
                    }
                    if(actGroup)
                    {
                        if(!t.Data.hierarchies.includes(actGroup.hierarchie) ||
                            !t.Data.genderGroup.includes(actGroup.gender) )
                                return false
                        if(actGroup.stage!==0 && actGroup.stage!==t.Data.stage) 
                            return false
                    }
                    return true                 
            })
            setTemplates(actTemplates.map((el,index)=>({text:el.Display,value:el.UID,key:UID})))
            setMinDate(actGroup.validFrom*1000)
        }
        if(group&& member &&  functions.length>0)
            templatesMemberGroup()
    },[member,group,functions])

    const {errors:timeErrors,checkSubmit:checkTimeSubmit}=useCheckForm(timestamp, 
        [
            {name: 'timestamp', error: texts.errorTimestamp ,test:(val)=>{
                return val!==undefined && val!=='' && val!==0 && val!==null
            }},
            {name: 'timestamp', error: texts.errorTimestampMin + 
                    showDatum(minDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
                return !val || val>=minDate 
            }, display:'always'},
            {name: 'timestamp', error: texts.errorTimestampMax ,test:(val)=>{
                return !val || val<=new Date().setUTCHours(23,59,59,9999).valueOf()
            }, display:'always'}
        ]) 
    
    const {errors,checkSubmit}=useCheckForm({
            member:member,
            group:group,
            template: template,

        }, 
        [
            {name: 'member', error: texts.errorProvided ,test:(val)=>{
                return val!==undefined && val!=='' && val!==0 && val!==null
            }},
            {name: 'group', error: texts.errorProvided ,test:(val)=>{
                return val!==undefined && val!=='' && val!==0 && val!==null
            }},
            {name: 'template', error: texts.errorProvided ,test:(val)=>{
                return val!==undefined && val!=='' && val!==0 && val!==null
            }},
           
        ]) 
    
  

    modalSubmit.current=async ()=> {
        const check1= !checkSubmit()
        const check2= !checkTimeSubmit()
        if(check1 ||  check2)
            return
        if(member && group && template) {
           //const res=await apiInsert(`/kpe20/job/${member}/${group}/${template}?timestamp=${timestamp.timestamp}`,{empty:true},'kpe20')
           const res=await apiInsert(`/kpe20/job/${member}/${group}/${template}?`,{empty:true},'kpe20')

           if(!res.success)
                window.alert(res.message)
        
        }

        resetModal()
    }
    

    return(<>
        <Modal.Header>{texts.Job}</Modal.Header>
        <Modal.Content>
            <Form>
                <Form.Field
                    label={texts.Person}
                    placeholder={texts.Person}
                    control={SearchSelect}
                    api="kpe20"
                    SuggestUrl={`/kpe20/SearchData?types=${JSON.stringify(['person','guest','extern','job'])}`}
                    Value2TitleUrl='/kpe20/Value2Title'
                    value={member}
                    autoSelect
                    onChange={(ev,{value,key})=>{setMember(value)}}
                    name='member'
                    error={errors.member}
                    SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                />
                 <Form.Field
                    label={texts.Group}
                    placeholder={texts.Group}
                    control={SearchSelect}
                    api="kpe20"
                    SuggestUrl={`/kpe20/SearchData?types=group&changeable=1`}
                    Value2TitleUrl='/kpe20/Value2Title'
                    name="UIDgroup"
                    value={group}
                    autoSelect
                    onChange={(ev,{value})=>{setGroup(value)}}
                    error={errors.group}
                />
                {
                    !member || ! group ? '':
                    <Form.Select
                        label={texts.Job}
                        placeholder={texts.Job}
                        options={templates}
                        search
                        onChange={(ev,{value})=>{setTemplate(value)}}
                        value={template}
                        name='template'
                        error={errors.template}
                    />
                }
                {
                    group &&
                    <Form.Field
                        label= {texts.Date}
                        control={DatePicker}
                        jsTime
                        value={timestamp.timestamp}
                        onChange={(ev,{value})=>setTimestamp({timestamp:value})}
                        maxDate={new Date().setUTCHours(23,59,59,9999).valueOf()}
                        name='timestamp'
                        error={timeErrors.timestamp}
                        minDate= {minDate}
                        locale={config.locale}
                    />
                }
            </Form>
        </Modal.Content>
       
                {children}
    

    </>)
}

export default EditJob