import React, {useState, useEffect}  from 'react'
import {Dropdown} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'


const MenuDev=(props) => {
    const texts=useTexts('/show/person')
    const {person,UID,group,setModalParams,resetModal,isDesktop}=props

    const clickHandler=async (ev,{name,size,dimmer,closeicon})=>
    {
        const module=await import('./modals/'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size,
                closeicon,
                ...props
            }
        })
    }

    return(
        <Dropdown item simple={isDesktop} text={texts.MenuDev}  >
            <Dropdown.Menu>
                <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalVisible' 
                    size='large' 
                    closeicon
                    text={texts.MenuVisible}
                />
                <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalPersonData' 
                    size='large' 
                    closeicon
                    text={texts.MenuPersonData}
                />
            </Dropdown.Menu>
        </Dropdown>

    )

}  

export default MenuDev
