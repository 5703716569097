import React, {useState, useEffect}  from 'react'
import {Dropdown} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'
import useTexts from '../../lib20/useTexts'
import { useConfig } from '../../lib20/KPE20context'
import ModalRail from '../../lib20/Rails/ModalRail'

const MenuEdit=(props) => {
    const texts=useTexts('/show/group')
    const config = useConfig()
    const {UID,person,isDesktop,group,modalParams,setModalParams,resetModal,timestamp, functions, achievements, actions, objectActions}=props
    const {apiInsert}=useApi()

    const addons = config.addons?.filter(a => a.menus?.includes('group/edit'))


    const clickHandler=async (ev,{name,size,dimmer,closeicon,modalProps})=>
    {
        const module=await import('./modals/'+name)
        setModalParams({
            addLevel:{
                UID:UID,
                component:module.default,
                size: size,
                closeicon:closeicon,
                functions,
                achievements,
                actions,
                objectActions,
                objectType:'group',
                ...props,
                ...modalProps
            }
        })
    }

    const exit=async ()=>
    {
        if(window.confirm(texts.confirmExit))
        {
            const result=await apiInsert('/kpe20/extern/'+group.UID,{UID:UID,...person.Data})
            if(result.success)
            {
                window.alert(texts.exitConfirm)
            }
        }
    }

    useEffect(()=>{
        if(modalParams.length>0 )
        {
            setModalParams({modify:{timestamp:timestamp,objectActions}})
        }
    },[timestamp,objectActions])



    return(

        <Dropdown item simple={isDesktop} text={texts.MenuEdit} key={'groupEdit'} >
            <Dropdown.Menu key={'groupEditMenu'}>
            
                <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalEditFee'
                    text={texts.EditFee} 
                    person={person}
                    group={group}
                    size='large'
                    key={'GroupModalEditFee'}
                    icon= 'euro'
                />
                 <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalBankAccount'
                    text={texts.BankAccounts} 
                    person={person}
                    group={group}
                    size='large'
                    key='GroupModalBackAccount'
                    icon= 'university'
                />

                <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalEditAddress'
                    text={texts.Address}
                    key='GroupModalEditAddress'
                    ivon='edit'
                    icon='home'

                />
                <Dropdown.Divider/>

                <Dropdown.Item 
                    onClick={clickHandler} 
                    size='large' 
                    name='ModalAvatar'
                    text={texts.Logo}
                    key='GroupModalAvatar'
                    icon='image'

                />
                 <Dropdown.Item
                    component={ModalRail}
                    key={'rail'}
                    text={texts.groupRail}
                    closeicon
                    onClick={ev=>
                        setModalParams({addLevel:{
                            component:ModalRail,
                            railName:`${group.Title} ${group.Display}`,
                            railPath: UID,
                            setModalParams: setModalParams}})
                    }
                    icon='train'
                />
                <Dropdown.Divider/>
                <Dropdown.Item 
                    onClick={clickHandler} 
                    size='large' 
                    name='ActionsPlaceholder'
                    text={texts.Actions}
                    closeicon
                    key='GroupActionsPlaceholder'
                    icon='spinner'

                />
                
                <Dropdown.Divider/>  

                {addons && addons.map((addon,index) => (
                    <Dropdown.Item
                        onClick={clickHandler} 
                        text={addon.name}
                        size='large'
                        name='Addon'
                        modalProps={{
                            addon:addon,
                            UID:UID,
                        }}
                        key={`addon${index}`}
                        icon={addon.icon}
                    />
                ))}
                
            </Dropdown.Menu>
        </Dropdown>

    )

}  

export default MenuEdit
