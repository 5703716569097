import React,{ useState, useEffect, useRef}  from 'react';
import {Header,Form,Popup, Modal, Menu,Icon, Button, Input, Accordion} from 'semantic-ui-react'
import useTexts from '../useTexts'
import ButtonSelect from './ButtonSelect'
import {useConfig} from '../KPE20context'





const ShowElement=(props)=>
{
    const texts=useTexts('lib20Texts','FilterElement')
    const config=useConfig()

    const opers= {
        EQ: '=',
        NE : '<>',
        GT :  '>',
        GE : "\u2265",
        LT : '<',
        LE : "\u2264",
        IN : texts.valueIncluded,
        NI : texts.valueNotIncluded
    }

    const opersInverted= {
        EQ: '=',
        NE : '<>',
        GT :  '<',
        GE : "\u2264",
        LT : '>',
        LE : "\u2265",
        IN : texts.valueIncluded,
        NI : texts.valueNotIncluded
    }

    const paraOpers=    {
        hierarchie: opersInverted,
        stage: opers,
        gender: opers,
        age: opers, 
        openBills: opers,
        family: opers,
        achievement: opers,
        category: opers,
        groupHierarchie: opersInverted,
        groupStage: opers,
        groupGender: opers,
        groupCount: opers,
        functionStage: opers,
        functionHierarchie: opers,
        function: opers,
        vote: opers,
        qualified: opers,
        categories: opers,
        eventHierarchie: opersInverted,
        birthday: opers,
        birthdayMonth: opers,
        eventStage: opers,
        eventGender: opers,
        none: opers,
        all: opers
    }


    // defines the parameters to be filtered for and the type of possible operations
    
    const logTags=[
        { value:'and', key:'and' ,text:texts.lAnd}, 
        { value:'or', key:'or', text:texts.lOr}, 
        { value:'xor' , key:'xor',text:texts.lXor},
        { value: 'nand', key:'nand',text:texts.lNand},
        { value: 'nor', key:'nor', text:texts.lNor}
    ]

    const {value,functions,achievements,name,type}=props
    const [tag,setTag]= useState() // tags are the combination of tag and oper
    const [oper,setOper]=useState()
    const [para,setPara]=useState()


    let logical= ['and','or','nand','nor','xor'].includes(tag)

   

    const paras=  ! config.UID ? {
            groupStage:[],
            stage:[],
            hierarchie:[],
            gender:[],
            age:[],
            openbills:[],
            family:[],
            category:[],
            function:[],
            personGender:[],
            groupGender:[],
            groupHierarchie:[],
            groupStage:[],
            groupCount:[],
            functionStage:[],
            qualified:[],
            vote:[],
            eventHierarchie:[],
            eventStage:[],
            eventGender:[],
            birthday:[],
            birtdayMonth:[]
        }: {
        groupStage: config.Stage.map((stage,Index)=>({value:Index,key:Index, text:config.Stage[Index]})),
        stage: config.Stage.filter((stage,Index)=>(Index>0)).
                    map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
        hierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
                        map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        gender: Object.keys(config.Gender).map(key=>({value:key, key: key,text:config.Gender[key] })),
        age: Array(110).fill(0,0,110).map((age,Index)=>({value:Index,key:Index, text: Index})),
        openBills: [{key:1,value:1,text:texts.yes},{key:0,value:0,text:texts.no}],
        family: Array(15).fill(0,0,15).map((family,Index)=>({value:Index,key:Index, text: Index})),
        function: functions.map((el)=>({key:el.UID,value:el.UID, text:el.Display})),
        personGender:  Object.keys(config.Gender).map(key=>({value:key, key: key,text:config.Gender[key] })),
        groupGender:  Object.keys(config.GenderGroups).map(key=>({value:key, key: key,text:config.GenderGroups[key] })),
        groupHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        groupStage:  config.Stage.filter((stage,Index)=>(Index>0)).
            map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
        groupCount:  Array(200).fill(0,0,200).map((age,Index)=>({value:Index,key:Index, text: Index})),
        functionHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        functionStage:  config.Stage.filter((stage,Index)=>(Index>=0)).
            map((stage,Index)=>({value:Index,key:Index, text:config.Stage[Index]})),
        qualified: [{key:1,value:1,text:texts.yes},{key:0,value:0,text:texts.no}],
        vote: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        categories: config.FunctionCategories.
            map((c,Index)=>({key:Index,value:c,text:c})),
        category: Object.entries(config.ExternCategories).
            map(([key,value])=>({key:key,value:key,text:value})),
        achievement: achievements.map((a,Index)=>({key:Index, value:a.UID,text:a.Display})),
        eventGender:  Object.keys(config.GenderGroups).map(key=>({value:key, key: key,text:config.GenderGroups[key] })),
        eventHierarchie: config.Hierarchie.filter((hier,Index)=>(Index>0)).
            map((hier,Index)=>({value:Index+1,key:Index, text:config.Hierarchie[Index+1]})),
        eventStage:  config.Stage.filter((stage,Index)=>(Index>0)).
            map((stage,Index)=>({value:Index+1,key:Index, text:config.Stage[Index+1]})),
        birthday: [
                {key:0,value:255,text:texts.today},
                {key:-1,value:-1,text:texts.yesterday},
                {key:1,value:1,text:texts.tomorrow},
                {key:-7,value:-7,text:texts.lastWeek},
                {key:7,value:7,text:texts.thisWeek},
                {key:14,value:14,text:texts.nextWeek},
            ],
        birthdayMonth: [
                {key:255,value:255,text:texts.thisMonth},
                {key:1,value:1,text:texts.January},
                {key:2,value:2,text:texts.February},
                {key:3,value:3,text:texts.March},
                {key:4,value:4,text:texts.April},
                {key:5,value:5,text:texts.May},
                {key:6,value:6,text:texts.June},
                {key:7,value:7,text:texts.July},
                {key:8,value:8,text:texts.August},
                {key:9,value:9,text:texts.September},
                {key:10,value:10,text:texts.October},
                {key:11,value:11,text:texts.November},
                {key:12,value:12,text:texts.December},
        ]
     
 
    }




    useEffect(()=>
    {
        // getter use effect
        if(value && Object.keys(value).length>0)
        {
            const tag= Object.keys(value)[0]
            logical= ['and','or','nand','nor','xor'].includes(tag)
            if(logical)
            {
                setTag(tag)
            }
            else if(tag!=='none' && tag!=='all')
            {
                setTag(tag.substring(0,tag.length-2))
                setOper(tag.substring(tag.length-2))

            }
            else
            {
                setTag(tag)
                setOper(null)
            }
            setPara(value[tag])
        }
        else
        {
            setTag(null)
            setOper(null)
            setPara(null)
        }
      
    },[value])

   

    

    const paraOptions= tag && !logical ?   paras[tag] :[]
    let paraText
    if(!oper || paraOptions.length===0)
        paraText= ''
    else
    {
        if(Array.isArray(para))
        {
            const paraTexts=para.map(p=>(paraOptions.find((pa)=>(pa.value===p)).text))
            paraText='['+paraTexts.join(',')+']'
        }
        else
        {
            paraText = paraOptions.find((pa)=>(pa.value===para)).text
        }
    }
                            
    return (
    <Menu key={name} compact>
       


                {logical ? 
                    logTags.find(el=>(el.value===tag)).text
                    :
                        typeof type==='string' ?
                            texts[type+'_'+tag] 
                        :
                            type.reduce((result,myType)=>{
                                return texts[myType+'_'+tag] ? texts[myType+'_'+tag] :result
                            },'')
                }

                {
                    !tag  ? '' :
 
                    logical ?
                    <Menu vertical text compact> 

                    {   

                           para.map((cond,Index)=>(
                               
                                    <ShowElement
                                        value={cond}
                                        name={name+parseInt(Index)}
                                        functions={functions}
                                        achievements={achievements}
                                        type={type}
                                    />
                               
                            ))
                    }
                    </Menu>
                    :
                    <>
                        &nbsp;{paraOpers[tag][oper]}&nbsp;
                           
                        {
                           paraText
                        }
                    </>
                }  
    </Menu>
    ) 
}

export default ShowElement