import React, {useEffect, useState} from 'react'
import {Form, Header, Segment, Icon, Dropdown, Flag, Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../../lib20/useTexts'
import {useConfig} from '../../../../lib20/KPE20context'
import {useCheckFormArray} from '../../../../Hooks/useCheckFormArray'
import {accountTypes} from './types.js'
import { IBAN,BIC } from 'ibankit'
import { DatePicker } from '@wollo-lib/wollo-react'


const EditAccounts =(props)=> {

    const {apiUpdate,apiSelect}=useApi()
    const config=useConfig()
    const texts=useTexts('/show/person')
    const [accounts, setAccounts]=useState([])
    const {children, modalParams, resetModal, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]

    const excludeFees=config.fees?.type && ['personFees','familyFees','familyPersonFees'].includes(config.fees.type) ?  ['familyFees','familyPersonFees'].includes(config.fees.type) ? ['personFees'] : ['familyFees'] : ['familyFees','familyPersonFees']
    const accountOptions=Object.keys(accountTypes).filter(el=>(!excludeFees.includes(el))).map(option=>({
        key:option,
        icon:accountTypes[option],
        text:texts[option],
        value:option
    }))


    const {errors,getError,checkSubmit}=useCheckFormArray(accounts,
        [
            {name:'type', error: texts.invalidAccountType,test:(val,index)=>(
                val!=='')},
            {name:'IBANshow', error: texts.invalidIBAN,test:(val,index)=>
            {
                  const result=  IBAN.isValid(val)
                  return result
            }},
            {name:'IBANowner', error: texts.invalidOwner,test:(val,index)=>(
                val!=='')},
            {name:'BIC', error: texts.invalidBIC,test:(val,index)=>
            {
                  const result=  BIC.isValid(val)
                  return result
            }},
            {
                name: 'SEPA', error: texts.sepaDataObligatory, test:(val,index)=>
                {
                    const result=!['personFees','familyFees'].includes(accounts[index].type) || (val && val.date!==null)  
                    return result
                }


            }
        
        ]
    )


    modalSubmit.current= async ()=> {
        if(checkSubmit())
        {  
            
                const result=await apiUpdate('/kpe20/person/'+UID,{accounts:accounts})
                {
                    if(result.success)
                        resetModal()
                }
        }
    }


 

    const add=async () => {
        const {UID:mandatNo}=await apiSelect('/kpe20/UID?base64=1')

        if( config.fees && 
            ['personFees','familyFees','familyPersonFees'].includes(config.fees?.type) &&
             ! accounts.some(el=>['familyFees','personFees'].includes(el.type))
        )
        {

            const feeType=  ['familyFees','familyPersonFees'].includes(config.fees?.type) ? 'familyFees' : 'personFees'
            setAccounts((p) => ([{type:feeType, IBANshow:'', IBANowner:'',BIC:'', SEPA:{mandatNo:mandatNo,date:null}},...p ]))
        }
        else
        {
            setAccounts((p) => ([...p, {type:'person', IBANshow:'', IBANowner:'',BIC:'', SEPA:{mandatNo:mandatNo,date:Date.now()/1000}}]))
        }
    }

    const handleChange=async (ev, {name, value, index}) => {

        if(name==='type')
        {
            if(['personFees','familyFees'].includes(value))
            {
                // set sepa mandat and date
                const {UID:mandatNo}=await apiSelect('/kpe20/UID?base64=1')
                setAccounts(aas=>
                {  
                    const account=   {...aas[index],type:value, SEPA:{mandatNo:mandatNo,date:null} }
                    const unModifiedAccounts=aas.filter((el,Index)=>Index!==index)
                        .map(el=>['personFees','familyFees'].includes(el.type) ? {...el,type:'person'} : {...el})
                    console.log( [account,...unModifiedAccounts])
                    return  [account,...unModifiedAccounts]
                })
               
            }
            else
            {
                setAccounts(aas=> aas.map((a,Index)=>
                    Index===index ? {...a,type:value} : {...a}
                ))
            }
        }
        else if(name==='IBANshow' && IBAN.isValid(value))
        {
            const {UID:mandatNo}=await apiSelect('/kpe20/UID?base64=1')
            const iban=new IBAN(value)
            let BIC=''
            if(['AT','BE','DE','NL','LU'].includes(iban.getCountryCode()))
                BIC=window.ibanToBic.ibanToBic(iban.toString());
            setAccounts(aas=> aas.map((a,Index)=>
                Index===index ? {...a,IBAN:iban.toString(),IBANshow:iban.toFormattedString(),BIC:BIC,SEPA:{date:Date.now()/1000,mandatNo:mandatNo}} : {...a}
            ))
        
        }
        else if(name==='SEPAdate')
        {
            setAccounts(aas=> aas.map((a,Index)=>
                Index===index ? {...a,SEPA:{
                    date:value,
                    mandatNo: a.SEPA.mandatNo
                }} : {...a}
            ))
        }
        else
        {
            setAccounts(aas=>aas.map((a,Index)=>
                Index===index ? {...a,[name]:value} :{...a}
            ))
        }

    }

    const remove=(e, {index:removeIndex}) => {
        setAccounts(accounts.filter((account, index) => index !== removeIndex))
    }


    useEffect(() => {
        let myAccounts=[...person.Data.accounts]
        // add the bics, if forewhatever reason they do not exist and we have an algorithm to to so
        myAccounts.forEach(account=>
        {
            if(account.IBAN && !account.BIC)
            {
                const iban=new IBAN(account.IBAN)
                if(['AT','BE','DE','NL','LU'].includes(iban.getCountryCode()))
                {
                    account.BIC=window.ibanToBic.ibanToBic(iban.toString());
                }
            }
        })
        if(myAccounts.length===0)
        {
            myAccounts.push({type:'familyFees', IBAN:'', IBANshow:'', IBANowner:'',BIC:'', SEPA:{mandatNo:'',date:null}})
        }
        setAccounts(myAccounts.map(a=>({...a,IBANshow:a.IBAN ? IBAN.printFormat(a.IBAN) : a.IBANshow})))
    }, [person])




    return(<>

        <Modal.Header as='h3'>{texts.ModalBankAccountEdit+person.Display}</Modal.Header>
        <Form as={Modal.Content} scrolling>
            {accounts.map((account, index) => (<>
                <Segment key={index}>
                    <h3 style={{marginBottom:'25px'}}>
                        <Form.Group>
                        <Header as="h4">
                            <Icon name={accountTypes[account.type]} />
                            <Header.Content>
                            <Dropdown 
                                value={account.type}
                                options={accountOptions} 
                                onChange={handleChange}
                                name='type'
                                index={index}
                                error={getError('type',index)}
                                pointing='top left'
                                width={3}
                            />
                            </Header.Content>
                           </Header>
                            <Form.Button 
                                icon='trash' 
                                name={account} 
                                index={index} 
                                onClick={remove} 
                                floated='right' 
                                compact 
                                negative 
                                width={6}
                            />
      
                        </Form.Group>
                    </h3>
                    
                    <Form.Input
                        placeholder={texts.IBAN}
                        value={account.IBANshow}
                        name='IBANshow'
                        onChange={handleChange}
                        index={index}
                        error={getError('IBANshow',index)}
                    />
                    
                    <Form.Input
                        placeholder={texts.IBANowner}
                        value={account.IBANowner}
                        name='IBANowner'
                        onChange={handleChange}
                        index={index}
                        error={getError('IBANowner',index)}
                    />
                    <Form.Input
                        placeholder={texts.BIC}
                        value={account.BIC}
                        name='BIC'
                        onChange={handleChange}
                        index={index}
                        error={getError('BIC',index)}
                    />
                    { ['familyFees','personFees'].includes(account.type) &&
                        <>
                            <Header as='h4'>
                                {texts.sepaRef}: {account.SEPA?.mandatNo.replaceAll('=','')}
                            </Header>
                            <Form.Field
                                control= {DatePicker}
                                label= {texts.sepaDate}
                                value={account.SEPA?.date}
                                placeholder= {texts.sepaDate}
                                name='SEPAdate'
                                onChange={handleChange}
                                error={getError('SEPA',index)}
                                index={index}
                            />
                        </>

                    }

                  

                </Segment>
            </>))}
            <Form.Button icon='add' content={texts.add} onClick={add} positive/>
            
        </Form>
        {children}
    </>)
}
export default EditAccounts