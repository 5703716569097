import React, {useState, useEffect, useRef} from 'react'
import { Header, Form, Modal, StepGroup}  from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'
import {SearchSelect, DatePicker, showDatum} from '@wollo-lib/wollo-react'
import { useCheckForm } from '../../../Hooks/useCheckForm'
import useTexts from '../../../lib20/useTexts'
import EditPhone        from '../tabs/modals/ModalPhone'
import EditMessenger    from '../tabs/modals/ModalMessenger'
import EditAddress      from '../tabs/modals/ModalAddress'
import EditMail         from '../tabs/modals/ModalMail'
import SelectFamily     from './SelectFamily'
import { useConfig } from '../../../lib20/KPE20context'


const EnterGroup=(props)=>
{
    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}=props
    const {group,text,textDate,person,setPerson, groupQuery, Type}=modalParams[0]
    const {apiInsert, apiSelect}=useApi()
    const [timestamp,setTimestamp]=useState({timestamp:null})
    const [minDate,setMinDate]=useState(null)
    const [minLinkDate,setMinLinkDate]=useState(null)
    const texts=useTexts('/edit/person')
    const config=useConfig()

    const [myGroup,setMyGroup]=useState({UID:null})

    const {errors:groupErrors,checkSubmit:checkGroupSubmit}=useCheckForm(myGroup,
    [
        {name: 'UID', error: texts.errorGroup ,test:(val)=>{
                return val!==undefined && val!=='' && val!==null
        }}
    ])
   
    const {errors:timeErrors,checkSubmit:checkTimeSubmit}=useCheckForm(timestamp, 
    [
        {name: 'timestamp', error: texts.errorTimestamp ,test:(val)=>{
            return val!==undefined && val!=='' && val!==0 && val!==null
        }},
        {name: 'timestamp', error: texts.errorTimestampMin + 
                showDatum(minDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
            return !val || val>=minDate 
        }, display:'always'},
        {name: 'timestamp', error: texts.errorTimestampLinkMin + 
                showDatum(minLinkDate,{year:"numeric",month:"2-digit", day:"2-digit",locale:config.locale, milliseconds:true}) ,test:(val)=>{
            return !val || !minLinkDate || val>=minLinkDate 
        }, display:'always'},
        {name: 'timestamp', error: texts.errorTimestampMax ,test:(val)=>{
            return !val || val<=new Date().setUTCHours(23,59,59,9999).valueOf()
        }, display:'always'}
    ]) 

    useEffect(()=>{
        const getPerson= async ()=>
        {
            const result=await apiSelect(`/kpe20/person/${person.UID}/?parent=1`)
            if(result.success)
            {
                setMinLinkDate(result.result.parent.LvalidFrom*1000)
                setTimestamp({timestamp:Date.now()})
            }
        }
        if(person.UID)
            getPerson()

    },[person])

     
    const changeGroup=async (ev,{value,result})=>
    {
       setMyGroup({UID:value})
       setMinDate(result.validFrom*1000)
       if(timestamp.timestamp< result.validFrom*1000)
       {
            setTimestamp({timestamp:result.validFrom*1000})
            window.alert(texts.alterTimestamp)
       }
    }



    modalSubmit.current=async (event)=> 
    {
        const error1=!checkGroupSubmit()
        const error2=!checkTimeSubmit()
        if(error1  ||  error2)
            return
        let queryString=''
        if(Date.now()-timestamp.timestamp >= 24*3600)
            queryString='?timestamp='+timestamp.timestamp
        const result=await apiInsert(`/kpe20/${Type}/`+myGroup.UID+queryString,{...person,phone:[],address:[],email:[],messenger:[],accounts:[]})
        if(result.success)
        {
            setPerson(result.result)
            setModalParams({setAll:[
                {
                    component: SelectFamily,
                    UID:    result.result.UID,
                    person: result.result,
                    setModalParams: setModalParams,
                    UIDparent: myGroup.UID
                    
                
                },
                {
                    component: EditAddress,
                    UID:    result.result.UID,
                    person: result.result,
                    UIDparent: myGroup.UID
                },
                {
                    component: EditMail,
                    UID:      result.result.UID,
                    person: result.result,
                    UIDparent: myGroup.UID,
                },
                {
                    component: EditPhone,
                    UID:     result.result.UID,
                    person: result.result,
                    UIDparent: myGroup.UID
                },

            ]})
           
        }
        else
        {
            window.alert('error update person: '+result.message)
           
        }
    }
    
    const now=new Date()

    return(
    <Modal.Content>
        <Form>
            <Header as='h3' content={text}/>
            <Form.Field
                label= {text.Date}
                control={DatePicker}
                jsTime
                value={timestamp.timestamp}
                onChange={(ev,{value})=>setTimestamp({timestamp:value})}
                maxDate={new Date().setUTCHours(23,59,59,9999).valueOf()}
                name='timestamp'
                error={timeErrors.timestamp}
                minDate= {minDate}
                locale={config.locale}
            />
        <Form.Field
                label = {texts.Group}
                placeholder = {texts.Group}
                control = {SearchSelect}
                api = "kpe20"
                SuggestUrl={'/kpe20/SearchData?types=group'+groupQuery}
                Value2TitleUrl={'/kpe20/Value2Title?types=group'+groupQuery}
                name = "UIDgroup"
                value = {myGroup.UID}

                autoSelect
                onChange={changeGroup}
                error={groupErrors.UID}
            />
        </Form>
        {children}

    </Modal.Content>)
}

export default EnterGroup