import React, {useState, useEffect}  from 'react'
import {Dropdown,Icon,  Modal, Header, Image, Tab} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'



const MenuEdit=(props) => {
    const texts=useTexts('/show/person')
    const {person,group,setModalParams,resetModal}=props

    const clickHandler=async (ev,{name,size,dimmer,closeicon})=>
    {
        const module=await import('../job/modals/'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size,
                closeicon,
                ...props
            }
        })
    }

    return(

        <Dropdown item simple text={texts.MenuDev} >
            <Dropdown.Menu>
                <Dropdown.Item 
                    onClick={clickHandler} 
                    name='ModalVisible' 
                    size='large' 
                    closeicon
                    text={texts.MenuVisible}
                />
            </Dropdown.Menu>
        </Dropdown>

    )

}  

export default MenuEdit
