import React, { useState, useEffect } from 'react'
import { Header, Modal, Segment,Icon, Popup } from 'semantic-ui-react'
import MyTabulator,{ dateFormatter}  from   '@wollo-lib/wollo-tabulator'
import useTexts from './lib20/useTexts'
import { useWindowDimensions } from '@wollo-lib/kpe-context'    //'./kpe-context/src'
import { useHistory } from 'react-router'

const ModalHome = (props) => {

    
    const {  modalParams,resetModal} = props
    const { type,myLists } = modalParams[0]

    const history=useHistory()
    const texts=useTexts('Home')

    const iconName={list:'list',dlist:'database',email:'mail'}
    const haederTexts={list:texts.headerMyLists,dlist:texts.headerMyDlists,email:texts.headerMyEMails }
    const {height}=useWindowDimensions()
    
    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        if(rowData.Type==='dlist')
            history.push('/edit/dlist/sources/'+rowData.UID)
        else
            history.push(`/edit/${rowData.Type}/${rowData.UID}`)
        resetModal()
      

    }

    const listColumns=[     
        {  title: haederTexts[type],field: 'Display', width: 250,  headerFilter:"input"},
        { field: 'member', width: 200, headerFilter:"input"},
        { field: 'validFrom', width: 100,                                     formatter: dateFormatter, 
            formatterParams:{
                format:  {year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false, locale:texts.dateLocale} 
            },   
        }
        
    ]  


    return(
    <>
        <Header>
            <Icon name={iconName[type]}/>
            <Header.Content>
                {haederTexts[type]}
            </Header.Content>
        </Header>
        <Modal.Content>
            <MyTabulator
                index='UID'
                columns={listColumns}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data= { myLists.filter(list=>list.Type===type)}
                height={Math.floor(height*0.7)}                    
                compact="very"
                layout="fitColumns"  
                groupBy={['pGroup']}
                onClick={cellClick}

            />
       </Modal.Content>
    </>
    )
}
export default ModalHome