
import  currency from 'currency.js'
import {dateFormatter, dateEditor, formatMoney, editMoney, mutateMoney} from '@wollo-lib/wollo-tabulator'
import ReactDOMServer from 'react-dom/server'
import {Flag} from 'semantic-ui-react'
export const nameReplace=(str)=>
{
    return  str.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
}



export const getExtraCol=(para,locale,decimalPoint, currencySymbol)=>
{
    const onClick=(e,cell)=>
    {
        const value=cell.getValue()
        cell.setValue(!value,false)

    }
    
    const moneySum= (values)=>
    {
        return currency(values.reduce((summe,current)=>(summe+ (isNaN(current) || current===null ? 0 : parseInt(current))),0),{
            symbol: currencySymbol,
            decimal: decimalPoint,
            separator: "'",
            fromCents: true
        }).format()
    }
    switch(para.type)
            {
                case 'string':
                    return {
                        title: para.name,
                        field: '__extra_'+nameReplace(para.name), 
                        minWidth:   120, 
                        editor: 'input',
                        headerFilter: 'input'
                    }
                    break
                case 'integer':
                    return {
                            title: para.name, 
                            field: '__extra_'+nameReplace(para.name), 
                            minWidth:   40, 
                            editor: 'number',
                            topCalc: 'sum',
                            headerFilter: 'number'
                        }
                    break
                case 'date':
                    return {
                                title: para.name, 
                                field: '__extra_'+nameReplace(para.name), 
                                minWidth:  85, 
                                formatter: dateFormatter, 
                                formatterParams:{
                                    format:  {year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false, locale:locale} 
                                },   
                                editor: dateEditor,
                                editorParams:{
                                    format:  {year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false, locale:locale} 
                                }
                            }
                        break
               
                case 'money':

                    return {
                                title: para.name, 
                                field: '__extra_'+nameReplace(para.name), 
                                minWidth: 60,
                                formatter: formatMoney, 
                                formatterParams: {
                                    decimal: decimalPoint,
                                    symbol: currencySymbol
                                },
                                editor: editMoney,
                                editorParams: {
                                    decimal: decimalPoint
                                },
                                topCalc: moneySum,
                                
                            }
                    break
                case 'bool':
                    return {
                            title: para.name,
                            field: '__extra_'+nameReplace(para.name), 
                            minWidth:40, 

                            mutator: (value, data, type, params, component)=>{
                                return value==='1' || value===1 ? true : false
                            },
                            headerFilterParams:{triState:true},
                            editorParams: {triState:true},
                            cellClick: onClick,
                            topCalc: 'count',
                            headerFilter: "tickCross",
                            formatter:"tickCross", 
                        }
                    break
                  
            }

}

export const getExtraField=(para,locale,decimalPoint,currencySymbol)=>
{
    const moneySum= (values)=>
    {
        return currency(values.reduce((summe,current)=>(summe+ (isNaN(current) || current===null ? 0 : parseInt(current))),0),{
            symbol: currencySymbol,
            decimal: decimalPoint,
            separator: "'",
            fromCents: true
        }).format()
    }
    switch(para.type)
    {
        case 'string':
            return {
                title: para.name, 
                field: para.alias, 
                minWidth:  para.width ? para.width : 120,
                headerFilter: "input",
                tooltip: para.tooltip
            }
            break
        case 'integer':
            return {
                    title: para.name, 
                    field: para.alias, 
                    minWidth: para.width ? para.width : 60,
                    topCalc: !para.sumCalc &&  para.sumCalc !== undefined  ? null :  'sum',
                    headerFilter: "integer",
                    tooltip: para.tooltip
                }
            break
        case 'date':
            return {
                        title: para.name, 
                        field: para.alias, 
                        minWidth: para.width ?? 85,
                        formatter: dateFormatter, 
                        formatterParams:{
                            format:  {year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false, locale:locale} 
                        },
                        tooltip: para.tooltip   
                    }
                break
        case 'money':
            return {
                        title: para.name, 
                        field: para.alias, 
                        minWidth: para.width ?? 60,
                        formatter: formatMoney, 
                        mutator: mutateMoney,
                        formatterParams: {
                            decimal: decimalPoint,
                            symbol: currencySymbol
                        },
                        sorter: (a, b, aRow, bRow, column, dir, sorterParams)=>{
                            if(a==="0.80" || b==="3.60")
                                debugger
                            console.log(a,b,parseInt(parseFloat(a)*100-parseFloat(b)*100))
                            return parseInt(parseFloat(a)*100-parseFloat(b)*100)
                        },
                        sorterParams:{
                            decimal:'.',
                        },
                        tooltip: para.tooltip,
                        headerSortTristate:true,
                        headerSortStartingDir:"desc",
                        topCalc: !para.sumCalc && para.sumCalc !==undefined ? null : moneySum
                    }
            break
        case 'bool':
            return {
                    title: para.name,
                    field: para.alias, 
                    minWidth: para.width ?? 40, 
                    mutator: (value, data, type, params, component)=>{
                        return value==='1' ? true : false
                    },
                    tooltip: para.tooltip,
                    topCalc: 'count',
                    formatter:"tickCross", 
                    headerFilter: "tickCross",
                    headerFilterEmptyCheck:function(value){
                        return !value; //only filter when the value is true
                    }
                }
            break
        case 'flag':
                return {
                        title: para.name,
                        field: para.alias, 
                        minWidth:40, 

                        formatter:function(cell, formatterParams, onRendered){
                                    const value= cell.getValue()
                                    const html=  ReactDOMServer.renderToString(<Flag name={value}/> ) //return the contents of the cell;
                                    return html
                             }



                    }
                break
    }
}