import React, {useState, useEffect, useRef}  from 'react'
import {Container, Header,Icon, Button, Form, Modal,Image} from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect,  useCheckForm, DatePicker} from '@wollo-lib/wollo-react'
//import useCheckForm from '../Hooks/useCheckForm'
//import { DatePicker } from '../lib/InputDate'

import useTexts from '../lib20/useTexts'
import {useHistory, useLocation} from 'react-router-dom'
import { useConfig} from '../lib20/KPE20context' 


import { DocumentPlaceholder } from '../css/avatarPlaceholder'

const NewEdit=(props) => {

    const texts=useTexts('/edit/achievement')
    const config=useConfig()
  
    const {member,setMember,template,setTemplate,achievements,errors}=props
    const aTemplates=achievements.map(el=> ({key:el.UID, text:el.Display, value:el.UID}))


    



    return (<>
        <Form.Field
            label={texts.Member}
            placeholder={texts.Member}
            control={SearchSelect}
            api="kpe20"
            SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['person', 'guest', 'extern', 'job'])}
            Value2TitleUrl='/kpe20/Value2Title'
            value={member}
            name='member'
            autoSelect
            onChange={(ev, {value})=>{setMember(value)}}
            error={errors.member}
            SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
        /> 

        {!member ? '' :
            <Form.Select
                label={texts.Achievement}
                placeholder={texts.Hierarchie}
                options={aTemplates}
                onChange={(ev, {value})=>{setTemplate(value)}}
                value={template}
                search
                error={errors.template}
            />
        }
    </>)
}



const EditAchievement=(props)=> {

    const texts=useTexts('/edit/achievement/modal')
    
    const {children, resetModal,setModalParams,modalParams, modalSubmit, modalDefaults}=props
    const {createNew,UID,achievements}=modalParams[0]
    const {defaultType,defaultDisplay,UIDdefault}=modalDefaults
    
    const [member, setMember]=useState({})
    const [memberDisplay,setMemberDisplay]=useState()
    const [template, setTemplate]=useState()
    const [templateDisplay,setTemplateDisplay]=useState()
    const [aData, setAData]=useState({date:null})
    const [loading,setLoading]=useState(false)
    const [imageUrl,setImageUrl]=useState()
    const [downloadUrl,setDownloadUrl]=useState()
    const [filename,setFilename]=useState('')
    const config=useConfig()
    const history=useHistory()
    const location=useLocation()



    const {apiSelect, apiDelete,apiInsert,apiPutFile}=useApi()
  
    

    useEffect(()=>{
        if(createNew && defaultType==='person' && UIDdefault)
        {
            setMember(UIDdefault)
        }

        
      
    }, [createNew])

    useEffect(()=>{
        const getAchievement=async () => {

            const result=await apiSelect('/kpe20/achievement/'+UID)
            if(result.success) {
                setMember(result.result.UIDBelongsTo)
                setTemplate(result.result.UIDTemplate)
                setMemberDisplay(result.result.DisplayMember)
                setTemplateDisplay(result.result.DisplayTemplate)
                setAData(result.result.BaseData)
            }
        }
        if(!createNew && UID) {
            getAchievement()
        }
    }, [UID])

    
    const {errors:errors,checkSubmit}=useCheckForm({...aData,member:member,template:template}, 
        [
            {name: 'date', error: texts.errorTimestamp ,test:(val)=>{
                return val!==undefined && val!=='' && val!==0 && val!==null
            }},
            {name: 'date', error: texts.errorTimestampMax ,test:(val)=>{
                return !val || val<=new Date().setUTCHours(23,59,59,9999).valueOf()
            }, display:'always'},
            
            {name: 'member', error: texts.errorProvided ,test:(val)=>{
                return  val!==undefined && val!=='' && val!==0 && val!==null
            }},
            
            {name: 'template', error: texts.errorProvided ,test:(val)=>{
                return  val!==undefined && val!=='' && val!==0 && val!==null
            }},

            
        ]) 

    useEffect(()=>{
        const getAchievementDocuments=async () => {

            const result=await apiSelect('/kpe20/files/'+UID)
            if(result.success) {
                const image=result.files.filter(el=>el.name.match(/.*_PREVIEW\d*((\.png)|(\.jpg))/gm))
                if(image.length)
                    setImageUrl(image[0].url)
                const download=result.files.filter(el=>el.name.match(/^((?!_PREVIEW\d*\.).)*$/gmi))
                    if(download.length)
                        setDownloadUrl(download[0].url)
    
            }
        }
        if(!createNew && UID) {
            getAchievementDocuments()
        }
    }, [UID])

    modalSubmit.current=async () => {

        if(!checkSubmit())
            return
        else
        {
           const res=await apiInsert(`/kpe20/achievement/${member}/${template}`, aData, 'kpe20')
           if(res.success)
           {
                if(createNew)
                {
        
                    setModalParams({
                        replace:
                        {
                            component: EditAchievement,
                            UID:res.result.UID,
                            achievements
                        }
                    })
  
                }
                else
                {
                    resetModal()
                }
           }
           else
           {
               window.alert('Error: '+res.message)
           }
           
        }

    }

    const deleteAchievement=async ()=>
    {
        if(window.confirm(texts.confirm))
        {
            const result=await apiDelete(`/kpe20/achievement/${UID}`)
            if(result.success)
            {
                resetModal()
            }
            else
            {
                window.alert(result.message)
            }
        }
    }
    const onChange=(ev,{value,name})=>
    {
        if(name==='date')
            setAData({date:value})
    }
    
    const uploadFile=async (e)=> {
        setLoading(true)
        // first delete all documents
        const current=await apiSelect('/kpe20/files/'+UID)
        await apiDelete(`/kpe20/files/${UID}`,{files:current.files.map(el=>el.name.replace(UID+'/',''))})

        const myFile = e.target.files[0]
        const result=await apiPutFile('/kpe20/files/imagePdf/'+UID,myFile,myFile.name,'documents') 
        setImageUrl(result.urls[1])
        setLoading(false)
    }

    
    const deleteFile=async (e) => {
        //  delete all documents
        const current=await apiSelect('/kpe20/files/'+UID)
        await apiDelete(`/kpe20/files/${UID}`,{files:current.files.map(el=>el.name.replace(UID+'/',''))})
        setImageUrl(null)
        setDownloadUrl(null)
    }
    

     return (<>
        <Modal.Header>{createNew ? texts.HeaderNew : texts.HeaderEdit}</Modal.Header>
        <Modal.Content>
                          
       

            <Form>
            {
                !createNew &&
                <>
                    {
                        
                        imageUrl  ?
                        <>
                        { downloadUrl && <a href={downloadUrl} target='_blank'><Icon name='download'/> </a>}
                            <Image 
                                src={imageUrl} 
                                size='large'
                                centered
                                style={{margin:0}}
                            />
                            </>
                        :
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <DocumentPlaceholder
                            width='200' 
                            height='300' 
                            text='pdf/png/jpg'
                            fontSize='12'
                        />
                        </div>

                    }
                    <Button.Group widths={2}>
                        <Button  htmlFor='file2' 
                                type='button' as='label' width={1} loading={loading}>
                            {loading===true ? '' : <>
                            <input type='file' id='file2'  accept='image/*,.pdf'  style={{display: 'none'}} onChange={uploadFile}/>
                                    <Icon name='upload' color='green'/> {texts.uploadDocument}</>
                            }
                        </Button>
                        <Button content={texts.deleteDocument} onClick={deleteFile} width={1} disabled={loading} negative/>
                    </Button.Group>
                </>
            }
   
            
            
            {
                createNew ?
                        <NewEdit 
                            member={member}
                            setMember={setMember}
                            template={template}
                            setTemplate={setTemplate}
                            achievements={achievements}
                            defaultDisplay={defaultDisplay}
                            errors={errors}
                        />
                : 
                    <>
                        <Header as='h3' content={templateDisplay}/>
                        <Header as='h3' content={memberDisplay}/> 
                    </>
            }
            <Form.Field
                label= {texts.date}
                control={DatePicker}
                jsTime
                value={aData.date}
                onChange={onChange}
                maxDate={new Date().setUTCHours(23,59,59,999).valueOf()}
                error={errors.date}
                name='date'
                locale={config.locale}
            />
            <Form.Button
                icon='trash'
                content={texts.delete}
                color='red'
                onClick={deleteAchievement}
            />
        
        </Form>
    </Modal.Content>
    {children}
    </>)
}

export default EditAchievement