import React,{ useState, useEffect, useRef}  from 'react';
import {Header,Form,Popup, Icon, List, Segment,Button,Label} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import EditSource from './ModalEditSource'
import {useParams} from "react-router-dom";

import useClipboard from '../../lib20/useClipboard';
import { useApi } from '@wollo-lib/kpe-context';
import ShowSource from '../ShowSource'

const TabSources=(props)=>
{

    const [actualSource,setActualSource] =useState('new')
    const [modalOpen, setModalOpen] = useState(false)

    const {functions, achievements, filterType, deleteFilter,sources, setModalParams, UID}=props 
    const texts=useTexts('/edit/dlist/'+filterType)
    


    const {apiInsert}=useApi()



    const addSource=()=>
    {
        setModalParams(
        {
            addLevel:{
                component: EditSource,
                createNew:true,
                functions: functions,
                achievements:achievements,
                filterType: filterType,
                UID:UID,
                size:'large'
            }
        })
    }
 
    const [clip,setClip, contentChanged]=useClipboard({
        alwaysUpdate:false,
        filter:(d)=>(d && d.type==='filteredSource'),
        copyAlert:texts.clipboardCopyAlert,
        browserSupport: texts.clipboardNotAvaillable
    })



    const pasteSource=()=>
    {
        contentChanged()
        if(clip && clip.type && clip.type==='filteredSource')
        {
            const data=clip.data
            apiInsert(`/kpe20/filter/${data.UIDBelongsTo}/${UID}/${filterType}`,{...data.Data,UID:undefined})
        }
        else
        {
            window.alert(texts.clipboardPasteError)
        }
    }

    const copyFilter=(ev,{index})=>
    {
        
        setClip({
            type: 'filteredSource',
            data: sources[index]
        })
    }


    const editFilter=(ev,{name})=>
    {
        setModalParams(
            {
                addLevel:{
                    component: EditSource,
                    filterUID:name,
                    functions: functions,
                    achievements:achievements,
                    filterType: filterType,
                    UID:UID,
                    size:'large'
                }
            })
    }

    return(
    <Form onMouseOver={()=> contentChanged()}>
        <Popup
            trigger={<Header as='h3' content={texts.TabHeaderSources}/>}
            content={<p>{texts.Explanation}</p>}
        />
        <List>
        {
            sources.map((source,index)=>

               <ShowSource 
                    sources={sources}
                    index={index}
                    key={source.UID}
                    texts={texts}
                    achievements={achievements}
                    functions={functions}
                    copyFilter={copyFilter}
                    editFilter={editFilter}
                    deleteFilter={deleteFilter}
                />)
 
        }
      
            
        </List>
        <Form.Group>
            {
                ( (clip && clip.type && clip.type==='filteredSource') || ('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)) &&
                    <Popup
                        trigger={
                        <Form.Button 
                            circular 
                            icon='paste' 
                            basic color='blue' 
                            onClick={pasteSource} 
                            size='mini'
                        />}
                        content={texts.clipboardPaste}
                    />
            }

            <Form.Button
                circular 
                icon='plus'
                basic color='green' 
                onClick={addSource} 
                size='mini'
            />
        </Form.Group>
    
    </Form>

    )
}
export default TabSources