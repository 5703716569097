import React, { useState, useEffect, useRef }  from 'react'
import {Container, Segment, Icon, Header, Grid, Menu, Image, Popup, GridColumn} from 'semantic-ui-react'


import {showDatum}      from '@wollo-lib/wollo-react'
import {ReactComponent as ThreemaLogo} from '../../../css/Threema.svg'
import useTexts         from '../../../lib20/useTexts'

import {telTypes,mailTypesDisplay as mailTypes, messengerIcons, messengerLinks, addressTypes, accountTypes} from './modals/types'
import addressFormatter from '@fragaria/address-formatter'
import {MalePlaceholder, FemalePlaceholder} from '../../../css/avatarPlaceholder'
import {baseUrl}        from '../../../config.jsx'

import AddressLink      from './PersonAddressOrRoute'


import EditPhone        from './modals/ModalPhone'
import EditMessenger    from './modals/ModalMessenger'
import EditAddress      from './modals/ModalAddress'
import EditMail         from './modals/ModalMail'
import EditAvatar       from '../modals/ModalAvatar'
import EditBankAccount  from './modals/ModalBankAccount'
import { useConfig }    from '../../../lib20/KPE20context'






const PersonMain=(props)=>
{
    const {UID,person,group,PopupHide,setModalParams}=props
    const texts=useTexts('/show/person')
    const config=useConfig()
    const dateFormat={year:"numeric",month:"2-digit", day:"2-digit",milliseconds:false,locale:config.locale,jsTime:false}
    
    return(
        <Grid stackable>
            <Grid.Row columns={2} >
                <Grid.Column width={10} key={12}>
                    <Header as ="h4">
                        <PopupHide 
                            content={texts.PhoneEdit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={()=> setModalParams({
                                        addLevel:
                                            {
                                                component: EditPhone,
                                                UID:UID,
                                                person: person,
                                                UIDparent: group.UID,
                                            }
                                        })} 
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.telephone}
                    </Header>

                    {   person.Data.phone===undefined ? '' :
                        person.Data.phone.map((tel,index)=>
                        (
                            <div key={index}>
                                 <Popup
                                    trigger={<Icon name={telTypes[tel.type]} color='blue'/>}
                                    content={texts.entryTypes[tel.type]}
                                />
                                <a href={`tel:${tel.number}`}>{tel.number}</a>
                            </div>
                        ))
                    }

                    <Header as ="h4">
                        <PopupHide 
                            content={texts.MailEdit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={()=> setModalParams({
                                        addLevel:
                                        {
                                            component: EditMail,
                                            UID:UID,
                                            person: person,
                                            UIDparent: group.UID,
                                        }
                                    })}  
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.email}
                    </Header>
                    {   person.Data.email===undefined ? '' :
                        person.Data.email.map((email,index) => (

                            <div key={index}>
                                <Popup
                                    trigger={<Icon 
                                            name={mailTypes[email.type]}
                                            style={{color:email.type==='secondary' ?  '#a0c0ff'  : '#2185d0'}}
                                        />
                                    }
                                    content={texts.entryTypes[email.type]}
                                />
                            <a href={`mailto:${email.email}`}><span style={email.unsubscribe ?{textDecoration:'line-through'}: {}}>{email.email}</span></a>
                            </div>
                        ))
                    }
                    
                    <Header as ="h4">
                        <PopupHide 
                            content={texts.MessengerEdit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={()=> setModalParams({
                                        addLevel: {
                                            component: EditMessenger,
                                            UID:UID,
                                            person: person,
                                            UIDparent: group.UID,
                                        }
                                    })}                                             
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.messenger}
                    </Header>
                    {   person.Data.messenger===undefined ? '' :
                        person.Data.messenger.map((messenger,index) => (

                            <div key={index}>
                                {messenger.type === 'Threema' ? <ThreemaLogo width={18} height={15} style={{fill:'#2185d0'}} /> :
                                    <Icon 
                                        name={messengerIcons[messenger.type]}
                                        color={messengerLinks[messenger.type]==='' ? 'black' : 'blue'}
                                    />
                                }
                                    {messengerLinks[messenger.type]==='' 
                                        ? messenger.account
                                        : <a href={messengerLinks[messenger.type]+messenger.account} target='_blank'>{messenger.account}</a>
                                    }
                                
                            </div>
                        ))
                    }

                    <Header as ="h4">
                        <PopupHide 
                            content={texts.AddressEdit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={()=> setModalParams({
                                        addLevel: {
                                            component: EditAddress,
                                            UID:UID,
                                            person: person,
                                            UIDparent: group.UID
                                        }
                                    })}                                              className='editIconColor'
                                />
                            }
                        />
                        {texts.addresses}
                    </Header>

                    {   person.Data.address===undefined ? '' :
                        person.Data.address.map((address,index)=>
                        {
                            return (<div key={index}>
                                
                                <Popup
                                    trigger={<Icon 
                                                name={addressTypes[address.type]}
                                                style={{color:address.type==='secondary' ?  '#a0c0ff'  : '#2185d0'}}
                                            />}
                                    content={texts.entryTypes[address.type]}
                                />
                                <AddressLink address={address}/>
                            </div>)
                        })
                    }
                    <Header as="h4">{texts.Birthday}</Header>
                    <p> {showDatum(person.Data.birthday,dateFormat)} </p>
                    {
                        (person.Type==='extern' && person.Data?.category?.includes('died')) &&
                        <>
                            <Header as="h4">{texts.Died}</Header>
                            <p>{showDatum(person.Data.died, dateFormat)}</p>
                        </>

                    }
                    {
                        person.Data.birthName &&
                        <>
                            <Header as="h4">{texts.BirthName}</Header>
                            {person.Data.birthName}
                        </>

                    }
                    

                
                </Grid.Column>
                <Grid.Column width={6} key={22}>
                    <Header as ="h4">
                        <PopupHide content={texts.ImageEdit} trigger={
                            <Icon 
                                name='edit' 
                                onClick={()=> setModalParams({
                                    addLevel:
                                    {
                                        component: EditAvatar,
                                        UID:UID,
                                        person: person,
                                        UIDparent: group.UID
                                    }
                                })}                                          
                                className='editIconColor'
                            />
                        }/>
                    </Header>

                    {
                        person.Data.avatar ? <Image src= {person.Data.avatar+`?t=`+Date.now()} size='medium' />
                        : 
                        person.Data.gender==='M' ? <MalePlaceholder width='300px'/> : <FemalePlaceholder width='300px'/>  
                    }
                            
                    
                </Grid.Column>
            </Grid.Row>
                {
                    person.Data && person.Data.accounts && <>
                    <Header as="h4">
                        <PopupHide 
                            content={texts.BankAccountEdit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={()=> setModalParams({
                                        addLevel: {
                                            component: EditBankAccount,
                                            UID:UID,
                                            person: person,
                                            UIDparent: group.UID
                                        }
                                    })}                                              
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.BankAccount}
                    </Header>

                    {
                        person.Data.accounts.map((account,Index)=>{

                            return <Grid.Row key={Index}>
                                <Grid.Column width={8}>
                                    <Popup
                                        trigger={ 

                                                    <Icon 
                                                        name={accountTypes[account.type]}
                                                        style={{color:['personalFees','familyFees'].includes(account.type) ?  '#2185d0' : '#a0c0ff' }}
                                                    />}
                                        content={texts.entryTypes[account.type]}
                                    />
                                    
                                    <span style={{fontWeight: 'bold'}}>{account.IBANshow}</span> {account.IBANowner}
                                </Grid.Column>
                                {
                                    ['personalFees','familyFees'].includes(account.type) &&
                                    <Grid.Column width={8}>
                                    {texts.Sepa}: {account.SEPA?.mandatNo.replaceAll('=','')} {showDatum(account.SEPA?.date)}
                                    </Grid.Column>
                                }
                                    

                            </Grid.Row>

                            
                        })
                    }
                    </>
                }

        </Grid>
    )
}

export default PersonMain