import React, {useState, useEffect} from 'react'
import { Form, Radio, Segment, Modal, Label} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect} from '@wollo-lib/wollo-react'
import useTexts from '../../../lib20/useTexts'





const SelectFamily =(props)=> {

    const {apiInsert,apiSelect}=useApi()
    const texts=useTexts('/show/person')
    const [family, selectFamily]=useState(null)
    const [suggestions,setSuggestions]=useState([])
    const {children, modalParams,resetModal, modalSubmit}=props
    const {person,setModalParams,UID}=modalParams[0]
    

  

   
    
    // Familien Vorschläge
    useEffect(()=>{
        const getSuggestions=async()=>
        {
            const result= await apiSelect(`/kpe20/family/suggest/${person.Data.lastName}`)
            if(result.success)
            {
                setSuggestions(result.result.
                    filter(s=>s.UID!==UID).
                    filter(s=>s.score>80)
                )
            }
        }
        if(UID && person)
            getSuggestions()
    },[UID,person])
 

    modalSubmit.current=async ()=> {
        const res=await apiInsert(`/kpe20/family/${family}/${UID}?rebate=true`,{empty:null})
        if(!res.success)
            window.alert('error update person: '+res.message)
        else
        {
            // modify the person in the other 3 levels
           const newPerson=({...person,Data:res.data})
           setModalParams({
                modify: {
                    person: newPerson
                }
           })
        }
        resetModal()
    }
    

    const handleChange= (ev, {name, value}) => {
        selectFamily(value)
    
    }

   




    return(<>

        <Modal.Header as='h3'>{texts.SelectFamily+person.Display}</Modal.Header>
        <Modal.Description>
            <Segment>
                <p>{texts.explainFamily}</p>
            </Segment>
        </Modal.Description>
        <Modal.Content>
            <Form>
                {suggestions.length>0 &&
                    <>
                      
                       
                
                    {
                        suggestions.map(s=>(
                            <>
                                    <Form.Field
                                    label={s.Display}
                                    control={Radio}
                                    value={s.UID}
                                    checked={family===s.UID}
                                    name='suggestion'
                                    onChange={handleChange}
                                />
                            </>
                        ))
                    }

                      
                    </>
                }
                <Form.Field
                        label={texts.ChangeFamily}
                        placeholder={texts.ChangeFamily}
                        control={SearchSelect}
                        api="kpe20"
                        SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['person'])}
                        Value2TitleUrl='/kpe20/Value2Title'
                        value={family}
                        autoSelect
                        onChange={handleChange}
                        name="input"    
                        SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                    />

            </Form>

        </Modal.Content>
        {children}
    </>)
}
export default SelectFamily