import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Loader,Form,Modal,Segment} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useApi,usePageSelect,useWindowDimensions} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {bannerFormatter} from '../../lib/formatters'
import MyTabulator,{autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import FilterElement from '../../lib20/FilterSources/FilterElement'



const EditSource=(props)=>
{


    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'}
    ])

    const {width,height}=useWindowDimensions()
    const texts=useTexts('/edit/mail/filter')

    const [sourceType, setSourceType]=useState('group')

    const {children, modalSubmit, resetModal, modalParams}=props
    const {UID,functions,achievements, setRecipients}=modalParams[0]
    const [filterState,setFilterState]=useState({
                                                    filterSource:null,
                                                    filterAlgo:{},
                                                    types: {person:true, guest: true, extern : false, job:false}
                                                 })
    //const [filterSource,setFilterSource]=useState()
    const [members,setMembers]=useState([])
    //const [filterAlgo,setFilterAlgo]=useState({})
    const [timeStamp,setTimeStamp]=useState(Date.now())
    const {apiSelect, apiInsert, apiDelete}=useApi()
    const [isLoading,setIsLoading]=useState(false)


  
    //const [ajaxParams,setAjaxParams]=useState({})

    const algo={}
        Object.keys(filterState.types).forEach(type=>{
            if(filterState.types[type])
                algo[type]=filterState.filterAlgo

    })

    
    const ajaxParams={
        Timestamp:timeStamp,
        type:sourceType==='group' ? JSON.stringify(Object.keys(filterState.types).filter(el=>(filterState.types[el]))) : ['entry'],
        grouped:0,
        Data:jsonFields,
        dataFilter: JSON.stringify(algo)
    }

    const {onScroll,loading,totalCount}=usePageSelect({
            select:filterState.filterSource ? `/kpe20/persons/${filterState.filterSource}` : null,
            urlPara:ajaxParams, 
            setData:setMembers,
            pageSize:50, 
            clientFilter:true
        })
  
    modalSubmit.current=async ()=>
    {
        setIsLoading(true)
        const result=await apiInsert(`/kpe20/email/filter/${filterState.filterSource}/${UID}`,algo)
        if(!result.success)
        {   
            window.alert(result.message)
        }
        else {

            const result=await apiSelect('/kpe20/email/persons/'+UID)
            if(result.success && result.result.length>0) {
                setRecipients(result.result)
            }
        }
        setIsLoading(false)
        resetModal()
    }

    
    const changeType=(ev,{name})=>
    {
        setFilterState(old=>({...old, types:{...old.types,[name]:!old.types[name]}}))
    }

    const handleSourceChange=(ev,{value,result})=>
    {   
        if(sourceType!==result.type)
        {
            if(result.type==='group')
                setFilterState(old=>({...old, types: {person:true, guest: true, extern : false, job:false}}))
            else
                setFilterState(old=>({...old, types: {person:true, guest: false, extern : false, job:false}}))
        }
        setSourceType(result.type)
        setFilterState(old=>({...old,filterSource:value})) 
        //setFilterSource(value)
    }

    const changeAlgo=(ev,{value})=>
    {
        setFilterState(old=>({...old,filterAlgo:value}))
    }


    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, hozAlign:"right", frozen:true, field:'UID'}, //incrementing row number
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner'},
        { title:  texts.MemberType, field: 'Title', width: 150,  headerFilter:"input"},        
        { title:  texts.FirstName, field: 'firstName', width: 120,  headerFilter:"input"},
        { title:  texts.LastName, field: 'lastName', width: 120,  headerFilter:"input"},
        { title:  texts.pGroup, field: 'pGroup',   headerFilter:"input"},
        { title:  texts.Age, field: 'dindex',  width:90, headerFilter:"input"}
    ]

    return(
        <>
            <Modal.Header as='h3' content= {texts.Header }/>
            <Modal.Content>

                <Form>
                    { sourceType==='group' &&
                        <Form.Group>
                            <Form.Checkbox label= {texts.personSource} name='person' checked={filterState.types.person} onClick={changeType} toggle/>
                            <Form.Checkbox label= {texts.guestSource} name='guest' checked={filterState.types.guest}  onClick={changeType} toggle/>
                            <Form.Checkbox label= {texts.externSource} name='extern' checked={filterState.types.extern} onClick={changeType}  toggle/>
                            <Form.Checkbox label= {texts.jobSource} name='job' checked={filterState.types.job} onClick={changeType}  toggle/>
                        </Form.Group>
                    }
                    <Form.Field 
                        control={SearchSelect}
                        label={texts.EditSourceLable}
                        placeholder={texts.EditSourcepPlaceholder}
                        value={filterState.filterSource}
                        onChange={handleSourceChange}
                        api = "kpe20"
                        SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['group','list','dlist'])}
                        Value2TitleUrl={'/kpe20/Value2Title?types='+JSON.stringify(['group','list','dlist'])}
                        autoSelect

                    />
                    
                        
                    <Form.Field
                        control={FilterElement}
                        label='Filter'
                        onChange={changeAlgo}
                        type={filterState.types.job ?'job':'person'}
                        value={filterState.filterAlgo}
                        functions={functions}
                        achievements={achievements}
                        name='filterAlgo'
                    />
                    
                </Form>
            </Modal.Content>
           <div>
                    <Loader disabled={!isLoading} />
              
                    {children}
            </div>
            <Modal.Content style={{paddingTop:'14px'}} >
                <Loader disabled={!isLoading} inline/> <b>{totalCount} {texts.totalCount}</b>
                <MyTabulator
                    index='UID'
                    columns={listColumns}
                    headerFilterPlaceholder={texts.headerFilterPlaceholder}
                    data={members}
                    height={(height-300)+'px'}                    
                    compact="very"
                    striped
                    layout="fitColumns"     
                    onScroll={onScroll}
                    replaceMode={true}
 
                />
            </Modal.Content>

        </>
    )
}
export default EditSource