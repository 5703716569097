import React, { useState, useEffect }  from 'react'
import {Image,Container, Menu, Header, Segment, Grid, Form, Modal, Button, Icon, Popup, Dropdown} from 'semantic-ui-react'
import {useApi, useWindowDimensions} from '@wollo-lib/kpe-context'
import {useWs, useConfig} from '../../lib20/KPE20context'
import EditGroup from './modals/ModalEditGroup.jsx'
import MemberList from './tabs/TabMember'
import GroupList from './tabs/TabGroup'
import JobList from './tabs/TabJob'
import FamilyList from './tabs/TabFamily' 
import ExternList from './tabs/TabExtern'
import { showDatum,DatePicker} from  '@wollo-lib/wollo-react'
import RailContainer from '../../lib20/Rails/RailContainer'
import {baseUrl}        from '../../config.jsx'
import useObjectActions from '../../Hooks/useObjectActions'

import useTexts from '../../lib20/useTexts'
import { Switch, Route, useHistory, useParams } from 'react-router-dom'
import HistoryButton from '../../edit/list/ModalHistory'

import MenuEdit from './MenuEdit'
import OutputMenu from '../../output/OutputMenu'

const Group=(props)=>
{
    const {apiSelect}=useApi()
    const texts=useTexts('/show/group')
    const [group,setGroup]=useState({Title:'',Display:'',UID:null})         // data for the Group where we have to display members
    const [admin,setAdmin]= useState(false)
    const {UID, type}= useParams()
    const [historyTime, setHistoryTime]= useState(null)
    const config=useConfig()
    const {width}=useWindowDimensions()

    const {modalParams, setModalParams, setModalDefaults, setExtraMenu, functions,achievements,actions,tabRef, setRailPath, setContainerWidth}=props
    
    //const address = group.Data.address

    const history= useHistory()
    const {objectActions,actionsMessageHandler}=useObjectActions(UID)


    function messageHandler(value) // websocket message handler
    {
        // it is not delivering back full data set, so merge new data into existing
        if(value.group)
        {
            setGroup(old=>(
                {
                ...old,
                ...value.group,
                   
                }))
        }
        else
        {
            actionsMessageHandler(value)
        }
    }

    const {timestamp}=useWs( {UIDmonitor:!historyTime?UID:null,UIDchanged:UID,cb:messageHandler,updateTimeLimit: 30000})

    // get some data for the selected group
    useEffect(()=>{
        const getGroupData=async ()=>
        {
            const myGroup= await apiSelect(`/kpe20/group/${UID}/?Timestamp=${timestamp}&parent=true&sibling=true`)
            if(myGroup.success) {
                setGroup(myGroup.result)
                setModalDefaults({
                    UIDdefault:UID,
                    defaultType:'group'
                })
           }
           setRailPath(UID)
           if(!type)
           {
                if(config.groupsDefaultTab && config.groupsDefaultTab[myGroup.result.hierarchie])
                    history.push(`/show/group/${config.groupsDefaultTab[myGroup.result.hierarchie]}/${UID}`)   
                else 
                    history.push(`/show/group/members/${UID}`)
           }
           
        }
        
        const getGroupAdmin=async()=>
        {
            const result= await apiSelect(`/kpe20/group/admin/${UID}`)
            if(result.success) {
                setAdmin(result.result)
            }
        }



        getGroupData()

        getGroupAdmin()
        // delete defaults, when view is closed
        return ()=>{setModalDefaults({})}
    },[UID,historyTime,timestamp])

    useEffect(()=>{
        setContainerWidth(1200)
    },[width])


    useEffect(()=>{
        {
            setExtraMenu({
                action: 'replace',
                position: 1,
                menus: ['members','jobs','extern','groups'].includes(type) ?
                [
                    {admin:false,menu:MenuEdit,props:{group,UID,achievements,functions,actions,objectActions, key:'GroupEdit'}},
                     {admin:false,menu:OutputMenu,props:{tabRef,filename:`${group.Title} ${group.Display}`, key:'GroupOutput',types:['person','guest','job']}}
                ]:
                [
                    {admin:false,menu:MenuEdit,props:{group,UID,achievements,functions,actions,objectActions, key:'GroupEdit'}}
                ]
            })
        }
        return ()=>setExtraMenu({action:'replace',menus:[]})
    },[group,actions,achievements,functions,objectActions,UID,type])


    
    const handleItemClick = (e, { name }) =>
    {

            history.push(`/show/group/${name}/${UID}`)
    }


    const MenuItems=[
        <Menu.Item
            content={texts.TabMembers}
            name='members'
            active={type === 'members'}
            onClick={handleItemClick}
        />,
        <Menu.Item
            content={texts.TabFamily}
            name='family'
            active={type === 'family'}
            onClick={handleItemClick}
        />,
        <Menu.Item
            content={texts.TabGroups}
            name='groups'
            active={type === 'groups'}
            onClick={handleItemClick}
        />,
        <Menu.Item
            content={texts.TabJobs}
            name='jobs'
            active={type === 'jobs'}
            onClick={handleItemClick}

        />,
        <Menu.Item
            content={texts.TabExtern}
            name='extern'
            active={type === 'extern'}
            onClick={handleItemClick}
        />

    ]


    //const timeFormat={year:"numeric",month:"2-digit", day:"2-digit",hour:"2-digit",minute:"2-digit",second:"2-digit"}
    const timeFormat={year:"numeric",month:"2-digit", day:"2-digit"}
   
    return(

        <>
            <Segment>
                <Grid>
                    
                        <Grid.Column key={1} width={11}>
                            <Header as ="h1">
                                { !admin    ?'':
                                    <Popup 
                                        content={texts.edit} 
                                        trigger={<Icon
                                            name='edit'
                                            onClick={ev=>{
                                                setModalParams( {
                                                    addLevel: {
                                                        component: EditGroup,
                                                        UID:UID,
                                                    }
                                                })}}
                                            className='editIconColor'/>
                                        }
                                    />
                                }
                                {group.Title} {group.Display}
                            </Header>
                            {
                                !group.parent &&  !group.parentS ? '' :
                                <Header as='h3'style={{ marginBottom:'2px'}}>
                                    {texts.belongsTo+' '}
                                    {
                                        group?.parent && 
                                            <a key={'parent'} style={{cursor:'pointer'}} onClick={()=>history.push('/show/group/'+group.parent.UID)}>{group.parent.Title} {group.parent.Display}</a>

                                    }                                   
                                    {
                                        (group?.parent  && group?.parentS) && ', '
                                    }                                    
                                    {
                                        group?.parentS &&
                                            <a key={'parentS'} style={{cursor:'pointer'}} onClick={()=>history.push('/show/group/'+group.parentS.UID)}>{group.parentS.Title} {group.parentS.Display}</a>
                                    }
                                </Header>
                            }
                            {
                                group.gender==='C' || group.gender ==='B' || !config 
                                || !config.HierarchieStageGenderGroups || !config.HierarchieStageGenderGroups[group.hierarchie] || 
                                !config.HierarchieStageGenderGroups[group.hierarchie][group.stage]['C'] ? '' :
                                <Header as='h3' style={{marginTop:'2px'}}>
                                    {texts.sibling+' '}
                                   
                                    {
                                        group?.sibling &&    
                                            <a key={'sibling'} style={{cursor:'pointer'}} onClick={()=>history.push('/show/group/'+group.sibling.UID)}>{group.sibling.Title} {group.sibling.Display}</a>
                                   }                                   
                                
                                </Header>
                            }
                        </Grid.Column> 
                        <Grid.Column key={2} width={2} >
                        {
                            ( group && group.Data && group.Data.avatar)   &&
                        
                            <Image 
                                src={`https://kpe20.${baseUrl}/kpe20/files/${group.Data.avatar}`} 
                                size='tiny'
                                centered
                                style={{margin:0}}/>
                        }
                        </Grid.Column>
                        <Grid.Column key={3} width={3}>
                            <HistoryButton
                                setModalParams={setModalParams}
                                historyTime={historyTime}
                                setHistoryTime={setHistoryTime}
                            />
                        </Grid.Column>
                    
    
                </Grid>
            </Segment>
        
            { width >600?

                <Menu tabular attached='top' fluid>
                    {MenuItems}
                </Menu>
                :
                <Dropdown item icon='bars' simple>
                    <Dropdown.Menu>
                        {MenuItems}
                    </Dropdown.Menu>
                </Dropdown>
            }  
           
            <Segment attached='bottom'>
                <Switch>
                    <Route exact path={'/show/group/members/:UID'}>
                        <MemberList 
                            UID={UID} 
                            timestamp={historyTime?  historyTime :null}
                            modalParams={modalParams}
                            setModalParams={setModalParams}
                            functions={functions}
                            achievements={achievements}
                            tabRef={tabRef}
                            setExtraMenu={setExtraMenu}
                            group={group}
                            wsTime={timestamp}
                        />
                    </Route>
                    <Route exact path={'/show/group/groups/:UID'}>
                        <GroupList  
                            UID={UID}
                            timestamp={historyTime?  historyTime :null}
                            modalParams={modalParams}
                            setModalParams={setModalParams}
                            functions={functions}
                            achievements={achievements}
                            tabRef={tabRef}
                            setExtraMenu={setExtraMenu}
                            group={group}
                            wsTime={timestamp}
                        />
                    </Route>
                    <Route exact path={'/show/group/jobs/:UID'}>
                        <JobList  
                            UID={UID} 
                            timestamp={historyTime?  historyTime :null}
                            modalParams={modalParams}
                            setModalParams={setModalParams}
                            functions={functions}
                            achievements={achievements}
                            tabRef={tabRef}
                            setExtraMenu={setExtraMenu}
                            group={group}
                            wsTime={timestamp}
                        />
                    </Route>
                    <Route exact path={'/show/group/extern/:UID'}>
                        <ExternList 
                             UID={UID}
                             timestamp={historyTime?  historyTime :0}
                             tabRef={tabRef}
                             setExtraMenu={setExtraMenu}
                             group={group}
                             functions={functions}
                             achievements={achievements}
                             setModalParams={setModalParams}
                             wsTime={timestamp}
                        />
                    </Route>
                    <Route exact path={'/show/group/family/:UID'}>
                        <FamilyList 
                             UID={UID}
                             timestamp={historyTime?  historyTime :0}
                             tabRef={tabRef}
                             setExtraMenu={setExtraMenu}
                             group={group}
                             functions={functions}
                             achievements={achievements}
                             setModalParams={setModalParams}
                             wsTime={timestamp}
                        />
                    </Route>
                </Switch>
            </Segment>
        </>

    )
}

export default Group
