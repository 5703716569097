

import React,{useRef, useEffect}                                    from    'react'       
import { Menu}                                  from    'semantic-ui-react'
import reactToString                            from    "../../../lib20/reactToString"
import EditPerson                               from    '../../person/modals/ModalEditPerson' 
import ChangeGroup                              from    '../../person/modals/ModalChangeGroup'
import ModalTimestamp                           from    '../../person/modals/ModalTimestamp'
import { useApi }                               from    '@wollo-lib/kpe-context'
import useTexts                                 from    '../../../lib20/useTexts'
import ModalEditFamily                          from    '../../person/modals/ModalEditFamily'
import ModalFeeAddress, { FeeAddressFamily }                          from '../../person/tabs/modals/ModalFeeAddress'
import {ModalFamily}                            from    '../../person/tabs/PersonFamily'
import { useConfig }                            from    '../../../lib20/KPE20context'
import Addon                                    from    '../../../lib/Addon'

const useContextMenu=(props)=>
{
    const texts=useTexts(['/show/person','/edit/person','/show/person/jobs','/show/person/family'])
    const refTexts=useRef(texts)
    const {setModalParams,setMyTime}=props
    const {apiSelect, apiDelete}=useApi()
    const config = useConfig()
    const addons = config.addons?.filter(a => a.listContext?.includes('group'))
 


    const getData=async (data)=>
    {
        const [result, resultg]=await
        Promise.all([
            data.UID ? apiSelect(`/kpe20/person/${data.UID}`) : {success:false, result: null},
            data.UID ? apiSelect(`/kpe20/group/${data.UIDgroup}`) : {success:false, result: null}

        ])
        const res={success: result.success && resultg.success }
        res.group=resultg.result
        res.person=result.result
        return res
    }

    useEffect(()=>{
        refTexts.current=texts
    },[texts])


    const rowContextMenu=(e,row)=>
    {
        
        const data=row.getData()
        const menu= [

        
          
        
            {
                label: reactToString(
                <Menu.Item
                    icon='edit'
                    content='Bearbeiten'
                />),
                
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: EditPerson,
                                UID: data.UID,
                                Type: data.Type,
                                myGroup: group,
                                myPerson: person

                            }
                        })
                    }
                },
                types:['extern','person'],
                changeable:true

            },
            {
                separator:true,
                types:['person','extern'],
                changeable:true
            },

            {
                label: reactToString(
                <Menu.Item
                    icon='exchange'
                    content={refTexts.current.changeGroup}
                />),
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component : ChangeGroup,
                                text:  refTexts.current.changeGroup,
                                textDate: refTexts.current.changeDate,
                                UID:data.UID,
                                group: group,
                                setGroup:()=>{},
                                groupQuery: '&gender='+JSON.stringify(['B',person.gender]),
                                type:'person',
                            }
                        })
                    }
                },
                types:['person'],
                changeable:true
            },
                

                 // exit orga
          
              
            {
                label: reactToString(
                <Menu.Item
                    icon='sign-out'
                    content={refTexts.current.exitOrga}
                />),
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component : ModalTimestamp,
                                text:  texts.confirmExit,
                                textDate: refTexts.current.exitDate,
                                UID:data.UID,
                                group: group,
                                type:'extern',
                                category: person.Data.category ? 
                                            person.Data.category.includes('former') ? person.Data.category : [...person.Data.category,'former'] :
                                            ['former']
                            }
                        })
                    }
                },
                types:['person'],
                changeable: true
            },
        
            {
                label: reactToString(
                <Menu.Item
                    icon='sign-in'
                    content={refTexts.current.changeGroupEnter}
                />),
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component : ChangeGroup,
                                text:  refTexts.current.changeGroupEnter,
                                textDate: refTexts.current.enterDate,
                                UID:data.UID,
                                group: group,
                                type:'person',
                                setGroup:()=>{},
                                groupQuery: '&gender='+JSON.stringify(['B',person.gender]),
                            }
                        })
                    }
                },
                types:['extern'],
                changeable:true

            },
            {
                label: reactToString(
                <Menu.Item
                    icon='exchange'
                    content={refTexts.current.changeGroup}
                />),
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component : ChangeGroup,
                                text:  refTexts.current.changeGroupExtern,
                                textDate: refTexts.current.changeDate,
                                UID:data.UID,
                                group: group,
                                setGroup:()=>{},
                                groupQuery: '&gender='+JSON.stringify(['B',person.gender]),
                                type:'extern',
                            }
                        })
                    }
                },
                types: ['extern'],
                changeable:true
            },
           
         
            {
                // died
              
                label: reactToString(
                <Menu.Item
                    icon='dot circle outline'
                    content={refTexts.current.died}
                />),
                action: async (e,column)=>
                { 
                    const {success,person,group}=await getData(data)
                    if(success)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component : ModalTimestamp,
                                text:  refTexts.current.confirmDied,
                                textDate: refTexts.current.diedDate,
                                UID:data.UID,
                                group: group,
                                type:'extern',
                                category:  person.Data.category ? 
                                person.Data.category.includes('died') ? person.Data.category : [...person.Data.category,'died'] :
                                    ['died']
                            }
                        })
                    }
                },
                types:['person','extern'],
                changeable:true
            },
            {
                separator:true,
                types:['person','extern'],
                changeable:true

            }, 
            {
                label: reactToString(
                <Menu.Item
                    icon='users'
                    content={refTexts.current.EditFamily}
                />),
                
                action: async (e,column)=>
                { 
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: ModalEditFamily,
                                UID: data.UID,
                                exitHook: ()=>{
                                    if(setMyTime)
                                        setMyTime(Date.now())
                                }
                            }
                        })
                    }
                },
                types:['person'],
                changeable:true
            },
            {
                label: reactToString(
                <Menu.Item
                    icon='mail'
                    content={refTexts.current.FeeAddress}
                />),
                
                action: async (e,column)=>
                { 
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: FeeAddressFamily,
                                UIDperson: data.UID,
                                exitHook: ()=>{
                                    if(setMyTime)
                                        setMyTime(Date.now())
                                }
                            }
                        })
                    }
                },
                types:['person','extern'],
                changeable:true
            },

             
            {
                label: reactToString(
                <Menu.Item
                    icon='eye'
                    content={refTexts.current.family}
                />),
                
                action: async (e,column)=>
                { 
                    const {success,person}=await getData(data)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: ModalFamily,
                                UID: data.UID,
                                person: person,
                                closeIcon: true
                            
                            }
                        })
                    }
                },
                types:['person'],
                changeable:false
            },
            {
                label: reactToString(
                <Menu.Item
                    icon='delete'
                    content={refTexts.current.deleteGuest}
                />),
                action: async (e,column)=>
                { 
                    if(window.confirm(refTexts.current.confirmDeleteGuest))
                    {
                        const result=await apiDelete('/kpe20/guest/'+data.UID)
                    }
                },
                types:['guest'],
                changeable:true

            },
            {
                label: reactToString(
                <Menu.Item
                    icon='delete'
                    content={refTexts.current.deleteJob}
                />),
                action: async (e,column)=>
                { 
                    if(window.confirm(refTexts.current.confirmDeleteJob))
                    {
                        const result=await apiDelete('/kpe20/job/'+data.UID)
                    }
                },
                types:['job'],
                changeable:true
            },

                
           
            {
                label: reactToString(
                <Menu.Item
                    icon='delete'
                    content={refTexts.current.deleteExtern}
                />),
                action: async (e,column)=>
                { 
                    if(window.confirm(refTexts.current.confirmDeleteExtern))
                    {
                        const result=await apiDelete('/kpe20/extern/'+data.UID)
                    }
                },
                types:['extern'],
                changeable:true
            },
            {
                separator:true,
                types:['person','extern','guest'],
                changeable:true

            }, 
            ...addons.map(addon=>(
            {
                label:reactToString(
                    <Menu.Item
                        icon={addon.icon}
                        content={addon.name}
                    />),
                action: async (e,column)=>
                { 
                    const {success,person}=await getData(data)
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: Addon,
                                UID: data.UIDBelongsTo,
                                addon:addon,
                                closeIcon: true,
                                size: 'large'
                            
                            }
                        })
                    }
                },
                types: addon.actionTypes ?? ['person','guest','extern','job'],
                channgeable: true
               
            }))
        ]
        return menu.filter(m=>(m.types.includes(data.Type) && (!m.changeable || data.visibility!=='visible' )))
        
    }
    return rowContextMenu
}

export default useContextMenu