import React, {useState, useEffect} from 'react'
import {Form, Button, Dropdown, Modal, Icon} from 'semantic-ui-react'
import {ReactComponent as ThreemaLogo} from '../../../../css/Threema.svg'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../../lib20/useTexts'
import {messengerIcons} from './types'


const EditMessenger=(props) => {

    const {apiUpdate}=useApi()
    const texts=useTexts('/show/person/messenger')
    const [messenger, setMessenger]=useState([])

    const {children, resetModal,modalParams, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]


    const messengerOptions=Object.keys(messengerIcons).
    map(option=>({
        key:option,
        icon:option==='Threema'? <ThreemaLogo width={17} height={15} style={{fill:'#2f2f2f'}} /> : messengerIcons[option],
        content:texts[option],
        value:option
    }))
    
 

    modalSubmit.current=()=> {
        const result=apiUpdate('/kpe20/person/'+UID,{messenger:messenger})
         resetModal()
    }
    

    const handleChange= (ev, {name, value, index}) => {
        setMessenger((p) => (p.map((val, i) => (i===index ?  {...val,[name]:value}: val))))
        // sort()
    }

    const add=() => {
        setMessenger((p) => ([...p, {type:'', account:''}]))
    }

    const sort=() => {
        var data2Sort = messenger
        var sorted = [...data2Sort].sort((a,b) => {return a.type < b.type})
        setMessenger(sorted)
        // setMessenger((m) => {
        //     const data2Sort = [...m]
        //     data2Sort.sort((a,b) => a.type - b.type)
        //     return data2Sort
        // })
    }

    const remove=(e, {index:removeIndex}) => {
        setMessenger(messenger.filter((messenger, index) => index !== removeIndex))
    }


    useEffect(() => {
        person.Data.messenger===undefined
            ? add()
            : 
                setMessenger(person.Data.messenger)
                // sort()
    }, [person])



    return(<>

        <Modal.Header as='h3'>{texts.EditMessenger+person.Display}</Modal.Header>
        <Modal.Content>
            <Form>
                {messenger.map((messenger, index) => (
                    <Form.Input
                        name='account'
                        index={index}
                        label={texts.messenger}
                        placeholder={texts.messenger}
                        value={messenger.account}
                        onChange={handleChange}
                    >
                        <Dropdown 
                            as={Button}
                            value={messenger.type}
                            icon={messenger.type === 'Threema' 
                                    ? <ThreemaLogo width={18} height={15} style={{fill:'#858585'}} />   //active '#5c5c5c'
                                    : messengerIcons[messenger.type]
                                } 
                            options={messengerOptions} 
                            onChange={handleChange}
                            name='type'
                            index={index}
                            scrolling
                            compact 
                            basic
                        />
                      
                        <input/>
                        <Button icon='trash' name={messenger} index={index} onClick={remove} negative/>
                    </Form.Input>

                ))}
                <Form.Button icon='add' content={texts.add} onClick={add} positive />
                
            </Form>

        </Modal.Content>
        {children}
    </>)
}
export default EditMessenger