import React  from 'react'
import { Menu, Item} from 'semantic-ui-react'
import {SearchSelect} from '@wollo-lib/wollo-react'
//import {SearchSelect} from '../lib/SearchSelect'
import useTexts from '../lib20/useTexts'
import {useHistory, useLocation} from 'react-router-dom'
import { useConfig } from '../lib20/KPE20context'


const SearchItem=(props) => {

    const {searchWidth, setUIDMember, setUIDGroup, setUIDList}=props
    const config=useConfig()
    const texts=useTexts('Menu')
    const history=useHistory()
    let params = new URLSearchParams(document.location.search);
    const search=params.get('search')
    const renderSearch=({title,value}) => (
        // search in menu is delivering white text and errounous html rendering, if we do not provide this render method
        <Item  key={value} style={{color:'black'}} ><div dangerouslySetInnerHTML={{__html: `${title}`}}/></Item>
    )
    const handleChange=(e,{value,result}) => {

        if(result.type==='group') {
           
            history.push(`/show/group/${value}`)
        }
        else if(['person','extern'].includes(result.type)) {

            history.push(`/show/person/main/${value}`)
        }
        else if(['job','guest'].includes(result.type)) {

            history.push(`/show/job_guest/${value}`)
        }
        else if(result.type==='list')
        {
            history.push(`/edit/list/${value}`)
        }
        else if(result.type==='dlist')
        {
            history.push(`/edit/dlist/sources/${value}`)
        }
        else if(result.type==='email')
        {
            history.push(`/edit/email/${value}`)
        }
    }
    
    return (
        <Menu.Item key={99} position='right' >
        {
            texts.SearchMember &&
            <SearchSelect
                style={{width:searchWidth}}
                fluid
                resultRenderer={renderSearch}
                name='search' 
                onChange={handleChange} 
                placeholder={texts['search placeholder']}
                category
                SuggestUrl={{
                    [texts.SearchMember]: '/kpe20/SearchData/member',
                    [texts.SearchGroup]: '/kpe20/SearchData/group',
                    [texts.SearchExtern]: '/kpe20/SearchData/extern',
                    [texts.SearchList]: '/kpe20/SearchData/list',
                    [texts.SearchDList]: '/kpe20/SearchData/dlist',
                    [texts.SearchEmail]: '/kpe20/SearchData/email',
                }}
               
                api='kpe20'
                autoClear
                autoSelect
                Value2TitleUrl='/'
                suppliedSearch={search}
            />
        }
        </Menu.Item>


    )
}

export default SearchItem
