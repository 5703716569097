import countries from '../lib20/countries.json'
import { useApi, useUser } from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { useConfig } from '../lib20/KPE20context'
import useTexts from '../lib20/useTexts'
import addressFormatter from '@fragaria/address-formatter'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


const useOutputPDF=(props)=>
{

    const {tabRef,types,list,filename,setLoading} = props
    const config=useConfig()
    const [lang,countryOrga]=config.locale.split('-')
    const {apiUpdate}=useApi()
    const texts = useTexts(['OutputMenu','/show/person'])

    
    
    const getCountryFromCode=(code)=>
    {
        if(code)
        {
            const country=countries.find(c=>c.alpha2===code)
            return country ? country[lang] : null
        }
        else
            return null
    }

    const formatAddress=(a)=>
    {
        if(a)
        {
            const paddress={
                "houseNumber":a.houseNumber,
                "road": a.road,
                "neighbourhood": a.road2,
                "city": a.city,
                "postcode": a.postcode,
                "countryCode": a.countryCode,
                
            }
            if(a.countryCode !==lang)
                paddress.country=getCountryFromCode(a.countryCode)
            return addressFormatter.format(
                paddress,
                {
                    output: 'array'
                }).join(', ')
        }
        else
            return []

    }

    const join=(aText,jString)=>
    {
        return aText.reduce((res,current)=>(current && current!=='' ? res==='' ? current : res+jString+current : res),'')
    }
   

    const outputPdf=async()=>
    {
        setLoading(true)
        const UIDs=tabRef.current.getData().map(o=>o.UID)
        const fonts=await import('./myFonts')
        const doc=new jsPDF({
            orientation:'l',
            format:'a4',
            unit: 'mm',
            putOnlyUsedFonts:true
        })
        jsPDF.API.events.push(["addFonts", 
             fonts.callAddFont]);
        let data=[]

        if(list)
        {
            const result=await apiUpdate('/kpe20/persons?Data=all&ExtraData=1&type=["entry"]',UIDs)
            console.log(result)
            if(result.success)
            {
                data=result.result.map(m=>{
                    const result= m
                    if(list.extraParameter)
                    {
                        list.extraParameter.forEach(e=>
                        {
                            const extraPara=e.name.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')
                            const ExtraData=m.ExtraData ?? {}
                            return result[e.name]=ExtraData[extraPara]?.toString()
                        })
                    }
                    return result
                })
            }
        }
        else
        {
            const result=await apiUpdate(`/kpe20/persons?Data=all&type=${JSON.stringify(types)}`,UIDs)
            if(result.success)
            {
                data=result.result
            }
        }
        const dataCols= data.map(m=>
        {
            const result=[
                m.Title,
                join([m.Data.greeting,m.Data.title, m.Data.firstName, m.Data.lastName],' '),
                formatAddress(m.Data.address[0]),
                m.Data.phone[0]?.number,
                m.Data.email[0]?.email,
                m.pGroup
            ]
            if(list && list.extraParameter)
                list.extraParameter.forEach(p=>result.push(m[p.name]))
            return result

        })
        const listHeader=list?.extraParameter?.map(e=>e.name) ?? []
        autoTable(doc,{
            head: [[
                texts['Title']+'!',
                texts['Name'],
                texts['address'],
                texts['telephone'],
                texts['email'],
                texts['Group'],
                ...listHeader
            ]],
            body: dataCols,
            willDrawCell: (HookData) => {
                console.log(HookData)
                HookData.doc.setFont('fontSans')
                HookData.doc.setFontSize(10)
            }
        })
        setLoading(false)
        window.open(doc.output('bloburl'), '_blank');
        //doc.save(filename)

    }
    return outputPdf
}

export default useOutputPDF