import React, { useState, useEffect, useRef , Suspense}  from 'react'
import {Container, Segment, Icon, Header, Button, Loader, Label, Dropdown, Menu, Popup} from 'semantic-ui-react'

import {useApi,useWindowDimensions} from '@wollo-lib/kpe-context'   //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useWs,useConfig} from '../../lib20/KPE20context'

import useTexts         from '../../lib20/useTexts'
import {Switch, Route, useHistory, useParams} from 'react-router-dom'

import PersonMain       from '../person/tabs/PersonMain'

import MenuDev          from './MenuDev'
import RailContainer    from '../../lib20/Rails/RailContainer'





function PersonJob(props) {


    const {apiSelect,apiDelete}=useApi()
    const texts=useTexts(['/show/person','/show/person/jobs'])
    const {UID}=useParams()
    const history=useHistory()
    const {width}=useWindowDimensions()

    const config=useConfig()

    const {setModalParams, modalParams, setModalDefaults,modalDefaults, setExtraMenu, functions,achievements,setRailPath, setContainerWidth}=props

    const [person, setPerson]=useState({Data:{email:[], phone:[], messenger:[], address:[]}, parent:{}, parentS:[]})
    const [group,setGroup]=useState()
    const [type,setType]=useState()
    const [admin,setAdmin]=useState(false)



    function messageHandler(value) // websocket message handler
    {
        // it is not delivering back full data set, so merge new data into existing

        setPerson(old=>(
            {
              ...old,
               ...value.person,
               parent:value.parent ? value.parent : old.parent
            }))
        if(value.person.type)
            setType(value.person.type)
        
    }

    const {timestamp}=useWs({UIDmonitor:person.UIDBelongsTo,UIDchanged:UID,cb:messageHandler, updateTimeLimit: 5000})
 

    useEffect(()=>{
        setExtraMenu({
            action:'replace',
            menus:
            [
                {admin:true,menu:MenuDev,props:{person,UID,type,group,timestamp,functions,admin}}
            ]
        })
        return ()=>setExtraMenu({action:'replace',menus:[]})
    },
    [group,person,timestamp,functions,type])

    useEffect(()=>{
        setModalDefaults({
            UIDdefault: person.UIDBelongsTo,
            defaultType:'person',
            defaultDisplay: person.Display
        })
    },[person])

   // get  data for the person group
    useEffect(()=>{
        const getPersonData = async ()=> {
            const result= await apiSelect(`/kpe20/person/${UID}/?parent=true`)  
            if (result.success) {
                
               
                const myPerson=result.result    
                if(!myPerson.Data.phone)
                    myPerson.Data.phone=[]
                if(!myPerson.Data.email)
                    myPerson.Data.email=[]
                if(!myPerson.Data.address)
                    myPerson.Data.address=[]
                setPerson(myPerson)
                setGroup({UID:myPerson.parent.UID})
                setType(myPerson.Type)
                setRailPath(myPerson.parent.UID)
            }
           
        }
        const getPersonAdmin=async()=>
        {
            const result= await apiSelect(`/kpe20/person/admin/${UID}`)
            if(result.success) {
                setAdmin(result.result)
            }
        }

        getPersonData()
        getPersonAdmin()

        // delete defaults, when view is closed 
        return ()=>{setModalDefaults({})}
    }, [UID])

    useEffect(()=>{
        setContainerWidth(1200)
    },[width])

    const personEntry=()=>
    {
        if(admin)
            history.push('/show/person/main/'+person.UIDBelongsTo)
    }

    const onDelete=async()=>
    {
        if(window.confirm(texts.confirmDeleteJob))
        {
            const result=await apiDelete(`/kpe20/job/${UID}`)
            if(result.success)
            {
                history.push('/')
            }
            else
            {
                window.alert(result.message)
            }
        }


    }

    return(
        <>
            <Segment>
                <Header as ="h2" onClick={personEntry} color={admin ? 'blue' : 'black'} style={admin ?{cursor:'pointer'}:{}}>
                    {person.Data.firstName} {person.Data.lastName}
                </Header>
                <Label color={(config &&  config.stageColors) && config.stageColors[person.Data.stage]} basic size='large' ribbon> 
       
                    {person.Title}
                </Label>
                {
                    admin &&
                    <Button icon='trash' basic color="red" content={texts.deleteJob} onClick={onDelete}/>
                }


                <Header as="h3">
                    <a onClick={e=>history.push('/show/group/members/'+person.parent.UID)}>
                        {person.parent.Title} {person.parent.Display}
                    </a>
                </Header>
                <Suspense fallback={<Loader loading/>}>
                    <PersonMain
                        person={person}
                        UID={person.UIDBelongsTo}
                        group={group}
                        setModalParams={setModalParams}
                        functions={functions}
                        achievements={achievements}
                        timestamp={timestamp}
                        PopupHide={()=>{return''}}
                    />
                </Suspense>
            </Segment>
        </>
    )

}

export default PersonJob
