export const countriesShort=
    [
    {"CIndex":"A","ISO2":"AT","EName":"Austria","DName":"Österreich","Auto":"Ja"},
    {"CIndex":"AFG","ISO2":"AF","EName":"Afghanistan","DName":"Afghanistan","Auto":""},
    {"CIndex":"AG","ISO2":"AG","EName":"Antigua And Barbuda","DName":"Antigua und Barbuda","Auto":""},
    {"CIndex":"AL","ISO2":"AL","EName":"Albania","DName":"Albanien","Auto":""},
    {"CIndex":"AND","ISO2":"AD","EName":"Andorra","DName":"Andorra","Auto":""},
    {"CIndex":"ANG","ISO2":"AO","EName":"Angola","DName":"Angola","Auto":""},
    {"CIndex":"ARM","ISO2":"AM","EName":"Armenia","DName":"Armenien","Auto":""},
    {"CIndex":"AUS","ISO2":"AU","EName":"Australia","DName":"Australien","Auto":""},
    {"CIndex":"AZ","ISO2":"AZ","EName":"Azerbaijan","DName":"Azerbaijan","Auto":""},
    {"CIndex":"B","ISO2":"BE","EName":"Belgium","DName":"Belgien","Auto":""},
    {"CIndex":"BD","ISO2":"BD","EName":"Bangladesh","DName":"Bangladesh","Auto":""},
    {"CIndex":"BDS","ISO2":"BB","EName":"Barbados","DName":"Barbados","Auto":""},
    {"CIndex":"BF","ISO2":"BF","EName":"Burkina Faso","DName":"Burkina Faso","Auto":""},
    {"CIndex":"BG","ISO2":"BG","EName":"Bulgaria","DName":"Bulgarien","Auto":""},
    {"CIndex":"BH","ISO2":"BZ","EName":"Belize","DName":"Belize","Auto":""},
    {"CIndex":"BHT","ISO2":"BT","EName":"Bhutan","DName":"Bhutan","Auto":""},
    {"CIndex":"BIH","ISO2":"BA","EName":"Bosnia And Herzegowina","DName":"Bosnien Herzegowina","Auto":""},
    {"CIndex":"BOL","ISO2":"BO","EName":"Bolivia","DName":"Bolivien","Auto":""},
    {"CIndex":"BR","ISO2":"BR","EName":"Brazil","DName":"Brasilien","Auto":""},
    {"CIndex":"BRN","ISO2":"BH","EName":"Bahrain","DName":"Bahrain","Auto":""},
    {"CIndex":"BRU","ISO2":"BN","EName":"Brunei Darussalam","DName":"Brunei Darussalam","Auto":""},
    {"CIndex":"BS","ISO2":"BS","EName":"Bahamas","DName":"Bahamas","Auto":""},
    {"CIndex":"BU","ISO2":"BI","EName":"Burundi","DName":"Burundi","Auto":""},
    {"CIndex":"BY","ISO2":"BY","EName":"Belarus","DName":"Weissrussland","Auto":""},
    {"CIndex":"C","ISO2":"CU","EName":"Cuba","DName":"Kuba","Auto":""},
    {"CIndex":"CAM","ISO2":"CM","EName":"Cameroon","DName":"Kamerun","Auto":""},
    {"CIndex":"CDN","ISO2":"CA","EName":"Canada","DName":"Kanada","Auto":""},
    {"CIndex":"CH","ISO2":"CH","EName":"Switzerland","DName":"Schweiz","Auto":"Ja"},
    {"CIndex":"CL","ISO2":"LK","EName":"Sri Lanka","DName":"Sri Lanka","Auto":""},
    {"CIndex":"CO","ISO2":"CO","EName":"Colombia","DName":"Kolumbien","Auto":""},
    {"CIndex":"COM","ISO2":"KM","EName":"Comoros","DName":"Komoren","Auto":""},
    {"CIndex":"CR","ISO2":"CR","EName":"Costa Rica","DName":"Costa Rica","Auto":""},
    {"CIndex":"CV","ISO2":"CV","EName":"Cape Verde","DName":"Cape Verde","Auto":""},
    {"CIndex":"CY","ISO2":"CY","EName":"Cyprus","DName":"Zypern","Auto":""},
    {"CIndex":"CZ","ISO2":"CZ","EName":"Czech Republic","DName":"Tschechische  Republik","Auto":""},
    {"CIndex":"D","ISO2":"DE","EName":"Germany","DName":"Deutschland","Auto":"Ja"},
    {"CIndex":"DARS","ISO2":"EH","EName":"Sahara","DName":"Sahara","Auto":""},
    {"CIndex":"DK","ISO2":"DK","EName":"Denmark","DName":"Dänemark","Auto":""},
    {"CIndex":"DOM","ISO2":"DO","EName":"Dominican Republic","DName":"Dominikanische Republik","Auto":""},
    {"CIndex":"DVRK","ISO2":"KP","EName":"North Korea","DName":"Nord Korea","Auto":""},
    {"CIndex":"DY","ISO2":"BJ","EName":"Benin","DName":"Benin","Auto":""},
    {"CIndex":"DZ","ISO2":"DZ","EName":"Algeria","DName":"Algerien","Auto":""},
    {"CIndex":"E","ISO2":"ES","EName":"Spain","DName":"Spanien","Auto":""},
    {"CIndex":"EAK","ISO2":"KE","EName":"Kenya","DName":"Kenia","Auto":""},
    {"CIndex":"EAT","ISO2":"TZ","EName":"Tanzania","DName":"Tansania","Auto":""},
    {"CIndex":"EAU","ISO2":"UG","EName":"Uganda","DName":"Uganda","Auto":""},
    {"CIndex":"EC","ISO2":"EC","EName":"Ecuador","DName":"Ecuador","Auto":""},
    {"CIndex":"ER","ISO2":"ER","EName":"Eritrea","DName":"Eritrea","Auto":""},
    {"CIndex":"ES","ISO2":"SV","EName":"El Salvador","DName":"El Salvador","Auto":""},
    {"CIndex":"EST","ISO2":"EE","EName":"Estland","DName":"Estland","Auto":""},
    {"CIndex":"ET","ISO2":"EG","EName":"Egypt","DName":"Ägypten","Auto":""},
    {"CIndex":"ETH","ISO2":"ET","EName":"Ethiopia","DName":"Ethiopia","Auto":""},
    {"CIndex":"F","ISO2":"FR","EName":"France","DName":"Frankreich","Auto":""},
    {"CIndex":"FIN","ISO2":"FI","EName":"Finland","DName":"Finnland","Auto":""},
    {"CIndex":"FJI","ISO2":"FJ","EName":"Fiji","DName":"Fiji","Auto":""},
    {"CIndex":"FL","ISO2":"LI","EName":"F","DName":"Liechtenstein","Auto":""},
    {"CIndex":"FR","ISO2":"FO","EName":"Faroe Islands","DName":"Faroer Inseln","Auto":""},
    {"CIndex":"FSM","ISO2":"FM","EName":"Micronesia","DName":"Micronesien","Auto":""},
    {"CIndex":"G","ISO2":"GA","EName":"Gabon","DName":"Gabun","Auto":""},
    {"CIndex":"GB","ISO2":"GB","EName":"United Kingdom","DName":"Groß Britannien","Auto":""},

    {"CIndex":"GBG","ISO2":"GG","EName":"Guernsey","DName":"Guernsey","Auto":""},
    {"CIndex":"GBJ","ISO2":"JE","EName":"Jersey","DName":"Jersey","Auto":""},
    {"CIndex":"GBM","ISO2":"IM","EName":"Isle of Man","DName":"Isle of Man","Auto":""},
    {"CIndex":"GBZ","ISO2":"GI","EName":"Gibraltar","DName":"Gibraltar","Auto":""},
    {"CIndex":"GCA","ISO2":"GT","EName":"Guatemala","DName":"Guatemala","Auto":""},
    {"CIndex":"GE","ISO2":"GE","EName":"Georgien","DName":"Georgien","Auto":""},
    {"CIndex":"GH","ISO2":"GH","EName":"Ghana","DName":"Ghana","Auto":""},
    {"CIndex":"GNB","ISO2":"GW","EName":"Guinea-Bissau","DName":"Guinea-Bissau","Auto":""},
    {"CIndex":"GR","ISO2":"GR","EName":"Greece","DName":"Griechenland","Auto":""},
    {"CIndex":"GUY","ISO2":"GY","EName":"Guyana","DName":"Guyana","Auto":""},
    {"CIndex":"H","ISO2":"HU","EName":"Hungary","DName":"Ungarn","Auto":""},
    {"CIndex":"HK","ISO2":"HK","EName":"Hong Kong","DName":"Hongkong","Auto":""},
    {"CIndex":"HN","ISO2":"HN","EName":"Honduras","DName":"Honduras","Auto":""},
    {"CIndex":"HR","ISO2":"HR","EName":"Kroatien","DName":"Kroatien","Auto":""},
    {"CIndex":"I","ISO2":"IT","EName":"Italy","DName":"Italien","Auto":""},
    {"CIndex":"IL","ISO2":"IL","EName":"Israel","DName":"Israel","Auto":""},
    {"CIndex":"IND","ISO2":"IN","EName":"India","DName":"Indien","Auto":""},
    {"CIndex":"IR","ISO2":"IR","EName":"Iran","DName":"Iran","Auto":""},
    {"CIndex":"IRL","ISO2":"IE","EName":"Ireland","DName":"Irland","Auto":""},
    {"CIndex":"IRQ","ISO2":"IQ","EName":"Iraq","DName":"Irak","Auto":""},
    {"CIndex":"IS","ISO2":"IS","EName":"Island","DName":"Island","Auto":""},
    {"CIndex":"J","ISO2":"JP","EName":"Japan","DName":"Japan","Auto":""},
    {"CIndex":"JA","ISO2":"JM","EName":"Jamaica","DName":"Jamaica","Auto":""},
    {"CIndex":"JOR","ISO2":"JO","EName":"Jordan","DName":"Jordanien","Auto":""},
    {"CIndex":"K","ISO2":"KH","EName":"Kambodscha","DName":"Kambodscha","Auto":""},
    {"CIndex":"KIR","ISO2":"KI","EName":"Kiribati","DName":"Kiribati","Auto":""},
    {"CIndex":"KN","ISO2":"KN","EName":"Saint Kitts And Nevis","DName":"Saint Kitts And Nevis","Auto":""},
    {"CIndex":"KS","ISO2":"KG","EName":"Kyrgyzstan","DName":"Kyrgisien","Auto":""},
    {"CIndex":"KSA","ISO2":"SA","EName":"Saudi Arabia","DName":"Saudi Arabien","Auto":""},
    {"CIndex":"KWT","ISO2":"KW","EName":"Kuwait","DName":"Kuwait","Auto":""},
    {"CIndex":"KZ","ISO2":"KZ","EName":"Kazakhstan","DName":"Kazakhstan","Auto":""},
    {"CIndex":"L","ISO2":"LU","EName":"Luxembourg","DName":"Luxemburg","Auto":""},
    {"CIndex":"LAR","ISO2":"LY","EName":"Libyan Arab Jamahiriya","DName":"Libyan Arab Jamahiriya","Auto":""},
    {"CIndex":"LB","ISO2":"LR","EName":"Liberia","DName":"Liberia","Auto":""},
    {"CIndex":"LS","ISO2":"LS","EName":"Lesotho","DName":"Lesotho","Auto":""},
    {"CIndex":"LT","ISO2":"LT","EName":"Lithuania","DName":"Lithauen","Auto":""},
    {"CIndex":"LV","ISO2":"LV","EName":"Latvia","DName":"Latvia","Auto":""},
    {"CIndex":"M","ISO2":"MT","EName":"Malta","DName":"Malta","Auto":""},
    {"CIndex":"MA","ISO2":"MA","EName":"Marocco","DName":"Marocco","Auto":""},
    {"CIndex":"MAL","ISO2":"MY","EName":"Malaysia","DName":"Malaysia","Auto":""},
    {"CIndex":"MC","ISO2":"MC","EName":"Monaco","DName":"Monaco","Auto":""},
    {"CIndex":"MD","ISO2":"MD","EName":"Moldova","DName":"Moldavien","Auto":""},
    {"CIndex":"MEX","ISO2":"MX","EName":"Mexico","DName":"Mexico","Auto":""},
    {"CIndex":"MH","ISO2":"MH","EName":"Marshall Islands","DName":"Marshall Islands","Auto":""},
    {"CIndex":"MK","ISO2":"MK","EName":"Macedonia","DName":"Mazedonien","Auto":""},
    {"CIndex":"MNG","ISO2":"MN","EName":"Mongolia","DName":"Mongolei","Auto":""},
    {"CIndex":"MOC","ISO2":"MZ","EName":"Mozambique","DName":"Mozambique","Auto":""},
    {"CIndex":"MS","ISO2":"MU","EName":"Mauritius","DName":"Mauritius","Auto":""},
    {"CIndex":"MV","ISO2":"MV","EName":"Maldives","DName":"Malediven","Auto":""},
    {"CIndex":"MW","ISO2":"MW","EName":"Malawi","DName":"Malawi","Auto":""},
    {"CIndex":"MYA","ISO2":"MM","EName":"Myanmar","DName":"Myanmar","Auto":""},
    {"CIndex":"N","ISO2":"NO","EName":"Norway","DName":"Norwegen","Auto":""},
    {"CIndex":"NA","ISO2":"AN","EName":"Netherlands Antilles","DName":"Niederländische Antillen","Auto":""},
    {"CIndex":"NAM","ISO2":"NA","EName":"Namibia","DName":"Namibia","Auto":""},
    {"CIndex":"NAU","ISO2":"NR","EName":"Nauru","DName":"Nauru","Auto":""},
    {"CIndex":"NEP","ISO2":"NP","EName":"Nepal","DName":"Nepal","Auto":""},
    {"CIndex":"NIC","ISO2":"NI","EName":"Nicaragua","DName":"Nicaragua","Auto":""},
    {"CIndex":"NL","ISO2":"NL","EName":"Netherlands","DName":"Niederlande","Auto":""},
    {"CIndex":"NZ","ISO2":"CK","EName":"New Zealand","DName":"Neuseeland","Auto":""},
    {"CIndex":"OM","ISO2":"OM","EName":"Oman","DName":"Oman","Auto":""},
    {"CIndex":"P","ISO2":"PT","EName":"Portugal","DName":"Portugal","Auto":""},


    {"CIndex":"PE","ISO2":"PE","EName":"Peru","DName":"Peru","Auto":""},
    {"CIndex":"PK","ISO2":"PK","EName":"Pakistan","DName":"Pakistan","Auto":""},
    {"CIndex":"PL","ISO2":"PL","EName":"Poland","DName":"Polen","Auto":""},
    {"CIndex":"PNG","ISO2":"PG","EName":"Papua New Guinea","DName":"Papua Neu Guinea","Auto":""},
    {"CIndex":"PY","ISO2":"PY","EName":"Paraguay","DName":"Paraguay","Auto":""},
    {"CIndex":"Q","ISO2":"QA","EName":"Qatar","DName":"Qatar","Auto":""},
    {"CIndex":"RA","ISO2":"AR","EName":"Argentina","DName":"Argentinien","Auto":""},

    {"CIndex":"RC","ISO2":"TW","EName":"Taiwan","DName":"Taiwan","Auto":""},
    {"CIndex":"RCA","ISO2":"CF","EName":"Republic of Central Africa","DName":"Republik Zentral Afrika","Auto":""},
    {"CIndex":"RCB","ISO2":"CG","EName":"Congo","DName":"Kongo","Auto":""},
    {"CIndex":"RCH","ISO2":"CL","EName":"Chile","DName":"Chile","Auto":""},
    {"CIndex":"RG","ISO2":"GN","EName":"Guinea","DName":"Guinea","Auto":""},

    {"CIndex":"RI","ISO2":"ID","EName":"Indonesia","DName":"Indonesin","Auto":""},
    {"CIndex":"RIM","ISO2":"MR","EName":"Mauritania","DName":"Mauritanien","Auto":""},
    {"CIndex":"RL","ISO2":"LB","EName":"Lebanon","DName":"Libanon","Auto":""},
    {"CIndex":"RM","ISO2":"MG","EName":"Madagascar","DName":"Madagascar","Auto":""},
    {"CIndex":"RMM","ISO2":"ML","EName":"Mali","DName":"Mali","Auto":""},
    {"CIndex":"RN","ISO2":"NE","EName":"Niger","DName":"Niger","Auto":""},
    {"CIndex":"RO","ISO2":"RO","EName":"Romania","DName":"Rumänien","Auto":""},
    {"CIndex":"ROK","ISO2":"KR","EName":"South Korea","DName":"Süd Korea","Auto":""},
    {"CIndex":"ROU","ISO2":"UY","EName":"Uruguay","DName":"Uruguay","Auto":""},
    {"CIndex":"RP","ISO2":"PH","EName":"Philippines","DName":"Philippinen","Auto":""},
    {"CIndex":"RSM","ISO2":"SM","EName":"San Marino","DName":"San Marino","Auto":""},

    {"CIndex":"RUS","ISO2":"RU","EName":"Russian Federation","DName":"Russland","Auto":""},
    {"CIndex":"RWA","ISO2":"RW","EName":"Rwanda","DName":"Ruanda","Auto":""},
    {"CIndex":"S","ISO2":"SE","EName":"Sweden","DName":"Schweden","Auto":""},
    {"CIndex":"SD","ISO2":"SZ","EName":"Swaziland","DName":"Swaziland","Auto":""},
    {"CIndex":"SGP","ISO2":"SG","EName":"Singapore","DName":"Singapore","Auto":""},
    {"CIndex":"SK","ISO2":"SK","EName":"Slovakia","DName":"Slovakei","Auto":""},
    {"CIndex":"SLO","ISO2":"SI","EName":"Slovenia","DName":"Slowenien","Auto":""},
    {"CIndex":"SME","ISO2":"SR","EName":"Suriname","DName":"Surinam","Auto":""},
    {"CIndex":"SN","ISO2":"SN","EName":"Senegal","DName":"Senegal","Auto":""},

    {"CIndex":"SUD","ISO2":"SD","EName":"Sudan","DName":"Sudan","Auto":""},
    {"CIndex":"SY","ISO2":"SC","EName":"Seychelles","DName":"Seychellen","Auto":""},
    {"CIndex":"SYR","ISO2":"SY","EName":"Syrian Arab Republic","DName":"Syrien","Auto":""},
    {"CIndex":"TCH","ISO2":"TD","EName":"Chad","DName":"Tschad","Auto":""},

    {"CIndex":"TJ","ISO2":"TJ","EName":"Tajikistan","DName":"Tajikistan","Auto":""},
    {"CIndex":"TM","ISO2":"TM","EName":"Turkmenistan","DName":"Turkmenistan","Auto":""},
    {"CIndex":"TN","ISO2":"TN","EName":"Tunisia","DName":"Tunesien","Auto":""},
    {"CIndex":"TO","ISO2":"TO","EName":"Tonga","DName":"Tonga","Auto":""},
    {"CIndex":"TR","ISO2":"TR","EName":"Turkey","DName":"Türkei","Auto":""},
    {"CIndex":"TT","ISO2":"TT","EName":"Trinidad And Tobago","DName":"Trinidad und Tobago","Auto":""},

    {"CIndex":"UA","ISO2":"UA","EName":"Ukraine","DName":"Ukraine","Auto":""},
    
    {"CIndex":"USA","ISO2":"AS","EName":"United States","DName":"Vereinigte Staaten von Amerika","Auto":""},
    {"CIndex":"UZ","ISO2":"UZ","EName":"Uzbekistan","DName":"Uzbekistan","Auto":""},
    {"CIndex":"V","ISO2":"VA","EName":"Vatican City State","DName":"Vatikanstadt","Auto":""},
    {"CIndex":"VN","ISO2":"VN","EName":"Viet Nam","DName":"Vietnam","Auto":""},
   
    {"CIndex":"VU","ISO2":"VU","EName":"Vanuatu","DName":"Vanuatu","Auto":""},
    {"CIndex":"WAG","ISO2":"GM","EName":"Gambia","DName":"Gambia","Auto":""},
    {"CIndex":"WAL","ISO2":"SL","EName":"Sierra Leone","DName":"Sierra Leone","Auto":""},
    {"CIndex":"WAN","ISO2":"NG","EName":"Nigeria","DName":"Nigeria","Auto":""},
    {"CIndex":"WD","ISO2":"DM","EName":"Dominica","DName":"Dominica","Auto":""},
    {"CIndex":"WG","ISO2":"GD","EName":"Grenada","DName":"Grenada","Auto":""},
    {"CIndex":"WL","ISO2":"LC","EName":"Saint Lucia","DName":"Saint Lucia","Auto":""},
    {"CIndex":"WS","ISO2":"WS","EName":"American Samoa","DName":"Amerikanisch Samoa","Auto":""},
    {"CIndex":"WV","ISO2":"VC","EName":"Saint Vincent And The Grenadines","DName":"Saint Vincent And The Grenadines","Auto":""},


    {"CIndex":"YV","ISO2":"VE","EName":"Venezuela","DName":"Venezuela","Auto":""},
    {"CIndex":"Z","ISO2":"ZM","EName":"Zambia","DName":"Sambia","Auto":""},
    {"CIndex":"ZA","ISO2":"ZA","EName":"South Africa","DName":"Südafrika","Auto":""},
    {"CIndex":"ZRE","ISO2":"CD","EName":"Zaire","DName":"Zaire","Auto":""},
    {"CIndex":"ZW","ISO2":"ZW","EName":"Zimbabwe","DName":"Zimbabwe","Auto":""}
    ]
   
    