import {baseUrl,api} from '../config'


export const bannerFormatter=( cell)=>
{
    const value=cell.getValue()
    if(value && value.length>0)
    {
        const [UID,]=value.split('/')
        if(UID!=='null')
        {
            const url=`https://${api}.${baseUrl}/kpe20/files/${value}`
            let result=`<img src='${url}' height="15em"/>`
            return result
        }
    }
    else
    {
        return ''
    }
}