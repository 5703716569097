import React, {useState, useEffect, useRef} from 'react'
import { Header, Form, Modal, Segment, Message}  from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect, useCheckForm,DatePicker} from '@wollo-lib/wollo-react'
import { useConfig } from '../../../lib20/KPE20context'
//import {DatePicker} from '../../../lib/InputDate'
//import SearchSelect from '../lib/SearchSelect'
//import useCheckForm from '../Hooks/useCheckForm'
import useTexts from '../../../lib20/useTexts'
import {useHistory} from 'react-router-dom'

import ChangeGroup      from './ModalChangeGroup'
import ModalTimestamp   from  './ModalTimestamp'
import EnterGroup       from  './ModalEnterGroup'


const EditPerson=(props) => {

    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}=props

    const {createNew,UID,myPerson,myGroup,Type}=modalParams[0]
    const {defaultType,UIDdefault}=modalDefaults
    const {apiSelect, apiUpdate,apiInsert,apiDelete}=useApi()
    const texts=useTexts('/edit/person')
    const config=useConfig()

    const history=useHistory()
    const [person, setPerson] = useState({})
    const [group, setGroup] =useState({})
    const [groupQuery, setGroupQuery]=useState('')
    const [died,setDied]=useState(false)
    const [duplications,setDuplications]=useState([])
    const [noDuplication,setNoDuplication]=useState(false)

    useEffect(()=>{
        const getPerson =  () => {
                setPerson({Type:myPerson.Type,...myPerson.Data})
                if(Type!=='extern')
                    setGroupQuery('&gender='+JSON.stringify(['B',myPerson.gender]))
                setGroup(myGroup)

        }
        const initializeDefaults=async () => {
            const initPerson={ 
                Type
            }
            if(defaultType==='group')
            {
                
                const result=await apiSelect(`/kpe20/group/${UIDdefault}?siblings=true`, 'kpe20')
                if(result.success)
                {
                    const pGroup=result.result
                    initPerson.hierarchie=pGroup.hierarchie
                    initPerson.stage=pGroup.stage
                    if(pGroup.gender !=='C' && pGroup.gender !=='B')
                    {
                        initPerson.gender=pGroup.gender
                        if(Type!=='extern')
                            setGroupQuery('&gender='+pGroup.gender)
                    }
                   
                    setGroup({UID:pGroup.UID})
                }
                
            }
            setPerson(initPerson)
        }


        if (!createNew) {
            getPerson()
        }
        else {
           initializeDefaults()
        }
    }, [UID,myPerson,myGroup])
    
   
    const {errors,checkSubmit}=useCheckForm(person,
        [
            {name: 'firstName', error: texts.errorFirstName ,test:
                (val) => (val!==undefined && val !== '')
            },
            {name: 'lastName', error: texts.errorLastName ,test:
                (val) => (val!==undefined && val !== '')
            },
            {name: 'gender', error: texts.errorGender ,test:
                (val) => (val!==undefined && val !== '')
            },
            {name:'birthday',error:texts.errorBirthday ,test:
                (val) => ((val!==undefined && val!== '') || person.Type==='extern')
            },
            
    
        ])
    
    const {errors:groupErrors,checkSubmit:checkGroupSubmit}=useCheckForm(group,
        [
            {name: 'UID', error: texts.errorGroup ,test:(val)=>{
                    return val!==undefined && val!==''
            }}
        ])
     
    const onBlur=async (e)=>
    {
        // check potential duplications, when lastName and firstName is entered
        if(createNew && person.lastName && person.lastName !==''
             && person.firstName && person.firstName!=='')
        {
            const result=await apiSelect(`/kpe20/person/duplicates/${person.firstName}/${person.lastName}`)
            if(result.success)
            {
                if(result.result.length>0)
                {
                    setDuplications(result.result)
                }
            }
        }
    }

    const handleChange=(ev, {name, value})=>
    {

        if(name==='gender')
        {
            if(value)
            {
                if(Type!=='extern')
                    setGroupQuery('&gender='+JSON.stringify([value,'B']))
            }
            else
            {
                setGroupQuery('')
            }
        }
        if(name==='category')
        {
            // prevent deletion of category 'died'
            if(person.category?.includes('died') && !value.includes('died'))
            {
                value=[...value,'died']
            }
            if(!person.category?.includes('died') && value.includes('died'))
            {
                // remember for asking the user for the died date
                setDied(true)
            }
            setPerson(m=>({...m,category:value}))

        }
        else
        {
            setPerson((m)=>(
                {
                    ...m,
                    [name] : value
                }
            ))
        }
    }
    

    modalSubmit.current=async (event)=> {

        // you have to call the checks in seperate statements, not like if(!checkSubmit() || !checkGroupSubmit()), in this case the optemiser is calling onfly the first check
        // and will not call the second one, if the first one in the or statement is already true


        if(!checkSubmit() || (createNew && duplications.length>0 && !noDuplication)) {
            return
        }
        else {

            if(createNew)
            {   
                setPerson({...person,phone:[],address:[],email:[],messenger:[],accounts:[]})
                setModalParams({setAll:[
                    {
                        component : EnterGroup,
                        text:  texts[`${Type}GroupEnter`],
                        textDate: texts.enterDate,
                        UID:UID,
                        group: group,
                        groupQuery: groupQuery,
                        person: person,
                        Type: Type,
                        setPerson:setPerson

                    },
                ]})
               
            }
            else if(died)
            {
                setDied(false)  
                setModalParams({
                    setAll:[
                    {
                        component : ModalTimestamp,
                        text: texts.confirmDied,
                        textDate:  texts.diedDate,
                        UID:UID,
                        group: group,
                        type:'extern',
                        category:  person.category ? 
                            person.category.includes('died') ? person.category : [...person.category,'died'] :
                        ['died']
            
                    }
                ]})
            }
            else
            {

                const result= await apiUpdate(`/kpe20/${person.Type}/`+UID,{UID:UID,... person} )
                resetModal()
                if(!result.success)
                    window.alert('error update person: '+result.message)

               
            }
            
        }


    }

    const clickButton=async (ev,{name})=>
    {

        if(name==='change')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroup,
                    textDate: texts.changeDate,
                    UID:UID,
                    group: group,
                    setGroup:setGroup,
                    groupQuery: groupQuery,
                    type:'person',

                }
            })
        }
        else if(name==='enter')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroupEnter,
                    textDate: texts.enterDate,
                    UID:UID,
                    group: group,
                    setGroup:setGroup,
                    groupQuery: `&gender=["B","${myPerson.gender}"]`,
                    type:'person',

                }
            })
        }
        else if(name==='changeExtern')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroupExtern,
                    textDate: texts.changeDate,
                    UID:UID,
                    group: group,
                    setGroup:setGroup,
                    groupQuery: groupQuery,
                    type:'extern',

                }
            })
        }
        else if(name==='exit')
        {
            setModalParams({
                addLevel:
                {
                    component : ModalTimestamp,
                    text: texts.confirmExit,
                    textDate:  texts.exitDate,
                    UID:UID,
                    group: group,
                    type:'extern',
                    category: person.category ? 
                            person.category.includes('former') ? person.category : [...person.category,'former'] :
                            ['former']

        
                }
            })
        }
        else if(name==='died')
        {
            setModalParams({
                addLevel:
                {
                    component : ModalTimestamp,
                    text: texts.confirmDied,
                    textDate:  texts.diedDate,
                    UID:UID,
                    group: group,
                    type:'extern',
                    category:  person.category ? 
                        person.category.includes('died') ? person.category : [...person.category,'died'] :
                    ['died']
        
                }
            })
        }
        else if(name==='delete')
        {
            if(window.confirm(texts.confirmDeleteExtern))
            {
               const result= await apiDelete(`/kpe20/extern/${UID}`)
               if(result.success)
               {
                   resetModal()
                   history.push('/show/group/members/'+group.UID)
               }
            }
        }
        else
            alert('not yet implemented')
    }
    

    return (<>
        <Modal.Header>
            {createNew ? texts[`${Type}HeaderNew`] : texts[`${person.Type}HeaderEdit`]}
        </Modal.Header>
        <Modal.Content>
            <Form 
                size='tiny'
                error={createNew && duplications.length>0 && !noDuplication}

            >
                <Segment.Group>
                    <Segment secondary>
                    
                        <Form.Group widths='equal'>
                            <Form.Input 
                                label = {texts.Greeting} 
                                placeholder = {texts.Greeting}
                                name ="greeting"
                                onChange = {handleChange}
                                value = {person.greeting}
                                fluid
                            />

                            <Form.Input 
                                label = {texts.Title} 
                                placeholder = {texts.Title}
                                name = "title"
                                onChange = {handleChange}
                                value = {person.title}
                                fluid
                            />

                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Input 
                                label = {texts.NickName} 
                                placeholder = {texts.NickName}
                                name ="nickname"
                                onChange = {handleChange}
                                value = {person.nickname}
                                fluid
                            />

                            <Form.Input 
                                label = {texts.BirthName} 
                                placeholder = {texts.BirthName}
                                name = "birthName"
                                onChange = {handleChange}
                                value = {person.birthName}
                                fluid
                            />

                        </Form.Group>
                    
                </Segment>
                <Segment>
                        <Form.Group widths='equal'>
                            <Form.Input 
                                label = {texts.FirstName} 
                                placeholder = {texts.FirstName}
                                name ="firstName"
                                onChange = {handleChange}
                                value = {person.firstName}
                                error={errors.firstName}
                                fluid
                                onBlur={onBlur}
                            />

                            <Form.Input 
                                label = {texts.LastName} 
                                placeholder = {texts.LastName}
                                name = "lastName"
                                onChange = {handleChange}
                                value = {person.lastName}
                                error = {errors.lastName}
                                fluid
                                onBlur={onBlur}
                            />
                        </Form.Group>
                
                        <Form.Group>
                            <Form.Field
                                label={texts.Birthday}
                                control={DatePicker}
                                placeholder={texts.Birthday}
                                value={person.birthday}
                                name='birthday'
                                onChange={handleChange}
                                maxDate={Date.now()/1000}
                                error={errors.birthday}

                            />
                            <Form.Select
                                label = {texts.Gender}
                                placeholder = {texts.Gender}
                                options = {[   
                                {text : texts.GenderM, value : "M", key: "M"},
                                {text: texts.GenderF, value : "F", key :"F"}]}
                                name = "gender"
                                onChange = {handleChange}
                                value = {person.gender}
                                error = {errors.gender}
                            />
                        </Form.Group>
                        {
                            Type !== 'extern' ? '':
                            // edit category for externals 
                            <Form.Select
                                label = {texts.externCategory}
                                placeholder = {texts.externCategory}
                                options = {
                                    Object.entries(config.ExternCategories).map(([val,text])=>({
                                        key:val,
                                        value:val,
                                        text:text
                                    }))  
                                }
                                multiple
                                name = "category"
                                onChange = {handleChange}
                                value = {person.category}
                            />
                            
                        }
                   
                </Segment>
            </Segment.Group>
           

                { 
                    createNew ? ''
                       /* <Form.Field
                            label = {texts.Group}
                            placeholder = {texts.Group}
                            control = {SearchSelect}
                            api = "kpe20"
                            SuggestUrl={'/kpe20/SearchData?types=group'+groupQuery}
                            Value2TitleUrl={'/kpe20/Value2Title?types=group'+groupQuery}
                            name = "UIDgroup"
                            value = {group.UID}

                            autoSelect
                            onChange={(ev,{value})=>{setGroup({UID:value})}}
                            error={groupErrors.UID}
                       />*/
                    :
                        <Segment>
                            <Header as='h4' content={group.Title+' '+ group.Display} />
                            { myPerson.Type!=='extern' ?

                                <Form.Group  widths='equal'>
                                    <Form.Button content={texts.changeGroup} color='green' name='change' icon='exchange' onClick={clickButton}/> 
                                    <Form.Button content={texts.exitOrga} color='grey'  name='exit' icon='sign-out' onClick={clickButton}/> 
                                    <Form.Button content={texts.died} color='black'  name='died' icon='dot circle outline' onClick={clickButton}/> 
                                </Form.Group>
                                :
                                <Form.Group  widths='equal'>
                                    <Form.Button content={texts.changeGroupExtern} color='grey' icon='exchange' name='changeExtern' onClick={clickButton}/> 
                                    <Form.Button content={texts.enterOrga} color='green' icon='sign-in' name='enter' onClick={clickButton}/> 
                                    <Form.Button content={texts.deleteExtern} color='grey'  icon='delete' name='delete' onClick={clickButton}/> 
                                    <Form.Button content={texts.died} color='black' icon='dot circle outline' name='died' onClick={clickButton}/> 
                                </Form.Group>

                            }
                            
                        </Segment>

               
                }
                {
                    createNew  &&
                    <Message
                        error
                    >
                        <Message.Header>{texts.duplicationHeader}</Message.Header>
                        <Message.Content>
                            <ul>
                                {
                                    duplications.map(dup=>
                                    (   <li>
                                            {dup.Title} {dup.Display}
                                        </li>
                                    ))
                                }
                            </ul>
                            <p>{texts.duplicationText}</p>
                            <Form.Checkbox label={texts.duplicationsConfirm} onChange={()=> setNoDuplication((prevChecked) => !prevChecked)}/>
                        </Message.Content>
                    </Message>
                }
            </Form>
        </Modal.Content>
        {children}


    </>)

}
export default EditPerson
