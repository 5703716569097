import React,{ useState, useEffect, useRef }  from  'react';
import {Loader,Button,Checkbox}                         from        'semantic-ui-react'
import EditFilter                               from    '../modals/EditFilterSingle'
import OutputMenu from '../../../output/OutputMenu';
import {
    useApi,
    usePageSelect,
    useWindowDimensions
}                                       from        '@wollo-lib/kpe-context'    // '../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'


//import MyTabulator,{autoNumFormatter}   from        '../../../lib/MyTabulator'
import MyTabulator,{autoNumFormatter}   from        '@wollo-lib/wollo-tabulator'

import useTexts                         from        '../../../lib20/useTexts'
import { useHistory}         from        "react-router-dom";
import { bannerFormatter }              from        '../../../lib/formatters';



const Table=(props)=>
{

    const texts=useTexts('/show/group')
    const {UID,timestamp,tabRef,functions,achievements, wsTime}= props
    const {apiUpdate, apiSelect}=useApi()
    const [filterOpen,setFilterOpen]=useState(false)
    const [totalCount,setTotalCount]=useState(0)
    const firstIndex=useRef(0)
    const [dataTree,setDataTree]=useState(true)

    const history= useHistory()
    const [isLoading,setIsLoading]=useState(false)
    const [groups,setGroups]=useState([])
    const [groupTree, setGroupTree]=useState([])
    const {height}=useWindowDimensions()
    
    const jsonFields= JSON.stringify([
        {path:'$.banner', alias: 'banner'},
        {path:'$.location', alias: 'location'}
    ])

    
    const [filterState,setFilterState]=useState({
        filterSource:null,
        filterAlgo:{},
        types: { group :true}
     })
   
    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo
 
    })
    const ajaxParams =
        {
            Timestamp:timestamp,
            type:JSON.stringify(['group','ggroup']),
            Data:jsonFields,
            dataFilter: JSON.stringify(algo)
        }

   // const {onScroll,isLoading,totalCount}=usePageSelect({select:`/kpe20/collections/${UID}`,urlPara:ajaxParams, setData:setGroups, pageSize:50, clientFilter:true})
    
    useEffect(()=>{
        const getTree=async()=>
        {
            setIsLoading(true)
            const [resultG,result,rootCounts]= await Promise.all([
                apiSelect(`/kpe20/group/${UID}/?parent=true`),
                apiSelect(`/kpe20/collections/${UID}/?${Object.entries(ajaxParams).map(([key,value])=>(`${key}=${value}`)).join('&')}`),
                apiUpdate(`/kpe20/collections/counts`,[UID])
            ])
            if(result.success && resultG.success)
            {
                let myGroups=result.result
                const counts=await apiUpdate(`/kpe20/collections/counts`,myGroups.map(g=>g.UID))
                
                const myGroups2=myGroups.map(g=>{
                    const gcount=counts.find(count=>count.UID===g.UID)
                        return {...g,dindex:gcount?.count ?? 0}
                })
                setTotalCount(myGroups.length)
                setGroups(myGroups2.map(g=>({...g})))
                const getChildren=(group)=>
                {
                    group.treeTitle=`${group.Title} ${group.location ? group.location : group.Display}`
                    
                    if(group.gender!=='C' )
                    {
                        const children=myGroups2.filter((g)=>g.UIDgroup===group.UID)
                        group._children=children.map(child=>getChildren(child))
                    }
                    else
                    {
                        const sisters=myGroups2.filter((g)=>g.UIDsister===group.UID)
                        group._children=sisters.map(child=>getChildren(child))
                    }
                    return group
                   
                }
                const tree=getChildren(
                    {
                        UID: resultG.result.UID,
                        banner: resultG.result.Data.banner,
                        Title: resultG.result.Title,
                        treeTitle: `${resultG.result.Title} ${resultG.result.Data.location}`,
                        Display: resultG.result.Display,
                        pGroup: [resultG.result?.parent?.Title,resultG.result?.parent?.Display].join(' '),
                        dindex: rootCounts[0]?.count,
                        gender: resultG.result.Data.gender
                      
                    })
                
                setGroupTree([
                    
                   tree
                    
                ])
                setIsLoading(false)
 
               
          
                
            }
        }

        if(UID)
            getTree()

    },[UID, wsTime])
    


    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        if(['Title','Display','treeTitle'].includes(cell.getField()) )
        {
            history.push('/show/group/'+rowData.UID)
        }
        if(cell.getField()==='pGroup')
        {
            history.push('/show/group/'+rowData.UIDgroup)
        }
        
    }

    const toggleFilterOpen=()=>
    {
        setFilterOpen(state=>!state)
    }

    useEffect(()=>
    {
        if(UID)
            setFilterState(state=>({...state,filterSource:UID}))
    },
    [UID])



   

    const listColumnsTree=[
        
        { title:  texts.GroupType, field: 'treeTitle', minWidth: 300,  headerFilter:"input"},
        { formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner', download:false},
        { title:  texts.GroupName, field: 'Display', minWidth: 100,  headerFilter:"input"},
        { title:  texts.Count, field: 'dindex',  width:90, headerFilter:"input"}
    ]
       
    const listColumns=[
        
        { formatter:autoNumFormatter, width:50, frozen:true, download:false, visible:false, field:'UID'}, //incrementing row number
               //{formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:50, hozAlign:"center"}, // edit 
        { title:  texts.GroupType, field: 'treeTitle', minWidth: 300,  headerFilter:"input", visible: false},
        { formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner', download:false},
        { title:  texts.GroupType, field: 'Title', minWidth: 100,  headerFilter:"input"},
        { title:  texts.GroupName, field: 'Display', minWidth: 100,  headerFilter:"input"},
        { title:  texts.belongsTo, field: 'pGroup',   headerFilter:"input"},
        { title:  texts.Count, field: 'dindex',  width:90, headerFilter:"input"}
    ]
       
    const toggleTree=(e, {checked}) =>{
        setDataTree(checked)
        if(checked)
        {
           tabRef.current.setColumns(listColumnsTree)
        }
        else
        {
            tabRef.current.setColumns(listColumns)
        }

    } 
         return(
            <>
                <Button 
                    icon='filter' 
                    circular
                    basic
                    onClick={toggleFilterOpen}
                /> 
                {isLoading ? <Loader active inline size='small'/> : totalCount}&nbsp;{texts.groupCount}
                {filterOpen &&
                    <EditFilter
                        functions={functions}
                        achievements={achievements}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        type='group'
                        UID={UID}

                    />
                }
                 <Checkbox
                        toggle 
                        label='Baum Struktur'
                        onChange={toggleTree}
                        checked={dataTree}
                        icon='sitemap'
                />
                
                    <MyTabulator
                        index='UID'
                        columns={listColumnsTree}
                        headerFilterPlaceholder={texts.headerFilterPlaceholder}
                        data={dataTree ? groupTree : groups}
                        onClick={cellClick}                   
                        compact="very"
                        striped
                        layout="fitColumns"    
                        replaceMode={true}
                        dataTreeStartExpanded={true}
                        height={Math.max(height-250,500)}  
                        tabRef={tabRef}
                        dataTree={dataTree}

                    />

                
            </>

        )


}

export default Table

