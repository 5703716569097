import React, { useEffect, useId, useState } from 'react'
import {Button, Container, Divider, Form, Header, Loader, Popup, Segment} from 'semantic-ui-react'
import {useConfig} from '../lib20/KPE20context'
import useTexts from '../lib20/useTexts'

const MapLinks=(props)=>
{
    const {handleChange, setDefaultSettings}=props
    const texts=useTexts('dbSettings')
    const config=useConfig()

    useEffect(()=>{
        setDefaultSettings(old=>({
            ...old,
            mapPlatform:    'GoogleMaps',        
            mapLinkType:    'Address',
    }))
    },[config])


    return (
        <Form>
            <Header>{texts.Maplinks}</Header>
            {
                texts.MaplinkDescr
            }
            <Form.Group>
                { ['GoogleMaps', 'Here WeGo', 'Openstreetmap', 'Bing'].map((map, index) => (
                    <Form.Radio 
                        name='mapPlatform'
                        key={index}
                        label={map}
                        value={map}
                        checked={config.orgaUser.Data.settings?.mapPlatform === map}
                        onChange={handleChange}
                    />
                ))}
            </Form.Group>    

            <Header as='h4'>{texts.MapLinkType}</Header>
            {texts.MapLinkTypeDescr}
            <Popup 
                disabled={config.orgaUser.Data.settings?.mapPlatform !== 'Openstreetmap'} 
                content={texts.MapLinkTypeNotAvailable}
                trigger={
                    <Form.Group>
                        {['Address', 'Route'].map((linkType, index) => (
                            <Form.Radio 
                                name='mapLinkType'
                                key={index}
                                label={texts[linkType]}
                                value={linkType}
                                checked={config.orgaUser.Data.settings?.mapLinkType === linkType}
                                disabled={config.orgaUser.Data.settings?.mapPlatform === 'Openstreetmap'}
                                onChange={handleChange}
                            />
                        ))}
                    </Form.Group>
                }
            />
           
        </Form>
    )
}

export default MapLinks

