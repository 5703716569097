import React,{useRef, useEffect}                from    'react'   
import { Menu}                                  from    'semantic-ui-react'
import reactToString                            from    "../../lib20/reactToString"
import { useConfig }                            from    '../../lib20/KPE20context'
import Addon                                    from    '../../lib/Addon'

const useContextMenu=(props)=>
{
    const {setModalParams,type}=props
    const config=useConfig()

    const addons = config.addons?.filter(a => a.listContext?.includes(type))
 


    const rowContextMenu=(e,row)=>
    {
                

        const data=row.getData()
        return addons.map(addon=>(
        {
            label:reactToString(
                <Menu.Item
                    icon={addon.icon}
                    content={addon.name}
                />),
            action: async (e,column)=>
            { 
                {
                    setModalParams({
                        addLevel:
                        {
                            component: Addon,
                            UID: data.UIDBelongsTo,
                            addon:addon,
                            closeIcon: true,
                            size: 'large'
                        
                        }
                    })
                }
            },
            types: addon.actionTypes ?? ['person','guest','extern','job'],
            channgeable: true
           
        }))
    }
    return rowContextMenu
}
export default useContextMenu