import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Table,Modal,Button,Input,Dropdown, List} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import {useCheckForm} from '@wollo-lib/wollo-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { useConfig } from '../../lib20/KPE20context';


const ExtraParameter=(props)=>
{
    const {children,resetModal, modalParams, modalSubmit}=props
    const {list,type,listOwner}=modalParams[0]
    const texts=useTexts('/edit/list')
    const [parameter,setParameter]=useState([])
    const [extraFields, setExtraFields] = useState([])
    const [selectedCol, setSelectedCol] = useState()
    const [newParameter,setNewParameter]=useState({name:null,type:'string'})
    const {apiInsert}=useApi()
    const config= useConfig()


    useEffect(()=>{
        if(list.extraParameter)
            setParameter([...list.extraParameter])
        if(list.extraFields)
            setExtraFields([...list.extraFields])
    },[list])


    const {errors,checkSubmit}=useCheckForm(parameter, [
        {name: 'UID', error: texts.errorType ,test:(val)=>{return parameter.name!==null && val!==null}}
    ])


    const dataTypes=[
        { key:'integer', value:'integer', text:texts.integerType},
        { key:'date', value:'date', text:texts.dateType},
        { key:'money', value:'money', text:texts.moneyType},
        { key:'string', value:'string', text:texts.stringType},
        { key:'bool', value:'bool', text:texts.boolType}
    ]
    const onChange=(el,{value,name,tname,index})=>
    {
        if(tname!=='extraFields')
        {
            setNewParameter(old=>({...old,[name]:value} ))
        }
        else if(value)
        {
            console.log(value)
            setSelectedCol(null)
            setExtraFields(old=>([...old,value] ))
        }
    }

    const clickDelete=(el,{index,tname})=>
    {
        if(tname!=='extraFields')
        {
            setParameter(old=>old.filter((p,i)=>i!==index))
        }
        else
        {
            setExtraFields(old=>old.filter((p,i)=>i!==index))
        }
    }
    modalSubmit.current=async ()=>
    {
        const paras=newParameter.name!==null ?[...parameter,newParameter] : parameter
        await apiInsert(`/kpe20/${type}/${listOwner.UID}`,{...list,extraParameter:paras,extraFields:extraFields})
        resetModal()
    }

    return(<>
    {

        !config.listExtraFields || config.listExtraFields.length===0 ? '' :
            <Modal.Content>
                <Modal.Header as='h3'>{texts.headerExtraFields}</Modal.Header>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell> {texts.extraParaName}</Table.HeaderCell>
                            <Table.HeaderCell>{texts.extraParaType}</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {
                        extraFields.map((para,Index)=>(

                    
                            <Table.Row>
                                <Table.Cell>
                                    {Index+1}
                                </Table.Cell>
                                <Table.Cell>
                                    {para.name}
                                </Table.Cell>
                                <Table.Cell>
                                    {dataTypes.find(t=>t.value===para?.type)?.text}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button 
                                        icon='trash'
                                        color='red'
                                        index={Index}
                                        onClick={clickDelete}
                                        tname='extraFields'
                                    />
                                </Table.Cell>
                            </Table.Row>
                            
                        ))
                    }
                    <Table.Row>
                                <Table.Cell>
        
                                </Table.Cell>
                            
                                <Table.Cell spawn={2}>
                                    <Dropdown
                                        placeholder={texts.extraCols}
                                        fluid
                                        selection
                                        options={config.listExtraFields ? config.listExtraFields.map(f=>({key: f.alias, text: f.name, value: f})): []}
                                        onChange={onChange}
                                        tname='extraFields'
                                        value={selectedCol}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                
                                </Table.Cell>
                            </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
        }
        <Modal.Content>
            <Modal.Header as='h3'>{texts.headerExtra}</Modal.Header>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell> {texts.extraParaName}</Table.HeaderCell>
                        <Table.HeaderCell>{texts.extraParaType}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                    parameter.map((para,Index)=>(

                   
                        <Table.Row>
                            <Table.Cell>
                                {Index+1}
                            </Table.Cell>
                            <Table.Cell>
                                {para.name}
                            </Table.Cell>
                            <Table.Cell>
                                {dataTypes.find(t=>t.value===para?.type)?.text}
                            </Table.Cell>
                            <Table.Cell>
                                <Button 
                                    icon='trash'
                                    color='red'
                                    index={Index}
                                    onClick={clickDelete}
                                    
                                />
                            </Table.Cell>
                        </Table.Row>
                        
                    ))
                }
                 <Table.Row>
                            <Table.Cell>
     
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    placeholder={texts.extraParaName}
                                    value={newParameter.name}
                                    onChange={onChange}
                                    name='name'
                                />
                                
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                    placeholder={texts.extraParaType}
                                    fluid
                                    selection
                                    options={dataTypes}
                                    value={newParameter.type}
                                    name='type'
     
                                    onChange={onChange}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Button 
                                    icon='plus'
                                    color='green'
                                    
                                    onClick={clickDelete}
                                    
                                />
                            </Table.Cell>
                        </Table.Row>
                </Table.Body>
            </Table>
            {children}
        </Modal.Content>




    </>)
}
export default ExtraParameter