import React, {useState, useEffect, startTransition}  from 'react'
import {Dropdown,Icon,  Modal, Header, Image, Tab, Menu} from 'semantic-ui-react'
import {useApi, useUser} from '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../lib20/useTexts'
import {useHistory} from 'react-router-dom'
import {useConfig} from '../../lib20/KPE20context'


const SubMenu=(props) => {
    const texts=useTexts('/show/person')
    const {UID,person,group,displayed,setDisplayed,modalParams,setModalParams,resetModal,timestamp, functions}=props
    const {apiInsert}=useApi()
    const config= useConfig()
    const addons = config.addons?.filter(a => a.menus?.includes('person/tabMenu'))

    const history = useHistory()

    const clickHandler=async (ev,{name})=>
    {
        startTransition(()=>{
            history.push(`/show/person/${name.slice('Person'.length).toLowerCase()}/${UID}`)
            // setDisplayed(name)
        })
    }

    const exit=async ()=>
    {
        if(window.confirm(texts.confirmExit))
        {
            const result=await apiInsert('/kpe20/extern/'+group.UID,{UID:UID,...person.Data})
            if(result.success)
            {
                window.alert(texts.exitConfirm)
            }
        }
    }

    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{timestamp:timestamp}})
        }
    },[timestamp])



    return(
    <>

                <Dropdown.Item 
                    onClick={clickHandler}
                    name='PersonMain'
                    active={displayed==='PersonMain'}
                    key='PersonMain'
                >
                                {texts.MenuPerson}
                </Dropdown.Item>
                <Dropdown.Item 
                    onClick={clickHandler}
                    name='PersonJobs'
                    active={displayed==='PersonJobs'}
                    key='PersonJobjs'
                >
                    {texts.MenuJobs}
                </Dropdown.Item>

                <Menu.Item 
                    onClick={clickHandler} 
                    name='PersonAchievements'
                    active={displayed==='PersonAchievements'}
                    key='PersonAchievements'
                >
                    {texts.MenuAchievements}
                </Menu.Item>
                <Menu.Item 
                    onClick={clickHandler} 
                    name='PersonLists'
                    active={displayed==='PersonLists'}
                    key='PersonLists'
                >
                    {texts.MenuLists}
                </Menu.Item>
                <Menu.Item 
                    onClick={clickHandler} 
                    name='PersonFamily'
                    active={displayed==='PersonFamily'}
                    key='PersonFamily'
                >
                    {texts.MenuFamily}
                </Menu.Item>
                {/* <Menu.Item 
                    onClick={clickHandler} 
                    name='History'
                    active={displayed==='PersonHistory'}
                    disabled
                    key='History'
                >
                    {texts.MenuHistory}
                </Menu.Item> */}
                <Menu.Item 
                    onClick={clickHandler} 
                    name='PersonFiles'
                    active={displayed==='PersonFiles'}
                    key='PersonFiles'
                >
                {texts.MenuFiles}
                </Menu.Item>
                {addons && addons.map((addon,index) => (
                    <Menu.Item
                        onClick={clickHandler} 
                        name={`Personaddon${index}`}
                        key={`addon${index}`}
                        active={displayed===`addon${index}`}
                    >
                    {addon.name}
                    </Menu.Item>
                ))}

                


        </>

    )

}  

export default SubMenu
