import React, {useEffect, useState} from 'react'
import {Form, Button, Segment, Icon, Dropdown, Flag, Modal} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../../lib20/useTexts'
import countries from '../../../../lib20/countries.json'
import {useConfig} from '../../../../lib20/KPE20context'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useCheckFormArray} from '../../../../Hooks/useCheckFormArray'
import {addressTypes} from './types.js'


const EditAddress =(props)=> {

    const {apiUpdate}=useApi()
    const config=useConfig()
    const texts=useTexts('/edit/address')
    const [address, setAddress]=useState([])
    const [familySaved,setFamilySaved]=useState(false)
    const {children, modalParams, resetModal, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]
    const [lang,countryOrga]=config.locale.split('-')
    const intlCompare=new Intl.Collator(lang).compare
    const countryOptions=countries.sort((first,second)=> (intlCompare(first[lang],second[lang]))).map(c=>({key:c.id,value:c.alpha2,text:c[lang], flag:c.alpha2}))


    const compareAdress=(a1,a2)=>(
        JSON.stringify(a1)===JSON.stringify(a2)
        
    )


    const addressOptions=Object.keys(addressTypes).map(option=>({
        key:option,
        icon:addressTypes[option],
        content:texts[option],
        value:option
    }))

    const {getError,checkSubmit}=useCheckFormArray(address,
        [
            {name:'type', error: texts.invalidAddressType,test:(val,index)=>(
                val!=='')},
            {name:'countryCode', error: texts.invalidAddressCounty,test:(val,index)=>(
                    val!=='')},
            {name:'city', error: texts.invalidCity, test:(val,index)=>(
               address[index] && address[index].city!='' && address[index].postcode !==''
            )},
        
        ]
    )
    modalSubmit.current= async ()=> {
        const family=address.find(p=>p.type==='family')  
        if(checkSubmit())
        {  
            if(!family || compareAdress(family,familySaved) || window.confirm(texts.ConfirmFamily) )
            {
                const result=await apiUpdate('/kpe20/person/'+UID,{address:address})
                {
                    if(result.success)
                        resetModal()
                }
            }
        }
    }


 

    const add=() => {
        setAddress((p) => ([...p, {type:'secondary', road:'', houseNumber:'', countryCode:countryOrga.toLowerCase(),  postcode:'', city:''}]))
    }

    const handleChange= (ev, {name, value, index}) => {

        if(name==='type') {

            if(value==='personal') {
             
                setAddress(old=>old.map((a,i)=>{
                 
                    {
                        if(i===index )
                            return {...a,type:'personal'}
                        else
                            return a
                    }
                }))
            }

            else if(value==='family') {

                // confirm change
              
                    // change the existing family address into personal or secondary and modify the new one
                    setAddress(old=>old.map((a,i)=>{
                            if(i==index)
                                return({...a,type:'family'})
                            else
                            {
                                return a
                            }
                        }
                    ))


            }

            else {

                setAddress((p) => (p.map((val, i) => (i===index ?  {...val,type:value}: val))))
            }

        }

        else if(name==='city') {

            if(value!==null) {

                const [zip,city]=value.split(':')
                setAddress((p) => (p.map((val, i) => (i===index ?  {...val,postcode:zip,city:city}: val))))
            }

            else {

                setAddress((p) => (p.map((val, i) => (i===index ?  {...val,postcode:'',city:''}: val))))                   
            }

        }

        else if(name==='primary') {

            setAddress(old =>[old[index],...old.filter((a,i)=>i!==index)])
        }

        else {
            
            setAddress((p) => (p.map((val, i) => (i===index ?  {...val,[name]:value}: val))))
        }
    }

    const remove=(e, {index:removeIndex}) => {
        setAddress(address.filter((address, index) => index !== removeIndex))
    }


    useEffect(() => {
        if(person && person.Data && person.Data.address)
        {
            const myAddress=[...person?.Data?.address]
            if(myAddress.length===0)
                myAddress.push({type:'secondary', road:'', houseNumber:'', countryCode:countryOrga.toLowerCase(),  postcode:'', city:''})
            setAddress(myAddress)
            const family=person.Data.address.find(p=>p.type==='family')
            setFamilySaved(family)
        }

    }, [person])




    return(<>

        <Modal.Header as='h3'>{texts.EditAddresses+person.Display}</Modal.Header>
        <Form as={Modal.Content} scrolling>
            {address.map((address, index) => (<>
                <Form as={Segment} widths={16}>
                    <h3 style={{marginBottom:'25px'}}>
                        <Form.Group>
                            <Dropdown 
                                value={address.type}
                                icon={addressTypes[address.type]} 
                                options={addressOptions} 
                                onChange={handleChange}
                                name='type'
                                index={index}
                                error={getError('type',index)}
                                pointing='top left'
                                button
                                basic
                                compact
                                width={3}
                            />
                            {/*<Form.Field width={10}/>*/}
                           
                            <Form.Checkbox
                                checked={index===0}
                                onClick={handleChange}
                                name='primary'
                                label={texts.PrimaryAddress}
                                active={index!==0}
                                disabled={index===0}
                                index={index} 
                                width={14} 
                            />
                            <Form.Button 
                                icon='trash' 
                                name={address} 
                                index={index} 
                                onClick={remove} 
                                floated='right' 
                                compact 
                                negative 
                            />
      
                        </Form.Group>
                    </h3>
                    <Form.Group >
                        <Form.Input
                            placeholder={texts.road}
                            label={texts.road}
                            value={address.road}
                            width={12}
                            name='road'
                            onChange={handleChange}
                            index={index}
                        />
                        
                        <Form.Input
                            placeholder={texts.houseNumber}
                            label={texts.houseNumber}
                            value={address.houseNumber}
                            width={4}
                            name='houseNumber'
                            onChange={handleChange}
                            index={index}
                        />
                    </Form.Group>
                    <Form.Input
                            placeholder={texts.road2}
                            label={texts.road2}
                            value={address.road2}
                            width={12}
                            name='road2'
                            onChange={handleChange}
                            index={index}
                        />
                    <Form.Group>
                        <Form.Dropdown
                            options={countryOptions}
                            placeholder={texts.countryCode}
                            label={texts.countryCode}
                            value={address.countryCode}
                            width={3}
                            name='countryCode'
                            onChange={handleChange}
                            index={index}
                            trigger={
                                <Flag name={address.countryCode}/>
                            }
                            icon=''
                            error={getError('countryCode',index)}
                            pointing='top left'
                            scrolling
                            button
                            basic
                            compact
                            search
                        />
                   
                    

            
                        <Form.Field
                            control={SearchSelect}
                            placeholder={texts.city}
                            label={texts.city}
                            value={address.postcode+':'+address.city}
                            width={13}
                            name='city'
                            onChange={handleChange}
                            index={index}
                            error={getError('city',index)}
                            api='kpe20'
                            SuggestUrl={'/kpe20/SearchData/places/'+address.countryCode}
                            Value2TitleUrl={'/kpe20/Value2Title/places/'+address.countryCode}
                        />
                    </Form.Group>
                    <Form.Input
                        placeholder={texts.neighbourhood}
                        label={texts.neighbourhood}
                        value={address.neighbourhood}
                        width={12}
                        name='.neighbourhood'
                        onChange={handleChange}
                        index={index}
                    />

                </Form>
            </>))}
            <Form.Button icon='add' content={texts.add} onClick={add} positive/>
            
        </Form>
        {children}
    </>)
}
export default EditAddress