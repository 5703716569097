import React, {useState, useEffect} from 'react'
import {Form, Modal, Item} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../lib20/useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'

const EditFamily =(props)=> {

    const {apiInsert,apiDelete}=useApi()
    const texts=useTexts('/show/person')
    const [famMember, setFamMember]=useState([])
    const {children, modalParams, resetModal, modalSubmit}=props
    const {UID,exitHook}=modalParams[0]
   
    modalSubmit.current=async ()=> {
        resetModal()
        const res=await apiInsert(`/kpe20/family/${famMember}/${UID}?rebate=true`,{empty:null})
        if(exitHook)
            exitHook()
        if(!res.success)
            window.alert('error update person: '+res.message)
        

    }

    const deleteFamily=async()=>
    {
        if(window.confirm(texts.confirmExitFamily))
        {
            resetModal()
            const res=await apiDelete('/kpe20/family/'+UID)
            if(!res.success)
                window.alert('error update person: '+res.message)
        }
    }

    const onChange=(ev,{value})=>{
        setFamMember(value)
    }

    return(<>

        <Modal.Header as='h3'>{texts.EditFamily}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Button
                        content={texts.ExitFamily}
                        icon='user'
                        onClick={deleteFamily}
                    />
                  <Form.Field
                    label={texts.ChangeFamily}
                    placeholder={texts.ChangeFamily}
                    control={SearchSelect}
                    api="kpe20"
                    SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['person'])}
                    Value2TitleUrl='/kpe20/Value2Title'
                    value={famMember}
                    autoSelect
                    onChange={onChange}

                    SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                />
                
            </Form>
        </Modal.Content>
        {children}
    </>)
}
export default EditFamily