import {useEffect, useState} from 'react'
import {Loader, Modal} from 'semantic-ui-react'

const Addon = (props) => {

    const {modalParams, resetModal} = props
    const {UID, addon}=modalParams[0]

    const [frame, setFrame] = useState(document.getElementById('addOn'))

    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        setFrame(frame ? frame.contentWindow : null)
    },[frame])
    
    
    useEffect(()=>
    {
        window.addEventListener("message", (ev) => {
        
            switch (ev.data.message) {
                case 'ready':
                    setLoading(false)
                    break
                case 'exit':
                    resetModal()
                    break
            }
        
        })
    },
    [])
    const callbackPara=addon?.callback ? `&callback=${addon.callback}` :''

    return (<>
        <Modal.Header as='h3'>{addon.name} </Modal.Header>
        <Modal.Content>
            {  !UID || !addon ? '' :
                <iframe
                    id='addOn' 
                    src={`${addon.url}?UID=${UID}${callbackPara}&origin=${window.location.origin}`} typename='false' active width='100%' height={window.innerHeight - 300}
                />
                    
            }
        </Modal.Content>
    </>)
}

export default Addon