import React,{ useState, useEffect, useRef }  from 'react';
import {Icon,Form,Popup,Button,Segment,Header, Loader, Message} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import {useWindowDimensions,useApi} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {bannerFormatter} from '../../lib/formatters'
import MyTabulator, {autoNumFormatter, autoNumEditFormatter, dateFormatter, dateEditor, formatMoney, editMoney, mutateMoney} from '@wollo-lib/wollo-tabulator'
import {useParams} from "react-router-dom";
import {SearchSelect, showDatum} from "@wollo-lib/wollo-react"
//import { SearchSelect } from '../../lib/SearchSelect';
import ModalExtraParameter from './ModalExtraParameter'
import ModalFilter from './ModalFilter'
import { useHistory } from 'react-router'
import { useConfig } from '../../lib20/KPE20context';
import useContextMenu from './useContextMenu'
import Addon from '../../lib/Addon'
import { getExtraCol, getExtraField, nameReplace } from './extraCols';








const ListResults=(props)=>
{
    const texts=useTexts('/edit/list')
    const {list,listData,setModalParams,listOwner, functions, achievements, tabRef, onScroll, totalCount, isLoading, fullRead}=props
    const {UID}= useParams()
    const {width,height}=useWindowDimensions()
    const [add,setAdd]= useState()
    const {apiInsert, apiDelete, apiUpdate}= useApi()
    const rowContextMenu= useContextMenu({setModalParams,type:'list'})
    const history=useHistory()
    const config=useConfig()
    const tabBuilt=useRef(false)
    const extraFields=useRef([])



    const addEntry=()=>
    {
        if(add)
        {
            const entry={UID:add}
            apiInsert(`/kpe20/list/person/${UID}`,entry,'kpe20')
            setAdd(null)
        }
    }

    
    

    useEffect(()=>
    {
        if(tabBuilt.current && list)
        {
            const extraCols=[]
            if( list.extraFields)
            {
                list.extraFields.forEach((para,Index)=>
                {
                    if(!extraFields.current.some(f=>f.alias===para.alias))
                        extraFields.current.push(para)
                    extraCols.push(getExtraField(para,texts.locale,texts.decimalPoint, texts.currencySymbol))
                })
            }
            if( list.extraParameter)
            {
                list.extraParameter.forEach((para,Index)=>
                {
                    extraCols.push(getExtraCol(para,texts.locale,texts.decimalPoint,texts.currencySymbol))
                })
            }
            tabRef.current.setColumns([...listColumns,...extraCols])
        }
      
    },[list, tabBuilt.current])
   

    const tabClicked=(cell)=>
    {
        let column=cell.getColumn()
        if(column.getField().match(/^__extra_.*/))
            return
        else
        {
            let row=cell.getRow()
            const rowData=row.getData()
            if(cell.getField()!=='UID')
            {
            
                const extraField= extraFields.current.find(f=>f.alias===column.getField())
                if(extraField?.urlPrefix)
                    window.open(extraField.urlPrefix+rowData[extraField.alias])
                else if (extraField?.addonUrl)
                {
                    {
                        setModalParams({
                            addLevel:
                            {
                                component: Addon,
                                UID: rowData.UIDBelongsTo,
                                addon:{
                                    UID: rowData.UIDBelongsTo,
                                    url: extraField.addonUrl
                                },
                                closeIcon: true,
                                size: 'large'
                            
                            }
                        })
                    }
                }
                else
                    history.push(`/show/person/main/${rowData.UIDBelongsTo}`)
    
                
            }
            else
            {
                if(window.confirm(texts.confirmDelete))
                {
                    apiDelete('/kpe20/list/person/'+UID,{UID:rowData.UID})
                }
            }
        }
    }

    var dateAccessor = function(value, data, type, params, column){
        return showDatum(value,{format:{year:"numeric", month:"2-digit", day:"2-digit", milliseconds:false, locale:texts.dateLocale}});
    }
    const listColumns=[
        { 
            title: texts.Name,
            frozen: true,
           
            columns: [
                {formatter:autoNumFormatter, width:50, hozAlign:"right", }, //incrementing row number
                {formatter:"buttonCross", width:30,field:'UID', hozAlign:"center", download:false},
                { title:  texts.FirstName,  minWidth: 90, field: 'firstName', maxWidth: 200,  resizable:true,  headerFilter:"input"},
                { title:  texts.LastName, minWidth: 90,  field: 'lastName', maxWidth: 200,   resizable:true,  headerFilter:"input"}
            ]
        },
        {formatter:bannerFormatter,  minWidth: 50, maxWidth: 60, hozAlign:"right", field:'banner',responsive:1, download:false},
        { title:  texts.MemberType, field: 'Title',  minWidth: 85, maxWidth: 150,  headerFilter:"input"},  
        { title:  texts.pGroup, field: 'pGroup',  minWidth: 250, headerFilter:"input"},
        {formatter: dateFormatter,
                formatterParams:{format:{year:"numeric", month:"2-digit", day:"2-digit", milliseconds:false, locale:texts.dateLocale}},
                accessorDownload : dateAccessor, 
                title: texts.Date, field: 'validFrom',  minWidth: 85, maxWidth: 120}
    ]


    const extraEdited=(cell)=>
    {
        const data=cell.getData()
    
        const entry={UID:data.UID}
        for (const d of Object.entries(data).filter(el =>el[0].match(/^__extra_.*/)))
        {
            entry[d[0].replace('__extra_','')]=d[1]
        }
        apiUpdate(`/kpe20/list/params/${UID}/${data.UID}`,entry)
    }



    const onKeyDown=(e)=>{
            if(e.keyCode === 13) 
                addEntry()
    }
   

    return(
    <>
        <Segment>
            {
                fullRead ?  '' :
                <Message warning>
                    {texts.notFullRead}
                </Message>
            }
            <Button color='green' 
                    content={texts.buttonExtra}
                    onClick={()=>setModalParams({addLevel:{list:list,type:'list',listOwner:listOwner,component:ModalExtraParameter}})}
            />
        
            <Form >
       
                <Form.Group onKeyDown={onKeyDown}>
                     <Form.Field
                        placeholder={texts.addEntry}
                        label={texts.addEntry}
                        SuggestUrl={'/kpe20/SearchData/person'}
                        Value2TitleUrl='/kpe20/Value2Title'
                        control= {SearchSelect}
                        width={12}
                        value={add}
                        onChange={(e,{value})=>setAdd(value)}
                        autoSelect
                    />
                    <Form.Button
                        icon='add'
                        color='green'
                        label='&nbsp;'
                        onClick={addEntry}
                    />
                    <Popup 
                                content={texts.addMulti} 
                                trigger={ <Form.Button
                                    color='green'
                                    fluid
                                    onClick={(ev)=>setModalParams({
                                        addLevel:
                                        {
                                            component: ModalFilter,
                                            functions: functions,
                                            achievements: achievements,
                                            UID:UID
                                        }

                                    })}
                                    label='&nbsp;'
                                >
                                    <Icon name='plus'/>
                                    <Icon name='users'/>
                                    <Icon name='filter'/>
     
                                </Form.Button>}
                        />

                </Form.Group>

            </Form>
            <Header as="h4">
            {
                        isLoading ? <Loader active inline size='small'/>
                        : <>{totalCount}&nbsp;{texts.entryCount}</>
                        
            }
            </Header>
            <MyTabulator
                index='UID'
                columns={listColumns}
                rowContextMenu= {rowContextMenu}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data={listData}
                height={Math.max(height-300,350)+'px'}
                compact="very"
                striped
                layout="fitColumns"
                
                replaceMode={true}
                onScroll={onScroll}
                tabRef={tabRef}
                on={[
                    {name:'cellEdited',function:extraEdited},
                    {name: 'tableBuilt', function: ()=>{
                        tabBuilt.current=true}
                    }
                ]}
                onClick={tabClicked}
                rowFormatter={(row)=>{
                    //row - row component
                    var data = row.getData();
                    if(data.UIDBelongsTo === config.orgaUser.UID){
                        row.getElement().style['background-color'] = '#FFFFb0';
                      

                    }
                }}
 
            />
 
        </Segment>

    </>
    )
}
export default ListResults