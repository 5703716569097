import React, {useState, useEffect}  from 'react'
import {Dropdown,Icon,  Modal, Header, Image, Tab} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'


const MenuEdit=(props) => {
    const texts=useTexts('/edit/list')
    const {shares,setModalParams,modalParams,timestamp,resetModal, admin}=props


    const clickHandler=async (ev,{name,size,closeIcon})=>
    {
        const module=await import('../'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size: size,
                closeIcon:closeIcon,
                ...props
            }
        })
    }

    // make shure, that changes to shares are passed through

    useEffect(()=>{
        if(modalParams.length>0 && modalParams[0].shares)
        {
            setModalParams({modify:{shares:shares,timestamp:timestamp,type:'email'}})
        }
    },[shares,timestamp])

    return(

        <Dropdown item simple text={texts.MenuEdit} >
            
            <Dropdown.Menu>
                { admin &&
                    <Dropdown.Item onClick={clickHandler} 
                        name='share/ModalShare' 
                        closeIcon size='medium'
                        key='emailEditMenuShare'
                    >
                            {texts.ModalShare}
                      
                    </Dropdown.Item>
                }
                </Dropdown.Menu>
        </Dropdown>

    )

}  

export default MenuEdit
