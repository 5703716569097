import React, {useState, useEffect}  from 'react'
import {Dropdown,Icon,  Modal, Header, Image, Tab} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'


const MenuEdit=(props) => {
    const texts=useTexts('/edit/list')
    const {isDesktop,shares,setModalParams,modalParams,timestamp,resetModal,objectActions,functions,achievements,actions}=props

    const clickHandler=async (ev,{name,size,closeIcon})=>
    {
        const module=await import('../'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size: size,
                closeIcon:closeIcon,
                functions,
                actions,
                achievements,
                objectActions,
                objectType:'dlist',
                ...props
            }
        })
    }

    // make sure, that changes to shares are passed through

    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{shares:shares,timestamp:timestamp,objectActions:objectActions}})
        }
    },[shares,timestamp,objectActions])

    useEffect(()=>{
        if(modalParams.length>0 )
        {
            setModalParams({modify:{objectActions:objectActions}})
        }
    },[objectActions])

    return(

        <Dropdown item simple={isDesktop} text={texts.MenuEdit} >
            <Dropdown.Menu>
                <Dropdown.Item 
                        onClick={clickHandler} 
                        name='share/ModalShare' 
                        closeIcon 
                        size='medium'
                        text= {texts.ModalShare}
                        key='dlistMenuShare'
                />
                <Dropdown.Item 
                    onClick={clickHandler} 
                    size='large' 
                    name='actions/Actions'
                    text={texts.Actions}
                    closeIcon
                    key='dlisMenuActions'

                />
            </Dropdown.Menu>
        </Dropdown>
        

    )

}  

export default MenuEdit
