import React from 'react'
import {Header,Form,Popup, Icon, List, Segment,Button,Label} from 'semantic-ui-react'
import ShowElement from '../lib20/FilterSources/ShowElement'


export const iconList={
    person:{icon: 'users', color: 'black'},
    guest:{icon:'user plus',color:'brown'},
    extern:{icon:'external',color:'blue'},
    job:{icon: 'sitemap', color:'green'},

}


const ShowSource=({texts,index,sources,copyFilter, editFilter, deleteFilter,achievements,functions})=>
{


    const source=sources[index]

    const cond= source.Data.person ? source.Data.person : source.Data.guest ? source.Data.guest : source.Data.extern ? source.Data.extern : source.Data.job ? source.Data.job : {}
    return(
    <>
        <List.Item key={source.UID+'Item'} >
            <Form.Group>
                <Popup
                    trigger={
                            <Button
                                icon='copy'
                                color='blue'
                                size='mini'
                                index={index}

                                onClick={copyFilter}
                            />}
                    content={texts.clipboardCopy}
                />
                <Label
                    icon='edit' 
                    size='medium' 
                    name={source.UID} 
                    onClick={editFilter}
                    as='a'
    
                >
                    
                    {
                        source.SourceType!=='list' ?
                        
                            Object.keys(iconList)
                                
                                .map((sourceType)=>
                            <Label.Detail style={{marginLeft:'0.3em'}}>
                            {   
                                source.Data[sourceType] ?
                                    <Icon 
                                        name={iconList[sourceType].icon}
                                        color={iconList[sourceType].color}
                                        
                                    />
                                :
                                
                                    <Icon 
                                        name={iconList[sourceType].icon}
                                        color='grey'
                                        
                                    />

                            }
                            </Label.Detail> )
                        :
                            <Label.Detail>
                                <Icon 
                                    name='users'
                                    color='green'
                                
                                />
                            </Label.Detail>

                    }
                    {`${source.Title} ${source.Display}`}
                    {
                        !source.Data.admin ?  ''  :
                        <Label.Detail>
                            <Icon name='edit outline'/>
                        </Label.Detail>
                    }
                     {
                        !source.Data.readOnly ? '':
                        <Label.Detail>
                            <Icon name='eye'/>
                        </Label.Detail>
                    }
                
                </Label>
            
                <Button
                    icon='trash'
                    color='red'
                    size='mini'
                    name={source.UID}
                
                    onClick={deleteFilter}
                />
               
            </Form.Group>
            <ShowElement
                value={cond}
                functions={functions}
                achievements={achievements}
                type={Object.keys(source.Data)}
                name={'d'+index}

            />
        </List.Item>

    </>)
   
}

export default ShowSource


