import React, {useEffect, useState }  from 'react'
import useTexts from '../../lib20/useTexts'
import {SearchSelect, showDatum, useCheckForm} from '@wollo-lib/wollo-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {Icon,Header,Segment,Form,Popup,Label,Loader, Button} from 'semantic-ui-react'
import ModalFilter from './ModalFilter'
import {useConfig} from '../../lib20/KPE20context'

const Recipient = (props) => {
    const texts=useTexts('/edit/email')
    const config=useConfig()
    const {recipient, index, added, writeable, UID} = props
    const {apiSelect,apiDelete, apiInsert}=useApi()

    
    const deleteEntry=async (ev,{uidperson}) => {
      
            const result=await apiDelete(`/kpe20/email/person/${UID}`,{UID:uidperson})
    }

    const tzOffset=new Date().getTimezoneOffset()

    return(
        <Popup
            trigger={
                <Label 
                    size='small' 
                   
                    basic={added.findIndex(el=>el.UID===recipient.UID)!==-1 && recipient.status==='added'}
                    color= {recipient.Data.status==='added' ? null : recipient.Data.status==='sent' ? 'green' : 'red'}
                    style={{marginTop:'3px'}}
                >
                    <span>
                        {recipient.MemberData.firstName}&nbsp; 
                        {recipient.MemberData.lastName}
                        &nbsp;&lt;{recipient.MemberData.email && recipient.MemberData.email[0] && recipient.MemberData.email[0].email}&gt;&nbsp;
                    </span>
                    {(recipient.Data.status==='added' && writeable) &&
                        <Icon  name='close' uidperson={recipient.UID} onClick={deleteEntry}/>
                    }
                </Label>
            }
            content={recipient.Data.status==='added' ? 
                texts.stateAdded : recipient.Data.status==='sent' ? 
                texts.stateSent + recipient.Data.address+' '
                    +showDatum(recipient.Data.timestamp/1000 -tzOffset*60,{locale:config.locale,year:"numeric",month:"2-digit", day:"2-digit",hour:"2-digit",minute:"2-digit"}) 
                : texts.stateError + 
                    recipient.Data.errors && Array.isArray(recipient.Data.errors) &&
                        recipient.Data.errors.map(e=>texts.mailErrors[e]).join(' ')}
            disabled={recipient.Data.status === 'error' && recipient.Data.errors[0] === 'other'}

        />
    )
}

const RecipientList = (props) => {
    const {UID,timestamp,recipients,setRecipients, isLoading, setIsLoading,
         deleted, setDeleted, added, setAdded, functions, achievements, 
         setModalParams, writeable}=props
    const {apiSelect,apiDelete, apiInsert}=useApi()
    const texts=useTexts('/edit/email')

    const [showRecipients,setShowRecipients] = useState([]) // ({})
    const [showOrder,setShowOrder] = useState(['error', 'sent', 'added'])

    useEffect(()=>{
        const getRecipients=async()=>
        {
            setIsLoading(true)
            const result=await apiSelect('/kpe20/email/persons/'+UID)
            if(result.success )
            {
                setRecipients(result.result)
            }
            setIsLoading(false)
        }
        if(UID && UID!=='new')
            getRecipients()
    },[UID,timestamp])


    useEffect(() => {
        setIsLoading(true)

        // setShowRecipients({
        //     error:[...recipients.filter(r => (r.Data.status !== 'sent' && r.Data.status !== 'added'))],
        //     sent:[...recipients.filter(r => r.Data.status === 'sent')],
        //     added:[...recipients.filter(r => r.Data.status === 'added')]
        // })


        setShowRecipients([
            ...recipients.filter(r => (r.Data.status !== 'sent' && r.Data.status !== 'added')),
            ...recipients.filter(r => r.Data.status === 'sent'),
            ...recipients.filter(r => r.Data.status === 'added')
        ])

        setIsLoading(false)
    },[recipients])

    const addEntry=async (ev,{value})=>
    {
       
        if(value)
        {
            const result=await apiInsert(`/kpe20/email/person/${UID}`,{UID:value},'kpe20')
        }

    }
    const undoDelete=async ()=>
    {
        if(deleted)
        {
            await apiInsert(`/kpe20/email/person/${UID}`,deleted,'kpe20')
        }
    }

    const undoAdd=async ()=>
    {
        if(added)
        {
            apiDelete(`/kpe20/email/person/${UID}`,added,'kpe20')
            setAdded([])
        }
    }

     


    return(
        <Segment secondary>
            <Header as='h4'>
                {texts.to}

                <Label circular>{recipients.length}</Label>

            </Header>
            
            <Segment.Group>
                <Segment secondary className='recipientList'>
                    <Loader inline active={isLoading}/>

                    {/* {showOrder.map(status => {
                        showRecipients[status]?.map((recipient,index)=>(

                            <Recipient recipient={recipient} index={index} {...props} /> 
                        ))
                    })}  */}

                    {showRecipients.map((recipient,index)=>(
                        <Recipient recipient={recipient} key={recipient.UID} {...props} /> 
                    ))}
                </Segment>
                {/* <div className='resizer' /> */}

                {writeable &&
                    <Segment tertiary key='1'>
                        <Form>
                            <Form.Group widths='equal'>
                                <Form.Field
                                    icon='user plus'
                                    placeholder={texts.addEntry}
                                    SuggestUrl={'/kpe20/SearchData/person'}
                                    Value2TitleUrl='/kpe20/Value2Title'
                                    control= {SearchSelect}
                                    width={12}
                                    onChange={addEntry}
                                    autoSelect
                                    autoClear

                                />
                                <Popup 
                                    content={texts.addMulti} 
                                    trigger={ <Form.Button
                                        color='green'
                                        fluid
                                        onClick={(ev)=>setModalParams({
                                            addLevel:
                                            {
                                                component: ModalFilter,
                                                functions: functions,
                                                achievements: achievements,
                                                UID:UID,
                                                setRecipients:setRecipients,
                                                size:'large'
                                            }

                                        })}
                                    >
                                        <Icon name='plus'/>
                                        <Icon name='users'/>
                                        <Icon name='filter'/>

                                    </Form.Button>}
                                />
                                <Form.Group attached widths='equal'>
                                    <Popup 
                                        content={texts.undoAdd} 
                                        trigger={ <Form.Button
                                            disabled={added.length===0}
                                            fluid
                                            onClick={undoAdd}
                                        >
                                            <Icon name='undo'/>
                                            <Icon name='plus'/>
                                        </Form.Button>}
                                    />
                                    <Popup 
                                        content={texts.undoDelete} 
                                        trigger={ <Form.Button
                                            disabled={deleted.length===0}
                                            fluid
                                            onClick={undoDelete}
                                        >
                                            <Icon name='undo'/>
                                            <Icon name='trash'/>
                                        </Form.Button>}
                                    />
                                </Form.Group>
                            
                            
                            
                            </Form.Group>
                        
                        </Form>
                    </Segment>
                }
                
                {/* </Accordion.Content> */}

            </Segment.Group>
        </Segment>
    )
}
export default RecipientList