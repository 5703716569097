import React,{useState, useEffect}  from 'react';
import {  Modal, Form, Segment}  from 'semantic-ui-react'
import { useConfig, useSetConfig } from '../lib20/KPE20context';
import useTexts from '../lib20/useTexts'
import useCheckForm from '../Hooks/useCheckForm';
import {useApi} from '@wollo-lib/kpe-context'
import addressFormatter from '@fragaria/address-formatter'
import countries from '../lib20/countries.json'
import jsPDF from 'jspdf'



const MyInput=(props)=>
{
    const {name,def}=props
    const {values,handleChange,errors}=def
    const texts=useTexts('OutputMenu')
    return <Form.Input 
        label={texts[name]}
       /* placeholder={texts[name]}*/
        name={name}
        value={values[name]}
        onChange={handleChange}
        error={errors[name]}
    />

}


const Labels=(props)=>{
  
    const {children,setModalParams, resetModal, modalParams, modalSubmit,modalDefaults}=props
    const {tabRef, list, types}=modalParams[0]
    const texts=useTexts('OutputMenu')
    const {apiUpdate}=useApi()
    const config=useConfig()
    const setConfig=useSetConfig()
    const [data,setData]=useState([])
    const [lang,countryOrga]=config.locale.split('-')


    const [labelSettings,setLabelSettings]=useState({
        pageWidth: 210,
        pageHeight: 297,
        marginTop: 4.5,
        marginBottom: 4.5,
        marginLeft:0,
        marginRight:0,
        noOfCols: 2,
        noOfRows: 6,
        lbMarginLeft: 10,
        lbMarginTop: 3,
        withSender: false,
        sender: '',
        extra: [],
        extraOptions: ['Anzahl','Zusatz']

    })

    useEffect(()=>{
        const loadFonts=async ()=>
        {
            const fonts=await import('./myFonts')
            jsPDF.API.events.push(["addFonts", 
                 fonts.callAddFont]);
        }
        loadFonts()
    },[])

    useEffect(()=>{
        if(config.orgaUser.Data.settings?.labelSettings)
        {
            setLabelSettings({...config.orgaUser.Data.settings.labelSettings, 
                extraOptions:list && list.extraParameter  ? list.extraParameter.map(p=>p.name) : []})
        }
    },[config])


    useEffect(()=>{
        const getData=async()=>
        {
            const UIDs=tabRef.current.getData().map(o=>o.UID)
            let result 
            if(list)
               result= await apiUpdate('/kpe20/persons?Data=all&ExtraData=1&type=["entry"]',UIDs)
            else 
                result=await apiUpdate(`/kpe20/persons?Data=all&type=${JSON.stringify(types)}`,UIDs)
            if(result.success)
                setData(result.result)
        }
        getData()
    },[])
   
    const getCountryFromCode=(code)=>
    {
        if(code)
        {
            const country=countries.find(c=>c.alpha2===code)
            return country ? country[lang] : null
        }
        else
            return null
    }

    const genPages=async ()=>
    {

        const join=(aText,jString)=>
        {
            return aText.reduce((res,current)=>(current && current!=='' ? res==='' ? current : res+jString+current : res),'')
        }

        const doc=new jsPDF({
            orientation:'p',
            format:[parseFloat(labelSettings.pageWidth), parseFloat(labelSettings.pageHeight)],
            unit: 'mm',
            putOnlyUsedFonts:true
        })
       
       

        const formatAddress=(a)=>
        {
            if(a)
            {
                const paddress={
                    "houseNumber":a.houseNumber,
                    "road": a.road,
                    "neighbourhood": a.road2,
                    "city": a.city,
                    "postcode": a.postcode,
                    "countryCode": a.countryCode,
                    
                }
                if(a.countryCode !==lang)
                    paddress.country=getCountryFromCode(a.countryCode)
                return addressFormatter.format(
                    paddress,
                    {
                        output: 'array'
                    })
            }
            else
                return []

        }
        //doc.addPage([labelSettings.pageWidth,labelSettings.pageHeight],'p')
        const labelHeight=(parseFloat(labelSettings.pageHeight)-parseFloat(labelSettings.marginTop)-parseFloat(labelSettings.marginBottom))/parseInt(labelSettings.noOfRows)
        const labelWidth=(parseFloat(labelSettings.pageWidth)-parseFloat(labelSettings.marginLeft)-parseFloat(labelSettings.marginRight))/parseInt(labelSettings.noOfCols)


        const getLabel=(startX,startY,person)=>{

            if(labelSettings.withSender)
            {
          //      doc.setFont("fontSerif");
                doc.setFontSize(8)
                doc.setLineWidth(labelWidth)
                doc.text(labelSettings.sender,
                    startX+7,
                    startY+parseFloat(labelSettings.lbMarginTop),
                    {
                        baseline: 'hanging',
                        maxWidth: labelWidth-14
                    }
                )
            }

            doc.setFont('fontSans')
            doc.setFontSize(10)
            doc.setLineWidth(labelWidth+15)
            const name=[]
            if( person.Data.greeting &&  person.Data.greeting!=='')
                name.push(person.Data.greeting)
            name.push( join([person.Data.title,person.Data.firstName,person.Data.lastName],' '))
            const address=formatAddress(person.Data.address[0])
            doc.text([  
                        ...name,
                        ...address
                    ],
                    startX+parseFloat(labelSettings.lbMarginLeft),
                    startY+15,
                    {
                        baseline: 'hanging',
                        maxWidth: labelWidth-15
                    }
                )
            if(list && labelSettings.extra && labelSettings.extra.length>0)
            {
                const bottomY= startY+ labelHeight -12
                doc.setFont('fontSerif')
                doc.setFontSize(8)
                doc.setLineWidth(50)
                doc.text(labelSettings.extra.map(p=>(`${p}:${person.ExtraData[p.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')]}`)).join('  '),
                    startX+7,
                    bottomY,
                    {
                        baseline: 'hanging',
                        maxWidth: labelWidth-12
                    }
                )
            }
    
        }
        let rowNo=0
        let colNo=0
        for(let person of data)
        {
            getLabel(
                parseFloat(labelSettings.marginLeft)+labelWidth*colNo,
                parseFloat(labelSettings.marginTop)+labelHeight*rowNo,
                person
            )
            ++colNo
            if(colNo>=parseInt(labelSettings.noOfCols))
            {
                colNo=0
                ++rowNo
                {
                    if(rowNo>=parseInt(labelSettings.noOfRows))
                    {
                        rowNo=0
                        doc.addPage([parseFloat(labelSettings.pageWidth),parseFloat(labelSettings.pageHeight)],'p')
                    }
                }
            }
        }
        
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
        // safe codeStart for next generation sequence  
   }


   modalSubmit.current=async (event)=> {
        if(!checkSubmit())
            return
        console.log('saving')
        setConfig({...config,orgaUser:{...config.orgaUser,Data: {...config.orgaUser.Data, settings:{...config.orgaUser.Data.settings ??{}, labelSettings:labelSettings }}}})
        await apiUpdate('/kpe20/person/'+config.orgaUser.UID,
            {settings:{...config.orgaUser.Data.settings ?? {}, labelSettings:labelSettings}})
        genPages()
        resetModal()
        
   }

   const {errors,checkSubmit}=useCheckForm(labelSettings,[
        {name: 'pageWidth', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'pageHeight', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'marginTop', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'marginBottom', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'marginLeft', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'marginRight', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'noOfCols', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'noOfRows', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'lbMarginLeft', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
        {name: 'lbMarginTop', error: texts.errorProvided, test: (val)=>(!isNaN(val) ) },
   ])

   



    const handleAddition = (e, { value }) => {
        setLabelSettings((p) => ({...p,extraOptions:
            [...p.extraOptions,value]
        }))
    }

    const onClick=(e)=>
    {
        setLabelSettings(p=>({...p,withSender:!p.withSender}))
    }

    const handleChange= (e,{value,name})=>
    {
        setLabelSettings((p)=>({
            ...p,[name]:value,
            withSender: name==='sender' ? value!=='' : p.withSender
        }))
        

            
    }

    const def={values:labelSettings,errors,handleChange}

    return(
    <>
        <Modal.Content>
            <Form>
                <Modal.Header as='h3' content={texts.LabelHeader}/>
                <Segment>
                    <Modal.Header as='h4' content={texts.LabelPageSettings}/>
                    <Form.Group>
                        <MyInput name='pageWidth' def={def}/><MyInput name='pageHeight'  def={def}/>        
                    </Form.Group>
                    <Form.Group>
                        <MyInput name='marginTop' def={def}/><MyInput name='marginBottom' def={def}/>        
                    </Form.Group>
                    <Form.Group>
                        <MyInput name='marginLeft' def={def}/><MyInput name='marginRight' def={def}/>        
                    </Form.Group>
                    <Form.Group>
                        <MyInput name='noOfCols' def={def}/><MyInput name='noOfRows' def={def}/>        
                    </Form.Group>
                </Segment>
                <Segment>
                    <Modal.Header as='h4' content={texts.LabelSettings}/>
                    <Form.Group>
                        <MyInput name='lbMarginLeft' def={def}/><MyInput name='lbMarginTop' def={def}/>        
                    </Form.Group>
                   
                        <MyInput name='sender' def={def} fluid/>
                        <Form.Checkbox toggle label={texts.withSender} checked={labelSettings.withSender} onClick={onClick} name='withSender'/>


                    <Form.Dropdown
                        options={labelSettings.extraOptions.map(e=>({key:e,text:e,value:e}))}
                        placeholder={texts.listParameter}
                        label={texts.listParameter}
                        search
                        selection
                        fluid
                        multiple
                        allowAdditions
                        value={labelSettings.extra}
                        onAddItem={handleAddition}
                        onChange={handleChange}
                        name='extra'
                        error={errors['extra']}
                    />
                </Segment>
            </Form>
        </Modal.Content>
        {children}
    </>
   )





   
   
   

}
export default Labels

