import React, {useState, useEffect}  from 'react'
import {Dropdown,  Menu, Image, Icon, Flag, Sidebar, Segment, Divider, Label, DropdownDivider} from 'semantic-ui-react'
import {ReactComponent as Logo} from '../css/LilieWhite.svg'

import {useHistory, useLocation} from 'react-router-dom'
//import {SearchSelect} from '@wollo-lib/wollo-react'
import ModalRail from '../lib20/Rails/ModalRail'
import {useWindowDimensions, useApi, useUser} from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../lib20/useTexts'
import {useRoot, useConfig, useWsStatus, useFakeUser} from '../lib20/KPE20context'
import {baseUrl} from '../config.jsx'

import DropdownModalNew from './DropdownModalNew'
import SearchItem from './SearchItem'
import AvatarMenu from '../lib20/Menu/AvatarMenu'
import MenuHelp from '../lib20/Menu/MenuHelp'
import ChangeLog, {NewVersion} from '../lib20/Changelog/Changelog'
import EditGroup from '../show/group/modals/ModalEditGroup'
import GuestGroup from '../edit/GuestGroup'
import EditPerson from '../show/person/modals/ModalEditPerson'
import EditJob from '../edit/Job'
import EditGuest from '../edit/Guest'
import EditList from '../edit/ModalLists'
import EditAchievement from '../edit/EditPersonAchievement'
import ConnectionStatus from '../lib20/Menu/ConnectionStatus'
import SystemMessage from '../lib20/SystemMessage'

import {version} from '../config'


const MenuItems=(props) => {
    const history=useHistory()
    const texts=useTexts('Menu')
    const config=useConfig()


    const {setSidebarVisible,functions,achievements,setModalParams,isAdmin,extraMenu,isDesktop,tabRef}=props

 
    const onClick=(ev,{name})=>
    {
        history.push(name)
        setSidebarVisible(false)
    }

    
        const newMenu=
        
            <Dropdown item simple={isDesktop} text={texts.New} key='new'>
                <Dropdown.Menu key={'MenuNew'}>

                    <DropdownModalNew
                        component={EditPerson}
                        key={'member'}
                        text={texts.Member}
                        setModalParams={setModalParams}
                        modalProps={{Type:'person'}}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemMember'}
                        icon='user'
                    />
                    <DropdownModalNew
                        component={EditPerson}
                        key={'extern'}
                        text={texts.Extern}
                        setModalParams={setModalParams}
                        modalProps={{Type:'extern'}}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemExtern'}
                        icon='external'
                    />

                    <DropdownModalNew
                        component={EditGuest}
                        key={'guest'}
                        text={texts.Guest}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemGuest'}
                        icon='user plus'
                    />

                    <DropdownModalNew
                        component={EditJob}
                        key={'job'}
                        text={texts.Job}
                        setModalParams={setModalParams}
                        modalProps={{functions}}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemJob'}
                        icon='sitemap'
                    />
                    
                    <DropdownModalNew
                        component={EditAchievement}
                        key={'achievement'}
                        text={texts.Achievement}
                        modalProps={{achievements}}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemAchievement'}
                        icon='file pdf'
                    />  
                    <Dropdown.Divider key='divn1'/>

                    <DropdownModalNew
                        component={EditGroup}
                        key={'group'}
                        text={texts.Group}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemGroup'}
                        icon='user circle'
                    />

                    <DropdownModalNew
                        component={GuestGroup}
                        key={'guestgroup'}
                        text={texts.GuestGroup}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemGGRoup'}
                        icon='user circle outline'
                    />

                    <Dropdown.Divider key='divn2'/>

                    <DropdownModalNew
                        component={EditList}
                        key={'list'}
                        text={texts.List}
                        modalProps={{
                            type:'list',
                            tabRef
                        }}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        myKey={'ItemList'}
                        icon='ordered list'
                    />   

                    <DropdownModalNew
                        component={EditList}
                        key={'dlist'}
                        myKey={'ItemDlist'}
                        text={texts.DList}
                        modalProps={{
                            type:'dlist'
                        }}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        icon='database'
                    />  

                    <DropdownModalNew
                        component={EditList}
                        key={'email'}
                        text={texts.EMail}
                        modalProps={{
                             type:'email',
                             tabRef
                        }}
                        myKey={'ItemEmail'}
                        setModalParams={setModalParams}
                        setSidebarVisible={setSidebarVisible}
                        icon='at'
                    />

                  

                </Dropdown.Menu>
            
            </Dropdown> 

          const myExtraMenu=
                extraMenu.map((el,Index)=>{

                    const MyMenu=el.menu
                        if(el && (!el.admin || isAdmin))
                            return(                      
                                <MyMenu 
                                    {...props}
                                    {...el.props}
                                    isDesktop={isDesktop}
                                    setSidebarVisible={setSidebarVisible}
                                />)
                        else
                            return ''
                })
             
            
           
             

        
    return [newMenu,...myExtraMenu]

}


const MainMenu=(props) => {

    const history=useHistory()    
    const config=useConfig()
    const user=useUser()
    const {fakeUser}=useFakeUser()
    const fakeUserMenuColor = 'orange'


    const texts=useTexts('Menu')
    const {setModalParams}=props    
    const [sidebarVisible, setSidebarVisible]=useState(false)
    const [changelogVisible, setChangelogVisible] = useState(false)


    const windowWidth=useWindowDimensions().width
    const [isDesktop, setDesktop]=useState(true)
    useEffect(()=>{
        windowWidth< 800 ? setDesktop(false) : setDesktop(true)
    }, [windowWidth])

    const isTouch= 
        ( 'ontouchstart' in window ) ||
               ( navigator.maxTouchPoints > 0 ) ||
               ( navigator.msMaxTouchPoints > 0 );
    

    return (<>

        <ChangeLog visible={changelogVisible} setVisible={setChangelogVisible} />
        <NewVersion/>
        <div style={{marginBottom:'20px'}}>
            {
                isDesktop ? 
                
                        <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted  key='MainMenuMenu'>
                            <Menu.Item
                                key='logo'
                                onClick={el=>{history.push('/')}}
                            >
                                <Logo width={35} height={35}/>
                                <ConnectionStatus key='ConnectionStatus' className='desktop' />
                            </Menu.Item>

                            <MenuItems {...props} setSidebarVisible={setSidebarVisible} isDesktop={true} key='MainMenuItems'/>
                            <MenuHelp
                                setModalParams={setModalParams}
                                setSidebarVisible={setSidebarVisible}
                            />
                            <SearchItem {...props} searchWidth='400px' key='MainSearch'/>
                            
                            <Dropdown icon={null} item direction='left' key='avatar' trigger={
                                config?.orgaUser?.Data?.avatar 
                                ? <Image src={config.orgaUser.Data.avatar} circular size='mini' />
                                : <Icon name='user' circular key='IconUser'/>
                            }>
                                <Dropdown.Menu >
                                    <Menu.Header
                                        as="a"
                                        style={{textAlign:'center'}}
                                        onClick={()=>{history.push(`/show/person/main/${config.orgaUser.UID}`)}}
                                    >
                                    {config.orgaUser.Data.firstName} {config.orgaUser.Data.lastName}
                                            
                                    </Menu.Header>
                                    <p style={{color:'grey', fontSize:'70%', textAlign:'center'}}>{config.AppName} v{version}</p>
                                    <AvatarMenu {...props} setSidebarVisible={setSidebarVisible} setChangelogVisible={setChangelogVisible} isDesktop={true} windowWidth={windowWidth} setModalParams={setModalParams}/>
                                </Dropdown.Menu>
                            </Dropdown>

                        </Menu>
                        

                :
                    <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted >
                        <Dropdown item icon='bars' onClick={e=>setSidebarVisible(status=>!status)} direction='left' key='MainBars' >
                            <ConnectionStatus className='mobile'/>
                        </Dropdown>
                        <Sidebar visible={sidebarVisible} 
                            onHide={() => setSidebarVisible(false)}
                            animation='overlay'
                            as={Segment} color={fakeUser ? fakeUserMenuColor : 'blue'} inverted vertical key='SideBar'
                        >
                            
                            <Menu.Item 
                                header 
                                key='Sideuser'
                            >
                                <a onClick={() => { 
                                    setSidebarVisible(false)
                                    history.push(`/show/person/main/${config.orgaUser.UID}`)
                                }} >
                                    <Icon name='user' style={{textAlign:'right'}}/>
                                    {`${config.orgaUser.Data.firstName} ${config.orgaUser.Data.lastName}`}
                                </a> 
                                <Icon name='close' onClick={()=>setSidebarVisible(false)}  style={{position:'absolute',right:'20px'}}/>
                                
                            </Menu.Item>
                            <Divider key='SideDiv1'/>
                            <p style={{fontSize:'70%', marginLeft: '20px'}}>{config.AppName} v{version}</p>
                            <AvatarMenu {...props} isDesktop={false} setSidebarVisible={setSidebarVisible} setChangelogVisible={setChangelogVisible} windowWidth={windowWidth}/>
                            <MenuHelp
                                setModalParams={setModalParams}
                                setSidebarVisible={setSidebarVisible}
                            />
                            <Divider />
        
                            <MenuItems setSidebarVisible={setSidebarVisible} {...props} isDesktop={false}/>
                        
                        </Sidebar>
                        <SearchItem {...props}  searchWidth='260px' />              

                    </Menu>
            }
            <SystemMessage/>
        </div>


    </>)
}
export default MainMenu
