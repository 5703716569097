import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Loader,Form,Modal,Segment,Icon,Checkbox} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import {SearchSelect} from '@wollo-lib/wollo-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
//import {usePageSelect,useWindowDimensions} from '@wollo-lib/kpe-context'
import {usePageSelect,useWindowDimensions} from '@wollo-lib/kpe-context'
import {bannerFormatter} from '../../lib/formatters'

import MyTabulator, {autoNumFormatter, autoNumEditFormatter} from '@wollo-lib/wollo-tabulator'
import { iconList } from '../ShowSource';
import FilterElement from '../../lib20/FilterSources/FilterElement'


const CheckboxType=({type,texts,changeType,types})=>
(
    <Form.Field   
        label={<label><Icon name={iconList[type].icon} color={iconList[type].color}/> {texts[type+'Source']}</label>}
        name={type} 
        checked={types[type]} 
        onClick={changeType} 
        toggle
        control={Checkbox}
    />
)


const EditSource=(props)=>
{


    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'}
    ])
    const {width,height}=useWindowDimensions()
    const texts=useTexts('/edit/dlist/modal')
        
    const [sourceType, setSourceType]=useState('group')

    const {children,resetModal,modalSubmit,modalParams}=props
    const {createNew,UID,functions,achievements,filterType,filterUID,setFilterUID}=modalParams[0]
  
    const [filterState,setFilterState]=useState({
                                                    filterSource:null,
                                                    filterAlgo:{},
                                                    types: {person:true, guest: true, extern : false, job:false}
                                                 })

    const [members,setMembers]=useState([])


    const [timeStamp,setTimeStamp]=useState(Date.now())
    const {apiSelect, apiInsert, apiDelete}=useApi()


    useEffect(()=>{
        const getFilter=async ()=>
        {
            const filter= await apiSelect(`/kpe20/filter/${filterUID}`)
            console.log(filter)
            if(filter.success)
            {
                    const myTypes={
                        person: filter.result.Data.person!== undefined,
                        guest: filter.result.Data.guest!== undefined,
                        extern: filter.result.Data.extern!== undefined,
                        job: filter.result.Data.job!== undefined
                }
                const algo=filter.result.Data[Object.keys(filter.result.Data).filter(el=>el!=='UID')[0]]
                setFilterState({
                    filterSource: filter.result.UIDBelongsTo,
                    filterAlgo:algo,
                    types:myTypes
                })
                setSourceType(filter.result.SourceType)
            }
        }
        if(filterUID && !createNew && filterType)
            getFilter()
    },[filterUID,createNew])

  
    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo

    })

    
    const ajaxParams={
        Timestamp:timeStamp,
        type:sourceType==='group' ? JSON.stringify(Object.keys(filterState.types).filter(el=>(filterState.types[el]))) : ['entry'],
        grouped:0,
        Data:jsonFields,
        dataFilter: JSON.stringify(algo)
    }

    const {onScroll,isLoading,totalCount}=usePageSelect({
            select:filterState.filterSource ? `/kpe20/persons/${filterState.filterSource}` : null,
            urlPara:ajaxParams, 
            setData:setMembers,
            pageSize:50, 
            clientFilter:true
        })
  
    modalSubmit.current=async ()=>
    {
      
        const result = await apiInsert(`/kpe20/filter/${filterState.filterSource}/${UID}/${filterType}`,algo)
        if(!result.success)
            window.alert('Insert filter error: '+result.message)
        if(filterUID)
        {
            const result=await apiDelete(`/kpe20/filter/${filterUID}`)
            if(!result.success)
                window.alert('Delete filter error: '+result.message)
        }
        resetModal()
    }

    
    const changeType=(ev,{name})=>
    {
        setFilterState(old=>({...old, types:{...old.types,[name]:!old.types[name]}}))
    }

    const handleSourceChange=(ev,{value,result})=>
    {   
        
        if(sourceType!==result.type)
        {
            if(result.type==='group')
                setFilterState(old=>({...old, types: {person:true, guest: true, extern : false, job:false}}))
            else
                setFilterState(old=>({...old, types: {person:true, guest: true, extern : true, job:true}}))
        }
        setSourceType(result.type)
        setFilterState(old=>({...old,filterSource:value})) 
    }

    const changeAlgo=(ev,{value})=>
    {
        setFilterState(old=>({...old,filterAlgo:value}))
    }


    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, hozAlign:"right", frozen:true, field:'UID'}, //incrementing row number
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner'},
        { title:  texts.MemberType, field: 'Title', width: 150,  headerFilter:"input"},        
        { title:  texts.FirstName, field: 'firstName', width: 120,  headerFilter:"input"},
        { title:  texts.LastName, field: 'lastName', width: 120,  headerFilter:"input"},
        { title:  texts.pGroup, field: 'pGroup',   headerFilter:"input"},
        { title:  texts.Age, field: 'dindex',  width:90, headerFilter:"input"}
    ]

    return(
    <>
        <Segment>
            <Modal.Header as='h3' content={filterUID==='new'? texts.EditSourcesHeaderNew : texts.EditSourcesHeader}/>
            <Modal.Content>

                <Form>
                    { sourceType==='group' &&
                        
                        <Form.Group stackable>
                            <CheckboxType
                                type='person'
                                types={filterState.types}
                                changeType={changeType}
                                texts={texts}      
                            />
                            <CheckboxType
                                type='guest'
                                types={filterState.types}
                                changeType={changeType}
                                texts={texts}      
                            />
                            <CheckboxType
                                type='extern'
                                types={filterState.types}
                                changeType={changeType}
                                texts={texts}      
                            />
                            <CheckboxType
                                type='job'
                                types={filterState.types}
                                changeType={changeType}
                                texts={texts}      
                            />
                           
                        </Form.Group>
                    }
                    <Form.Field 
                        control={SearchSelect}
                        label={texts.EditSourceLable}
                        placeholder={texts.EditSourcePlaceholder}
                        value={filterState.filterSource}
                        onChange={handleSourceChange}
                        api = "kpe20"
                        SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify(['group','list'])}
                        Value2TitleUrl={'/kpe20/Value2Title?types='+JSON.stringify(['group','list'])}
                        autoSelect

                    />
                    
                    { (sourceType==='group' && filterState.filterSource) && 
                        <Form.Field
                            control={FilterElement}
                            label='Filter'
                            onChange={changeAlgo}
                            type={Object.entries(filterState.types).filter(([type,val])=>val===true).map(([type,val])=>type)}
                            value={filterState.filterAlgo}
                            functions={functions}
                            achievements={achievements}
                            name='filterAlgo'
                        />
                    }
                </Form>
            </Modal.Content>
        </Segment>
        <Segment secondary>
            {children}
        </Segment>
        {
            filterState.filterSource &&
            <Segment>
                <Modal.Description>
                    <Modal.Header as='h4' content={texts.Result}/>
                    { isLoading ?
                        <Loader active={isLoading} />:
                        <>
                            {texts.Count}:  {totalCount} 
                        </>
                    }
                    <MyTabulator
                        index='UID'
                        columns={listColumns}
                        headerFilterPlaceholder={texts.headerFilterPlaceholder}
                        data={members}
                        height={(height-300)+'px'}                    
                        compact="very"
                        striped
                        layout="fitColumns"     
                        onScroll={onScroll}
                        replaceMode={true}
    
                    />
                </Modal.Description>

            </Segment>
        }
    </>
    )
}
export default EditSource