import React, {useState, useEffect} from 'react'
import {Popup, Form, Button,Icon, Dropdown, Modal, Label} from 'semantic-ui-react'
import {useApi} from '@wollo-lib/kpe-context'   //'../../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../../../lib20/useTexts'
import {useCheckFormArray} from '../../../../Hooks/useCheckFormArray'
import {mailTypes,mailTypesDisplay} from './types.js'


const EditMail =(props)=> {

    const {apiUpdate}=useApi()
    const texts=useTexts('/show/person')
    const [email, setEmail]=useState([])
    const [familySaved,setFamilySaved]=useState(false)
    const {children, modalParams,resetModal, modalSubmit}=props
    const {person,UID,UIDparent}=modalParams[0]
    

    const mailOptions=mailTypes.
    map(option=>({
        key:option,
        icon:mailTypesDisplay[option],
        content:texts[option],
        value:option
    }))

    const {getError,checkSubmit}=useCheckFormArray(email,
        [
            {name:'type', error: texts.invalidEmailType,test:(val,index)=>
                {
                    return val!=='' && val!== null && val!== undefined}
                },
            {name:'email', error: texts.invalidEmail,test:(val,index)=>(
                    val.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i))
            },
           
            
        ]
    )
    
 

    modalSubmit.current=async ()=> {
        const family=email.find(p=>p.type==='family')  
        if(checkSubmit())
        {  
            if(!family || family.email===familySaved || window.confirm(texts.ConfirmFamilyEmail) )
            {
                const  result= await apiUpdate('/kpe20/person/'+UID,{email:email})
                if(result.success)
                    resetModal()
            }
        }
    }
    

    const handleChange= (ev, {name, value, index}) => {
       
        if(name==='email')
            setEmail((p) => (p.map((val, i) => (i===index ?  {...val,email:value}: val))))
        else if(name==='type')
            setEmail((p) => (p.map((val, i) => (i===index ?  {...val,type:value}: val))))
        else if(name==='primary')
            setEmail(old =>[old[index],...old.filter((a,i)=>i!==index)])
        else if(name==='unsubscribe')
            setEmail((p) => (p.map((val, i) => (i===index ?  {...val,unsubscribe: val.unsubscribe ? false : true}: val))))


    }

    const add=() => {
        setEmail((p) => ([...p, {type:'personal', email:''}]))
    }

    const remove=(e, {index:removeIndex}) => {
        setEmail(email.filter((email, index) => index !== removeIndex))
    }


    useEffect(() => {
        let myEmail= [...person.Data.email]
        if(myEmail.length===0)
            myEmail=[{type:'personal', email:''}]
        setEmail(myEmail)
        const family=person.Data.email.find(p=>p.type==='family')
        setFamilySaved(family ? family.email : null)
    }, [person])


   
    return(<>

        <Modal.Header as='h3'>{texts.EditMail+person.Display}</Modal.Header>
        <Modal.Content>
            <Form>
                {email.map((email, index) => (
                        
                        <Form.Input
                            name='email'
                            index={index}
                            label={texts.email}
                            placeholder={texts.email}
                            value={email.email}
                            onChange={handleChange}
                            error={getError('type',index) || getError('email',index) ? [getError('type',index),getError('email',index)].join(' ') : false}
                            style={email.unsubscribe ?{textDecoration:'line-through'} : {}}
                        >
                            
                           
                            
                            <Dropdown 
                                as={Button}
                                value={email.type}
                                icon={mailTypesDisplay[email.type]} 
                                options={mailOptions} 
                                onChange={handleChange}
                                name='type'
                                index={index}
                                compact 
                                basic
                            />
                           
                            <input/>
                            {
                                index===0 ?
                                    <Button color={'grey' } basic><Icon name='check square outline'/></Button>
                                :
                                    <Popup
                                        trigger={
                                        
                                                
                                            
                                            <Button
                                                    animated='vertical'
                                                    name='primary'
                                                    active={index!==0}
                                                    color={'blue'}
                                                    index={index}
                                                    onClick={handleChange}
                                            >
                                            <Button.Content hidden>1.</Button.Content>    
                                            <Button.Content visible><Icon name='angle up'></Icon></Button.Content>                                     
                                            

                                            </Button>
                                        }
                                        content={texts.PrimaryMailHelp}
                                    />
                            }
                             <Popup
                                content={texts.unsubscribePopup}
                                trigger={ 
                                <Dropdown 
                                    as={Button}
                                    icon={email.unsubscribe ?  'privacy' : 'check'} 
                                    options={[{key:0,value:false,text:texts.subscribed, icon:'check'},{key:1,value:true,text:texts.unsubscribed, icon:'privacy'}]} 
                                    onChange={handleChange}
                                    name='unsubscribe'
                                    index={index}
                                    compact 
                                    basic
                                />
                                }
                            />
                            <Button icon='trash' name={email} index={index} onClick={remove} negative/>
                        </Form.Input>
                  

                ))}
                <Form.Button icon='add' content={texts.add} onClick={add} positive />
                
            </Form>

        </Modal.Content>
        {children}
    </>)
}
export default EditMail