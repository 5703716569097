import React from "react";

// https://svg2jsx.com/

export const MalePlaceholder=()=>
{
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      version="1"
    >
      <path
        fill="#ccc"
        fillOpacity="1"
        d="M3.148 199.88c.15-10.77.254-18.296-.064-32.258 7.022-8.795 31.74-12.828 38.493-15.243 8.714-6.505 18.587-12.04 27.097-16.94 4.7-2.707 4.761-11.983 4.417-18.213-1.561-8.822-2.387-16.43-8.685-22.908-.353-1.85-1.382-6.094-4.475-11.639-2.081-9.318-10.285-20.857-8.224-26.777 5.49-15.764.694-22.17 4.064-23.518 7.688-3.073 9.767-9.794 16.807-16.542 20.571-3.918 17.41-14.353 32.78-12.338 19.05 2.499 8.558 5.316 21.624 11.955 16.053 8.157 6.488 8.983 21.142 30.58 8.702 12.824-8.249 13.698-3.857 25.046 4.057 10.484-5.507 12.27-10.332 23.743-4.63 11.007-7.534 14.243-2.204 28.75 4.95 13.472 13.168 7.316 31.73 27.494 4.712 5.123 21.645-1.079 33.304 13.883-.007 7.67.163 18.636.215 34.982-52.256-.187-193.832-.058-193.832-.058z"
      ></path>
    </svg>
  );
}

export const FemalePlaceholder=() =>
{
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="315"
        height="315"
        version="1"
      >
        <path
          fill="#ccc"
          fillRule="evenodd"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="1"
          d="M189.58 30.662c9.943 6.002 18.366 7.91 28.42 29.665 14.618 31.633 14.457 38.714 20.591 27.687-1.171 2.857-2.265 5.684-1.41 7.787 1.222 1.292 2.444.798 3.667 0-.719 1.05.25 5.196 1.974 2.02 1.224-2.257 1.129-2.308 1.129-2.308.851 1.99.396 4.457.564 5.191 1.692 2.13 3.385-1.496 5.077-5.191.378 3.108.126 6.51-2.256 9.806 5.622 2.384 5.747-4.455 4.513-9.23 1.87 3.063 3.356 6.494 2.257 12.402.658 1.16 1.316-1.42 1.974-2.307-.19 1.705-.003 4.774-1.551 5.264 1.006.363 2.284 1.406 4.16-.721.191 5.176-1.31 8.706-8.391 7.21 2.107 1.407 2.443 11.33 6.346 4.11 1.397 2.153-1.034 3.59-2.186 8.003-.69 10.34-7.26 8.992-11 13.267-.966 4.64-1.778 5.944-3.221 9.373-.92 2.186 5.588 7.038 5.842-2.408 1.07 9.51-7.156 5.918-10.064 10.414-1.043 1.026-2.2 1.829-3.489 2.377-8.833 5.454-13.663 20.258-17.489 22.063-1.285 1.335-9.514 1.178-13.822.433-2.168 5.263-3.875 7.839-6.981 10.67-3.429 7.493-6.128 24.843 4.442 33.456 17.546 14.296 22.901 18.505 45.345 23.29 49.763 10.607 45.133 37.146 54.562 58.548l-275.114-.229c8.13-20.21 4.327-47.035 54.406-59.401 18.197-4.494 25.652-8.712 40.972-21.992 9.555-8.282 7.618-25.185 5.5-34.32-7.615-.85-11.56-2.95-12.975-5.913-3.648.003-7.607.672-7.616-7.138-9.875 4.636-14.338-3.13-18.9-10.67-4.514-2.253-10.104-1.397-11.636-12.258l-1.27-3.533c-2.086-2.977-1.119-7.155-.916-11.032 1.34 2.048 2.116 2.553 3.456 3.317-10.057-12.142-4.053-19.547-5.43-29.13 2.488 1.398 4.545.697 6.628-.504l-4.372-1.01s18.107-.343 8.463-1.442c-7.445-.848-7.052-5.119-7.052-5.119 2.165 1.232 4.106 2.885 6.276.937-.014-2.573.75-4.656 3.173-5.696-3.063-4.583 1.903-15.813-.423-20.837 1.378.979 2.305 3.264 4.725 1.226 1.544-4.145 2.362-5.645 4.654-6.345 1.374-4.138 3.448-7.058 6.7-7.932 0 0 4.249-13.699 7.193-18.53 7.175-11.772 10.225-14.307 23.765-22.96 13.323-8.513 23.707-8.994 30.395-5.551 3.819 1.965 6.311 2.235 8.04 2.235 4.97 0 8.798-5.226 22.354 2.956z"
        ></path>
      </svg>
    );
}

export const DocumentPlaceholder=(props)=>
{
  const {width=300,height=500, text='', fontSize=20}=props
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} >
    <rect fill="#ddd" width="300" height="500"/>
    <text fill="rgba(0,0,0,0.5)" font-family="sans-serif" font-size={fontSize} dy="10.5" font-weight="bold" x="50%" y="50%" text-anchor="middle">{text}</text>
  </svg>
 )
}
  

