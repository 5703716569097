import React, {useEffect, useState, useReducer, useRef}  from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import Home             from './Home'
import Group            from './show/group/Group'
import Person           from './show/person/Person'
import Job              from './show/job/Person'
import MainMenu         from './MainMenu/MainMenu'
import useTexts         from './lib20/useTexts'
import Notfound         from './lib20/Notfound'

import {useApi, useUser}from '@wollo-lib/kpe-context'
import {basePath, devMode}       from './config.jsx'
import {useRoot, useWs, useConfig} from './lib20/KPE20context'
import EditList         from './edit/list/List'
import EditDList        from './edit/dlist/DList'  
import Email            from './edit/email/Email'
import CentralModal     from  './lib20/CentralModal'
import Settings         from  './lib20/Settings/Settings'
import RailContainer    from './lib20/Rails/RailContainer'

import MapLinks         from  './Settings/MapLinks'
import UnsubscribedActions from './Settings/UnsubscribedActions'



import {Segment} from 'semantic-ui-react'
import SystemMessage from './lib20/SystemMessage'


const App=() => {

    
    // this App runs completely in the KPE Context from index.js!!!


    const {apiSelect}=useApi()
    const config=useConfig()
    // main Tabulator reference
    const tabRef=useRef()


    //const [modalSubmit, setModalSubmit]=useState(null)
    const texts=useTexts('App')

    const reducerModalParams=(state,action)=>
    {
        const entries=Object.entries(action)
        const [actionKey,params]=entries[0]
        switch(actionKey) {
            case 'addLevel':
                return [params,...state]

            case 'close':
                return state.filter((el,Index)=>Index!==0)

            case 'setAll':
                return params

            // if you need to update sate in a modal, you can use these ones
            case 'replace':
                return state.map((el,Index)=>((action.level && Index===action.level)||(!action.level && Index===0) ? params : el))

            case 'modify':
                if(state) {
                    const newState=state.map(level => {
                        const modifier={}
                        // make sure, that we are only modifying parameters, which are already preasent in the state
                        Object.keys(params).forEach((p=>{if(level[p]) modifier[p]=params[p]}))
                        return {...level,...modifier}
                    })
                    return newState
                }
                else
                    return null   
            default:
                console.error('invalid setModalParams key', actionKey)
        }
    }

    const [modalParams, setModalParams]     = useReducer(reducerModalParams,[]) // modal dialog stack
    const [modalDefaults, setModalDefaults] = useState({})                      // store defauts for menu creating new objetcs
    const [isAdmin, setAdmin]               = useState(false)                   // store here, if user is admin
    const [isBaseAdmin, setBaseAdmin]       = useState(false)                   // store here, if basic user is an admin
    const [achievements, setAchievements]   = useState([])                      //load all achievement templates  once ands tore them here avoids unessecarry trafic
    const [functions, setFunctions]         = useState([])                      // load all function templates  once and store them here avoids unessecarry trafic
    const [actions, setActions]             = useState([])                      // load all action templates  once and store them here avoids unessecarry trafic
    const [railPath,setRailPath]            = useState('db')
    const [containerWidth,setContainerWidth] = useState({})

    const reducerExtraMenu=(state,action)=>
    {
        switch(action.action)
        {
            case 'add': 
                const result= Array(Math.max(action.position+1,state.length)).fill(null).map((m,Index)=>
                (
                        Index===action.position ? action.menu: state[Index] 
                ))
                return result
                break
            case 'replace':
                return action.menus
            default:
                return state
        }
    }
    const [extraMenu, setExtraMenu]         = useReducer(reducerExtraMenu,[])                      // array of components for main menu addOns
    const [theme, setTheme]                 = useState('light')

    const {updateTemplate}=useWs({})
  

    useEffect(()=>{
        document.title=(devMode ? 'Test ' : '') + texts.Title
    },[texts])

    useEffect(() => {

        const isAdmin=async()=>
        {
            const res= await apiSelect('/kpe20/isAdmin','kpe20')
            setAdmin(res.result)
            setBaseAdmin(res.resultBase)
        }
        
        isAdmin()

    },[config])

    useEffect(()=>{
        const getAchievements=async () => {

            const acs=await apiSelect(`/kpe20/achievementTemplate/Data`)
            setAchievements(acs.result.map(a=>({...a,renewal:a.Data.renewal ? Math.round(a.Data.renewal/365.25) : null})))
        }
        if(config.UID )
            getAchievements()
    },[config,updateTemplate])
    
    useEffect(()=> {
        const getFunctions=async ()=> {

            const result= await apiSelect(`/kpe20/function/Data`,'kpe20')
            if(result.success)
            {
                setFunctions(result.result)
            }
        }
        if(config.UID)
            getFunctions()
    },[config, updateTemplate])

    useEffect(()=> {
        const getActions=async ()=> {

            const result= await apiSelect(`/kpe20/actionTemplate/Data`,'kpe20')
            if(result.success)
            {
                setActions(result.result)
            }
        }
        if(config.UID)
            getActions()
    },[config, updateTemplate])





    useEffect(() => {
        if (!config.orgaUser.Data.settings?.theme) {
            setTheme('light')
            return
        }
        
        if (config.orgaUser.Data.settings.theme === 'system') {
            setTheme(window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light')

        }

        if (theme === 'dark' || config.orgaUser.Data.settings.theme === 'dark') {
            import('./lib20/themes/darkmode.css')
            import('tabulator-tables/dist/css/tabulator_midnight.min.css')
        }
        else {
            import('./lib20/themes/lightmode.css')
            import('tabulator-tables/dist/css/tabulator_semanticui.min.css')
        }
    },[config.orgaUser.Data])

    return (

        <Router basename={basePath} >
            <MainMenu key='TopMainMenu'
                modalParams={modalParams}
                setModalParams={setModalParams}
                extraMenu={extraMenu}
                isAdmin={isAdmin}
                isBaseAdmin={isBaseAdmin}
                functions={functions}
                achievements={achievements}
                tabRef={tabRef}

            />

            {
                !config.maintenance || isAdmin  ?
                    <RailContainer containerWidth={containerWidth} railPath={railPath}>
                            <CentralModal key='CentralModal'
                            modalParams={modalParams}
                            setModalParams={setModalParams}
                            modalDefaults={modalDefaults}
                            />
                            <Switch>
                                
                            <Route exact path="/show/group/:UID" key={'a0'}>
                                <Group
                                    setModalParams={setModalParams}
                                    modalParams={modalParams}
                                    setModalDefaults={setModalDefaults}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    actions={actions}
                                    functions={functions}
                                    achievements={achievements}
                                    tabRef={tabRef}  
                                    setRailPath={setRailPath}  
                                    setContainerWidth={setContainerWidth}                
                                />
                            </Route>  
                        
                            <Route exact path="/show/group/:type/:UID" key={'a0'}>
                                <Group
                                    setModalParams={setModalParams}
                                    modalParams={modalParams}
                                    setModalDefaults={setModalDefaults}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    actions={actions}
                                    functions={functions}
                                    achievements={achievements}
                                    tabRef={tabRef}  
                                    setRailPath={setRailPath}  
                                    setContainerWidth={setContainerWidth}                
                                />
                            </Route>  

                            <Redirect exact from="/show/person/:UID" to="/show/person/main/:UID" />
                            <Route exact path="/show/person/:type/:UID" key={'RoutePerson'}>
                                <Person
                                    setModalParams={setModalParams}
                                    modalParams={modalParams}
                                    modalDefaults={modalDefaults}
                                    setModalDefaults={setModalDefaults}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    functions={functions}
                                    achievements={achievements}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}       
                                />
                            </Route>

                            <Route exact path="/show/job_guest/:UID" key={'RouteJobGuest'}>
                                <Job
                                    setModalParams={setModalParams}
                                    modalParams={modalParams}
                                    modalDefaults={modalDefaults}
                                    setModalDefaults={setModalDefaults}
                                    setExtraMenu={setExtraMenu}
                                    functions={functions}
                                    achievements={achievements}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}      
                                />
                            </Route>


                            <Route exact path="/edit/list/:UID" key={'RouteList'}>
                                <EditList
                                    modalParams={modalParams}
                                    setModalParams={setModalParams}
                                    setModalDefaults={setModalDefaults}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    functions={functions}
                                    achievements={achievements}
                                    actions={actions}
                                    tabRef={tabRef}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}    
                                />
                            </Route>
                            <Route exact path="/edit/dlist/:tab/:UID" key={'RouteDList'}>
                                <EditDList
                                    modalParams={modalParams}
                                    setModalParams={setModalParams}
                                    setModalDefaults={setModalDefaults}
                                    functions={functions}
                                    achievements={achievements}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    actions={actions}
                                    tabRef={tabRef}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}       
                                />
                            </Route>
                            <Route exact path="/edit/email/:UID" key={'RouteEmail'}>
                            {
                                <Email
                                    modalParams={modalParams}
                                    setModalParams={setModalParams}
                                    setModalDefaults={setModalDefaults}
                                    functions={functions}
                                    achievements={achievements}
                                    extraMenu={extraMenu}
                                    setExtraMenu={setExtraMenu}
                                    actions={actions}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}    
                                />
                            }
                            </Route>
                            <Route exact path="/" key={'RouteHome'} >
                                <Home 
                                    setExtraMenu={setExtraMenu}
                                    setModalParams={setModalParams}
                                    setRailPath={setRailPath}
                                    setContainerWidth={setContainerWidth}    
                                />

                            </Route> 
                            <Route exact path="/settings" key={'RouteAdmin'} >
                                    <Settings 
                                        isAdmin={isAdmin}
                                        setRailPath={setRailPath}
                                        setContainerWidth={setContainerWidth}    
                                        //setExtraMenu={setExtraMenu}
                                    >
                                        <MapLinks/>
                                        <UnsubscribedActions/>
                                    </Settings>


                
                            </Route>

                        
                            <Route default key='RouteDefault'>     {/*If the URL is unknown, show this page*/}
                                <Notfound/>
                            </Route>
                        </Switch>
                
                    </RailContainer>
                    :''
        }
        </Router>

    )
}


export default App
