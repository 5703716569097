import { useApi, useWindowDimensions } from '@wollo-lib/kpe-context'; //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { Container, Form, Grid, Header, Icon, Popup, Segment } from 'semantic-ui-react';
import { api, baseUrl } from '../../config.jsx';
import useObjectActions from '../../Hooks/useObjectActions';
import useTexts from '../../lib20/useTexts';
import { useConfig, useWs } from '../../lib20/KPE20context';
import { CKEditorUpload as TextEditor } from '../../lib20/CKeditor/CKInlineEditor';
import ModalList from '../ModalLists';
import DListEdit from './DListEdit';
import DListResults from './DListResults';
import MenuEdit from './MenuEdit.jsx';
import OutputMenu from '../../output/OutputMenu.jsx';
import HistoryButton from '../list/ModalHistory';
import {nameReplace} from '../list/extraCols'



const EditDList = (props) => {
    const { functions, achievements, modalParams, setModalParams, setExtraMenu, actions, tabRef,setRailPath, setContainerWidth} = props
    const config = useConfig()
    const { apiSelect, apiUpdate } = useApi()
    const texts = useTexts('/edit/dlist')
    const [list, setList] = useState({})

    const [sources, setSources] = useState([])
    const [shares, setShares] = useState([])
    const [number, setNumber] = useState({ include: 0, exclude: 0, intersect: 0, visible: 0 })

    const [listOwner, setListOwner] = useState(config.orgaUser)
    const [listMember, setListMember] = useState({})
    const { width, height } = useWindowDimensions()
    const { UID, tab } = useParams()
    const [admin, setAdmin] = useState(false)
    const [fullRead,setFullRead]= useState(true)
    const [writeable, setWriteable] = useState(false)
    const history = useHistory()
    const { objectActions, actionsMessageHandler } = useObjectActions(UID)
    const [historyTime, setHistoryTime]= useState(null)
    const [totalCount,setTotalCount]= useState(0)
    const [isLoading, setIsLoading]= useState(false)
    const [listData,setListData]=useState([])

    const PopupHide = (props) =>
    (
        // create a popup component, which hides on read only objects
        admin &&
        <Popup {...props} />

    )

    const messageHandler = (value) => {

        if (value.filterAdded) {
            setSources(old => [...old, value.filterAdded])
            setNumber(old => ({ ...old, [value.filterAdded.Type]: old[value.filterAdded.Type] + 1 }))
        }
        else if (value.filtersDeleted) {
            setSources(old => (old.filter(el => !value.filtersDeleted.find(f => el.UID === f.UID))))

            setNumber(old => ({
                include: old.include - value.filtersDeleted.filter(el => el.Type === 'include').length,
                exclude: old.exclude - value.filtersDeleted.filter(el => el.Type === 'exclude').length,
                intersect: old.intersect - value.filtersDeleted.filter(el => el.Type === 'intersect').length,
                visible: old.visible - value.filtersDeleted.filter(el => el.Type === 'visible').length
            }))
        }
        else if (value.Data) {
            setList({ ...value.Data.Data })
            setListOwner(value.Data.owner)
            setListMember(value.Data.member)
            setAdmin(value.Data.admin)
            setWriteable(value.Data.writeable)
            console.log(value.Data)
        }
        else if (value.shareAdded) {
            setShares(old => [...old, value.shareAdded])
        }
        else if (value.sharesDeleted) {
            setShares(old => (old.filter(el => !value.sharesDeleted.find(f => el.UID === f.UID))))
        }
        else if(value.changed )
        {
            setListData(old=>old.map(ld=> {
               return ld.UIDBelongsTo===value.changed.UIDperson ? {...ld,...value.changed.Data} : ld
            }))
        }
        else {
            actionsMessageHandler(value)
        }

    }

    const { timestamp, setTimestamp } = useWs({ UIDmonitor: UID, UIDchanged: UID, cb: messageHandler, updateTimeLimit: 2000})

    const listChange = (ev, { name, value }) => {
        const newList = { ...list, [name]: value }
        setList(newList)
        apiUpdate('/kpe20/dlist/' + UID, newList)
    }


    useEffect(() => {


        const getList = async () => {
            const myList = await apiSelect('/kpe20/dlist/' + UID, 'kpe20')
            setList({ ...myList.result.Data, Type:'dlist' })
            setListOwner(myList.result.owner)
            setListMember(myList.result.member)
            setAdmin(myList.result.admin)
            setFullRead(myList.result.writeable)
            setWriteable(myList.result.writeable)
            setRailPath(myList.result.UIDgroup)
        }
        if (UID && UID !== 'new') {
            getList()
        }
        else if (UID === 'new') {
            setListOwner(config.orgaUser.UID)
            setList({ name: '' })
            const params = new URLSearchParams(document.location.search);
        }
    }, [UID, config, shares, timestamp, modalParams])


    useEffect(() => {
        const getSources = async () => {
            const result = await apiSelect(`/kpe20/dlist/filter/${UID}`)
            if (result && result.success) {
                setSources(result.result)

                const newNumber = {
                    include: result.result.filter(el => el.Type === 'include').length,
                    exclude: result.result.filter(el => el.Type === 'exclude').length,
                    intersect: result.result.filter(el => el.Type === 'intersect').length,
                    visible: result.result.filter(el => el.Type === 'visible').length
                }
                setNumber(newNumber)
            }
            else {
                setNumber({ include: 0, exclude: 0, intersect: 0, visible: 0 })
            }
        }
        if (UID && writeable)
            getSources()
    }, [UID,writeable ])


    useEffect(() => {
        const getShares = async () => {
            const result = await apiSelect(`/kpe20/dlist/shares/${UID}`)
            if (result && result.success) {
                setShares(result.result)
            }
        }
        if (UID && admin)
            getShares()
    }, [UID, timestamp, admin])


    const extraFields= JSON.stringify(
        !list || (!list.extraParameter && !list.extraFields) ? null :
        
            list.extraParameter.map(para=>(
                {
                    path: '$.'+nameReplace(para.name), alias:'__extra_'+nameReplace(para.name)
                }
            ))
              
    )
    
    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'},
        ...list.extraFields ? list.extraFields.map(field=>(
            {
                path: field.path, alias:field.alias
            }
        )): []
    
    ])



    const ajaxParams={timestamp:timestamp,Data:jsonFields,ExtraData:extraFields,type:JSON.stringify(['entry'])}
    //const {onScroll, isLoading, totalCount}= usePageSelect({select:`/kpe20/list/persons/${UID}`,urlPara:ajaxParams, setData:setListData, pageSize:50, clientFilter:true})
   
    useEffect(()=>
    {
        const getEntries=async ()=>
        {
            const myTimestamp=historyTime ? `&timestamp=${historyTime}` : ''
            setIsLoading(true)
            const result= await apiSelect(`/kpe20/list/persons/${UID}?ExtraData=${extraFields}&Data=${jsonFields}${myTimestamp}&type=["entry"]`)
            setIsLoading(false)
            if(result.success)
            {
                setListData(result.result)
                setTotalCount(result.result.length)
            }

        }
        if(UID)
            getEntries()
    }
    ,[list,timestamp, historyTime])


    useEffect(() => {
        if (admin)
            setExtraMenu({action:'replace',
                menus:
                [
                    { admin: false, menu: MenuEdit, props: { UID, timestamp, functions, achievements, shares, type: 'dlist', actions, objectActions , key:'DListEdit'} },
                    { admin:false,menu:OutputMenu,props:{tabRef,filename:`${list.name}`,key:'DListOutput',list,types:['entry']}}
                ]
            })
        else {
            setExtraMenu({action:'replace',menus:[]})
        }
        return () => setExtraMenu({action:'replace',menus:[]})
    },
        [UID, functions, achievements, timestamp, shares, objectActions, actions, admin])

    useEffect(()=>{
        setContainerWidth(1800)
    },[width])
    
    const revertTimestamp=async ()=>{
        await apiUpdate(`/kpe20/dlist/revert/${UID}/${historyTime}`)
        setHistoryTime(null)
    }



    return (
        <>
            <Grid>
                <Grid.Column width={13}>
                    <Header as='h2'>
                        <PopupHide
                            content={texts.edit}
                            trigger={
                                <Icon
                                    name='edit'
                                    onClick={ev => {
                                        setModalParams({
                                            addLevel: {
                                                component: ModalList,
                                                UID: UID,
                                                type: 'dlist',
                                                admin,
                                                tabRef
                                            }
                                        })
                                    }}
                                    className='editIconColor'
                                />
                            }
                        />
                        {texts.HeaderEdit}: {list.name}
                    </Header>
                </Grid.Column>
                <Grid.Column width={3}>

                        <HistoryButton
                            setModalParams={setModalParams}
                            historyTime={historyTime}
                            setHistoryTime={setHistoryTime}
                        />

                </Grid.Column>
            </Grid>
            <Header as='h4'>{texts.Group}: <a onClick={e => { history.push('/show/group/' + listOwner?.UID) }}>{listOwner?.Title} {listOwner?.Display}</a></Header>
            <Header as='h4'>{texts.creator}: <a onClick={e => { history.push('/show/person/main/' + listMember?.UID) }}>{listMember?.Title} {listMember?.Display}</a></Header>
            <Segment>
            {    <Form>
                    <Form.Field
                        label={texts.Description}
                        control={TextEditor}
                        uploadUrl={'https://' + api + '.' + baseUrl + '/kpe20/files/editor/365/' + UID}
                        onChange={listChange}
                        value={list.description}
                        name='description'
                        readOnly={!writeable}
                        autoSave={0}
                    />
                        </Form>}
            </Segment>
            {(width > 1200  && writeable )?
                <Grid>
                    <Grid.Row divided >
                        <Grid.Column width={9}>
                            <DListResults
                                list={list}
                                setList={setList}
                                listOwner={listOwner}
                                tabRef={tabRef}
                                listData={listData}
                                isLoading={isLoading}
                                totalCount={totalCount}
                                fullRead={fullRead}
                                {...props}
                            />
                            
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <DListEdit
                                list={list}
                                sources={sources}
                                setSources={setSources}
                                number={number}
                                UID={UID}
                                {...props}
                            />
                        </Grid.Column>



                    </Grid.Row>
                </Grid>
                :
                <>
                    {admin && 
                            <DListEdit
                                list={list}
                                timestamp={timestamp}
                                sources={sources}
                                setSources={setSources}
                                number={number}
                                UID={UID}
                                {...props}

                        />
                    }
                    <Grid.Column width={10}>
                        <DListResults

                            list={list}
                            setList={setList}
                            listOwner={listOwner}
                            tabRef={tabRef}
                            listData={listData}
                            isLoading={isLoading}
                            totalCount={totalCount}
                            fullRead={fullRead}
                            {...props}

                        />
                    </Grid.Column>
                </>
            }

        </>
    )

}
export default EditDList
