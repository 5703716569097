import React, {useState, useEffect}  from 'react'
import {Dropdown,Icon,  Modal, Header, Image, Tab, Menu} from 'semantic-ui-react'
import {useApi, useUser} from '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../../lib20/useTexts'
import {useConfig} from '../../lib20/KPE20context'
import {useHistory} from 'react-router-dom'
import ChangeGroup from './modals/ModalChangeGroup'
import ModalTimestamp from './modals/ModalTimestamp'


const MenuEdit=(props) => {
    const texts=useTexts(['/show/person','/edit/person'])
    const {UID,person,group,modalParams,setModalParams,resetModal,timestamp,isDesktop}=props
    const {apiInsert,apiDelete,apiUpdate}=useApi()
    const history=useHistory()
    const config=useConfig()

    const addons = config.addons?.filter(a => a.menus?.includes('person/edit'))


    const clickHandler=async (ev,{name,size,dimmer,closeicon, modalProps})=>
    {
        const module=await import('./modals/'+name)
        setModalParams({
            addLevel:{
                component:module.default,
                size: size,
                closeicon:closeicon,
                dimmer:dimmer,
                ...props,
                ...modalProps
            }
        })
    }

    const groupQuery='&gender='+JSON.stringify(['B',person.gender])


    const exit=async ()=>
    {
        if(window.confirm(texts.confirmExit))
        {
            const result=await apiInsert('/kpe20/extern/'+group.UID,{UID:UID,...person.Data})
            if(result.success)
            {
                window.alert(texts.exitConfirm)
            }
        }
    }

    const deleteGuest=async ()=>
    {
        if(window.confirm(texts.confirmDeleteGuest))
        {
            const result=await apiDelete('/kpe20/guest/'+UID)
            if(result.success)
            {
               history.push('/')
            }
        }
    }


    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{timestamp:timestamp}})
        }
    },[timestamp])


    const clickMenu=async (ev,{name})=>
    {
        if(name==='change')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroup,
                    UID:UID,
                    group: group,
                    groupQuery: groupQuery,
                    type:'person'
                }
            })
        }
        else if(name==='enter')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroupEnter,
                    UID:UID,
                    group: group,
                    groupQuery: groupQuery,
                    type:'person'
                }
            })
        }
        else if(name==='changeExtern')
        {
            setModalParams({
                addLevel:
                {
                    component : ChangeGroup,
                    text:  texts.changeGroupExtern,
                    UID:UID,
                    group: group,
                    groupQuery: groupQuery,
                    type:'extern'
                }
            })
        }
        else if(name==='exit')
        {
            setModalParams({
                addLevel:
                {
                    component : ModalTimestamp,
                    text: texts.confirmExit,
                    textDate:  texts.exitDate,
                    UID:UID,
                    group: group,
                    type:'extern',
                    category: config.ExternCategories.former,
                }
            })
        }
        else if(name==='died')
        {
            setModalParams({
                addLevel:
                    {
                        component : ModalTimestamp,
                        text: texts.confirmDied,
                        textDate:  texts.diedDate,
                        UID:UID,
                        group: group,
                        type:'extern',
                        category: config.ExternCategories.former
                    }
            })
        }
        else if(name==='delete')
        {
            if(window.confirm(texts.confirmDelete))
            {
                await apiDelete(`/kpe20/extern/${UID}`)
                history.push('/')
            }
        }
        else
            alert('not yet implemented')
    }

    return(
        <>
            <Dropdown item simple={isDesktop} text={texts.MenuEdit}>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={clickHandler}
                        size='tiny'
                        closeicon
                        name='ModalEditFamily'
                        key='personFamily'
                        text={texts.MenuFamily}
                        icon='users'
                    />
                            
                       
                    <Dropdown.Divider key='divEP1'/>
                    { person.Type==='guest' &&
                        <Dropdown.Item
                            onClick={deleteGuest}
                            size='tiny'
                            closeicon
                            name='deleteGuest'
                            key='deleteGuest'
                            text={texts.deleteGuest}
                            icon='trash'
                        />
                    }
                    
                    {person.Type==='person'  &&
                        <>
                            <Dropdown.Item 
                                content={texts.changeGroup} 
                                name='change' 
                                onClick={clickMenu}
                                key='personChange'
                                icon='exchange'
                             /> 
                            <Dropdown.Item 
                                content={texts.exitOrga} 
                                color='red'
                                name='exit'
                                onClick={clickMenu}
                                key='personExit'
                                icon='sign-out'
                            /> 
                            <Dropdown.Item
                                content={texts.died}
                                color='black'
                                name='died'
                                onClick={clickMenu}
                                key='personDied'
                                icon='dot circle outline'                                
                            /> 
                        </>
                    }
                    {person.Type==='extern'  &&
                        <>
                            <Dropdown.Item 
                                content={texts.changeGroupExtern} 
                                name='changeExtern'
                                onClick={clickMenu}
                                key='externChange'
                                icon='exchange'
                            /> 
                            <Dropdown.Item
                                content={texts.enterOrga}
                                name='enter'
                                onClick={clickMenu}
                                key='externEnter'
                                icon='sign-in'
                            /> 
                            <Dropdown.Item 
                                content={texts.delete}
                                name='delete'
                                onClick={clickMenu}
                                key='externDelete'
                                icon='trash'
                            /> 
                            <Dropdown.Item
                                content={texts.died}
                                name='died'
                                onClick={clickMenu}
                                key='externDied'
                                icon='dot circle outline'
                            /> 
                        </>
                    }
                    <Dropdown.Divider/>  

                    {addons && addons.map((addon,index) => (
                        <Dropdown.Item
                            onClick={clickHandler} 
                            text={addon.name}
                            size='large'
                            name='Addon'
                            modalProps={{
                                addon:addon,
                                UID:person?.UIDBelongsTo,
                            }}
                            key={`addon${index}`}
                            icon= { addon.icon }
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>


        </>

    )

}  

export default MenuEdit
