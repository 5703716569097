import React, { useState, useEffect, useRef } from 'react'


// form validation function
// values: array or object of values to be validated
// errors: state object of current errors in the form: const [errors,setErrors]= useState{}
// set errors: setter function for errors object
// submitted: false before if form is first submitted. Therefore no errors are generateed, if form not yet submitted
// hasError referenc eobject, telleing if there is any error in teh form: const hasError=useRef(false)
// checks: array of objects defining the checks to be  performed [{name: (index of value array),error: (error text to be displayed or semantic error object), test: (test function with value argument)}]


export const useCheckForm=(values,checks)=>
{
    const [errors,setErrors]=useState({}) // array of errors
    const [submitted,setSubmitted] = useState(false)  // is form submitted the first time?
    const hasError=useRef(false)
    useEffect(()=>{
        hasError.current=false
        const myErrors={}
        checks.forEach(check=>{
            if(!check.test(values[check.name]))
            {
                hasError.current=true
                if(submitted || (check.display && check.display==='always'))
                {
                    if(myErrors[check.name])
                        myErrors[check.name]+=', '+check.error
                    else
                        myErrors[check.name]=check.error
                }
            }
            setErrors(myErrors)
        })
    },[values,submitted])



    // function for submit Chcek
    const checkSubmit =()=>
    {
        if(hasError.current)
        {
            setSubmitted(true)
                return false
        }
        return true
    }

    const getError=(name)=>
    {
        if(errors)
            return errors[name] 
        return null
    }

    return {getError:getError,errors:errors,checkSubmit:checkSubmit,hasError:hasError.current,setSubmitted:setSubmitted}
}
export default useCheckForm
