export const telTypes={ 
    home:'home',
    mobile:'mobile alternate',
    work:'industry',
    family:'group' 
}

export const mailTypesDisplay={
    personal:'mail',
    family:'group',
    work:'industry',
    login: 'desktop'
}

export const mailTypes=['personal','family','work']

export const messengerIcons={
    Discord:'discord',
    Facebook:'facebook',
    GitHub:'github',
    //'Google Plus':'google plus g',
    Instagram:'instagram',
    LinkedIn:'linkedin',
    //Microsoft:'microsoft',
    reddit:'reddit',
    Skype:'skype',
    Steam:'steam',
    // Teams:'teams',
    Threema:'threema',
    Twitch:'twitch',
    Xing:'xing',
    YouTube:'youtube'
}

export const messengerLinks={
    Discord: '',//'https://discord.com/users/',
    Facebook: 'https://www.facebook.com/',
    GitHub: 'https://github.com/',
    //'Google Plus': '',
    Instagram: 'https://www.instagram.com/',
    LinkedIn: 'https://www.linkedin.com/in/',
    //Microsoft: '',
    reddit: 'https://www.reddit.com/r/',
    Skype: 'skype:',
    Steam: 'https://steamcommunity.com/search/users/#text=',
    //Teams: '',
    Threema: 'https://threema.id/',
    Twitch: 'https://www.twitch.tv/',
    Xing: 'https://www.xing.com/profile/',
    YouTube: 'https://www.youtube.com/'
}

export const addressTypes={
    personal:'home',
    family:'group',
    secondary:'home',
    packet:'box',
    postbox: 'postbox'
}

export const accountTypes={
    person: 'user',
    personFees: 'user',
    family: 'group',
    familyFees: 'group',
    extern: "money bill alternate"
}