import React, { useState, useEffect } from 'react';
import {Grid, Divider, Header, Button} from 'semantic-ui-react'
import {useApi}from '@wollo-lib/kpe-context'
import {useConfig, useWs} from '../lib20/KPE20context'
import useTexts from '../lib20/useTexts'



const UnsubscribedActions = () => {



    const [actions, setActions] = useState([]);
    const [jobs,setJobs] =useState([])
    const {apiSelect,apiUpdate} = useApi()
    const config=useConfig()

    const texts={...useTexts('dbSettings'),...useTexts('lib20Texts','Languages')}

    const {timestamp}= useWs({UIDmonitor:config.orgaUser.UID})
    
    
    // useEffect hook to fetch data when the component mounts


    useEffect(() => {
        //  fetch unsubscribed actions data
        const fetchActions = async () => {
            let myJobs=[]
            let unsubscribed=[]
            const resJobs=await apiSelect(`/kpe20/person/jobgroups/${config.orgaUser.UID}?ExtraData=1`,'kpe20')
            if(resJobs.success)
            {
                myJobs=resJobs.result
                if( myJobs.length>0)
                {
                    setJobs(myJobs)
                    for(const job of myJobs)
                    {
                        const myUnsubscribed=job.ExtraData.unsubscribedActions
                        if( myUnsubscribed && Array.isArray(myUnsubscribed) && myUnsubscribed.length>0)
                        {
                            unsubscribed=[...unsubscribed,...myUnsubscribed.map(un=>({actionUID:un,job}))]
                        }
                    }
                }
                const actions= await apiUpdate(`/kpe20/action/`,unsubscribed.map(un=>un.actionUID))
                const myList=[]
                unsubscribed.forEach(un=>{
                    const action=actions.result.find(el=>el.UID===un.actionUID)
                    myList.push({key: action.UID+'_'+un.job.UID, UID:action.UID, UIDjob:un.job.UIDjob, job: `${un.job.Title} ${un.job.Display}`,trigger:`${action.TriggerTitle}: ${action.TriggerDisplay}`, action: action.Display})
                })
                setActions(myList)
            }
        };
        fetchActions();
     }, [timestamp, config.orgaUser.UID]); 



     const onDeleteSubscribe=(ev,{uidjob,uidaction})=>
     {
        const job=jobs.find(j=>j.UIDjob===uidjob)
        if(job)
        {
            const newUnsubscribed=job.ExtraData.unsubscribedActions.filter(a=>a!==uidaction)
            apiUpdate(`/kpe20/job/${uidjob}`,{unsubscribedActions: newUnsubscribed})
        }
     }

    // Render an unordered list of actions
    return (<>
        <Divider/>
        <Header>{texts.UnsubscribedActions}</Header>
        <Grid>
            {actions.map((action) => (
                <Grid.Row key={action.key}>
                     <Grid.Column width={4} key='0'>
                        {action.trigger}
                    </Grid.Column>
                    <Grid.Column width={6} key='0'>
                        {action.action}
                    </Grid.Column>
                    <Grid.Column width={4} key='1'>
                        {action.job}
                    </Grid.Column>
                    <Grid.Column width={2} key='2'>
                        <Button 
                            basic
                            color='red' 
                            icon='trash'
                            uidaction={action.UID}
                            uidjob={action.UIDjob}
                            onClick={onDeleteSubscribe}
                        />
                    </Grid.Column>

                </Grid.Row>

            ))}
        </Grid>
              

    </>);
};

export default UnsubscribedActions;