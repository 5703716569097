
import React,{ useState, useEffect, useRef }    from    'react';
import {Loader,Button, Menu,Segment}                                 from    'semantic-ui-react'
import {useApi,usePageSelect,useWindowDimensions}      from    '@wollo-lib/kpe-context'    //'../../../kpe-context/src/index.js' //
import {bannerFormatter}                        from    '../../../lib/formatters'
import EditFilter                               from    '../modals/EditFilter'
import MyTabulator,{
    autoNumFormatter,
    autoNumEditFormatter
}                                               from    '@wollo-lib/wollo-tabulator' //'../../../lib/MyTabulator'

import useTexts                                 from    '../../../lib20/useTexts'
import {useHistory,useParams}                   from    "react-router-dom";



const Table=(props)=>
{

    const {UID}= useParams()
    const {apiSelect,apiInsert, apiDelete}=useApi()
    const {timestamp,setModalParams, modalParams,functions,achievements, tabRef, extraMenu,group, wsTime}= props
    const texts=useTexts(['/show/group','/show/person'])
    const refTexts=useRef(texts)
    const [families, setFamilies]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [count,setCount]=useState(0)
    const [myTime,setMyTime]=useState(Date.now())
    const [reset,setReset]=useState(false)




    const history= useHistory()
    const {height}=useWindowDimensions()
   
    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'}
    ])


     
    useEffect(()=>{
        const getData=async ()=>
        {
            setIsLoading(true)
            const result=await apiSelect(`/kpe20/family/list/${UID}/?type=familyFees&personData=${jsonFields}`)
            if(result.success)
            {
                setCount(result.result.length)
                setFamilies(result.result.map(p=>({...p,UID:p.UIDperson, Type:'person'})))
               
            }
            setIsLoading(false)
        }
        if(UID)
            getData()

    },[UID,wsTime,timestamp])


    useEffect(()=>{
        if(reset)
        {
            tabRef.current.replaceData(families)
            setReset(false)
        }

    },[reset])

  

    async function dropped(row)
    {
        const rowData=row.getData()
        if(window.confirm(`${rowData.DisplayPerson} ${refTexts.current.moveFamily} ${rowData.DisplayFamily}`))
        {
            // move into new family
            const result=await apiInsert(`/kpe20/family/${rowData.UIDfamily}/${rowData.UID}/?rebate=true`,{none:null})
            // update display
            if(result.success)
            {
                setMyTime(Date.now())
            }
            else
            {
                window.alert(result.message)
                setReset(true)
                
            }
        }
        else
        {
            setReset(true)
        }   
    }

    const familyHeader= (value, count, data, group)=>
    {
        return (
            data && data[0] && data[0].DisplayFamily ? data[0].DisplayFamily: ''
            
        )
        //console.log(group.getData())
    }
    
    const removeFromFamily=async (e,cell)=>
    {
        const data=cell.getRow().getData()
        if(window.confirm(`${data.DisplayPerson} ${refTexts.current.confirmExitFamily}`))
        {

            const res=await apiDelete('/kpe20/family/'+data.UID)
            if(!res.success)
                window.alert('error update person: '+res.message)
            else
                setMyTime(Date.now())
        }

    }

  
    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        const field=cell.getField()
        if(['Title','banner', 'DisplayPerson'].includes(field))
        {
            history.push('/show/person/main/'+rowData.UID)
        }
     
        
    }


    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, hozAlign:"right", frozen:true, field:'UID', download:false}, //incrementing row number
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner', download: false},
      
      //  {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:38, hozAlign:"center", frozen:true}, // edit 
      { field: 'UID', formatter: 'buttonCross', width:50, cellClick:removeFromFamily},
      { title:  texts.MemberType, field: 'TitlePerson', width: 150,  headerFilter:"input"},        
      { title:  texts.Name, field: 'DisplayPerson', minWidth:200,  headerFilter:"input"},
    
   
      { title:  texts.Age, field: 'dindex',  width:90, headerFilter:"input"}  

       
       
      
    ]
         return(
           
             <>
                <Segment secondary>
                    {texts.TabFamilyExplanation}
                </Segment>
     
                {isLoading ? <Loader active inline size='small'/> : count}&nbsp;{texts.memberCount}
             
                <MyTabulator
                    index='UID'
                    columns={listColumns}
                    headerFilterPlaceholder={texts.headerFilterPlaceholder}
                    data={families}
                    onClick={cellClick}
                    replaceMode={false}
                    height={Math.max(height-250,500)}                    
                    compact="very"
                    layout="fitColumns"  
                    tabRef={tabRef}
                    groupBy={['UIDfamily']}
                    groupHeader={familyHeader}
                    groupStartOpen={true}
                    movableRows={true}
                    on={[{name:"rowUpdated", function: dropped}]}
                    
 
                />
            </>

        )


}

export default Table

