import React, { useEffect, useState } from 'react'
import {Popup, Form, Modal, Label, Header}  from 'semantic-ui-react'
import useTexts from '../lib20/useTexts'
import {useConfig} from '../lib20/KPE20context'
import { useApi} from  '@wollo-lib/kpe-context'
import {useHistory} from 'react-router-dom'

const AddModalList=(props)=>
{
    const {children, resetModal,modalParams, modalSubmit,modalDefaults}=props
    const {tabRef,type}=modalParams[0]
    const texts={...useTexts('/edit/modal/'+type),...useTexts('OutputMenu')}
    const [UIDlist,setUIDlist]=useState(null)
    const [myLists,setMyLists]=useState([]) 
    const config=useConfig()
    const {apiSelect, apiInsert}=useApi()
    const history=useHistory()
    
    useEffect(()=>{
        const getLists=async ()=>
        {
            const result=await 
                apiSelect(`/kpe20/list?type=${type}`)
           
            if(result.success)
            { 
                setMyLists(result.result.map(l=>(
                    {key: l.UID, value: l.UID, text:l.Display}
                )))
            }
        }
        if(config)
            getLists()
    },[config])


    const addPersons=async ()=>
    {
        if(UIDlist)
        {
            const personUIDs=tabRef.current.getData("active").map(p=>(p.UID))
            if(personUIDs.length>0)
            {
                await apiInsert(`/kpe20/${type}/person/${UIDlist}`,personUIDs)
                history.push(`/edit/${type}/${UIDlist}`)
                resetModal()
            }
        }
    }

    return (<>

        <Modal.Header content= {texts.headerAdd[type]} as='h4'/>

        <Modal.Content>
        <Form>
            <Form.Group>
                <Form.Dropdown
                    selection
                    search
                    placeholder={texts.selectExisting}
                    value={UIDlist}
                    onChange={(ev,{value})=>setUIDlist(value)}
                    width={12}
                    options={myLists}
                />
                <Form.Button
                    icon='plus'
                    color='green'
                    width={1}
                    onClick={addPersons}
                />

            </Form.Group>
        </Form>
 


        {
            tabRef.current.getData("active").map(p=>(<Label size='tiny' content={`${p.firstName} ${p.lastName}`}/>))
        }
        </Modal.Content>

    </>)


}
export default AddModalList