
import React,{ useState, useEffect, useRef }  from 'react';
import {Loader,Button} from 'semantic-ui-react'
import {usePageSelect,useWindowDimensions} from '@wollo-lib/kpe-context'    //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import EditFilter from    '../modals/EditFilterSingle'
import {bannerFormatter} from '../../../lib/formatters'
import OutputMenu from '../../../output/OutputMenu';
import MyTabulator,{autoNumFormatter} from '@wollo-lib/wollo-tabulator'
import { useConfig } from '../../../lib20/KPE20context';
import useContextMenu                         from '../modals/ContextMenu';
//import MyTabulator,{autoNumFormatter, autoNumEditFormatter} from '../../../lib/MyTabulator'

//import usePageSelect from './usePageSelect'

import useTexts from '../../../lib20/useTexts'
import {useHistory,useParams} from "react-router-dom";


const Table=(props)=>
{
    const texts=useTexts('/show/group')
    const {UID}= useParams()
    const {timestamp,tabRef, functions, achievements, setModalParams, wsTime}= props
    const history= useHistory()
    const [members,setMembers]=useState('')
    const {height}=useWindowDimensions()
    const [filterOpen,setFilterOpen]=useState(false)
    const config=useConfig()
    const rowContextMenu=useContextMenu({setModalParams})
    const [filterState,setFilterState]=useState({
        filterSource:null,
        filterAlgo:{},
        types: {person:false, guest: false, extern : true, job:false}
     })

    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo
 
    })
    
    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.category', alias:'externCategory', query:true}
    ])


    const ajaxParams={
        timestamp:timestamp,
        type:JSON.stringify(["extern"]),
        Data:jsonFields,groupBanner:true,
        dataFilter: JSON.stringify(algo),
        wsTime: wsTime
    }


    /*const setMyMembers=(data)=>
    {
        if(data.length>0)
            setMembers(data.map(m=>({...m,externCategory:m?.externCategory ? m?.externCategory.join(', '): ''})))
    }*/


    const {onScroll,isLoading,totalCount}=usePageSelect({select:`/kpe20/persons/${UID}`,urlPara:ajaxParams, setData:setMembers, pageSize:50, clientFilter:true})
  
 


    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        if(cell.getField()==='pGroup')
        {
            history.push('/show/group/extern/'+rowData.UIDgroup)
        }
        if(['Title','firstName','lastName','banner'].includes(cell.getField()))
        {
            history.push('/show/person/main/'+rowData.UID)
        }
        
    }
  

    const toggleFilterOpen=()=>
    {
        setFilterOpen(state=>!state)
    }
    
    const categoryValues= {}
    Object.entries(config.ExternCategories).forEach(([key,value])=>{categoryValues[value]=key})
    const listColumns=[
        
        {formatter:autoNumFormatter, width:50, hozAlign:"right", frozen:true, field:'UID' , download:false}, //incrementing row number
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'groupBanner', download:false},
      //  {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:38, hozAlign:"center", frozen:true}, // edit 
        { title:  texts.Title, field: 'Title', width: 150,  headerFilter:"input"},        
        { title:  texts.FirstName, field: 'firstName', width: 120,  headerFilter:"input"},
        { title:  texts.LastName, field: 'lastName', width: 120,  headerFilter:"input"},
        { title:  texts.pGroup, field: 'pGroup',   headerFilter:"input"},
        { title:  texts.Category, field: 'externCategory',  headerFilter:"list", 
            headerFilterFunc:(headerValue, rowValue, rowData, filterParams)=>{
                const fieldData= rowData.externCategory ? rowData.externCategory : []
                return fieldData.includes(headerValue)
            },
            headerFilterParams:{values:config.ExternCategories ?? [], clearable:true} ,
             formatter:(cell)=>
            {
                const categories=cell.getValue()
                if(categories)
                {
                    return categories.map(cat=>(
                        config.ExternCategories[cat]
                    )).join(',')
                }
                else
                {
                    return ''
                }
            },
            sorter:"array", 
            sorterParams:{
                type:"length",
                alignEmptyValues:"top",
            }
        },
        { title:  texts.Age, field: 'dindex',  width:90, headerFilter:"input"}
    ]
         return(
           
             <>
                <Button 
                    icon='filter' 
                    circular
                    basic
                    onClick={toggleFilterOpen}
                /> 
                {isLoading ? <Loader active inline size='small'/> : totalCount}&nbsp;{texts.externCount}
                {filterOpen &&
                    <EditFilter
                        functions={functions}
                        achievements={achievements}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        type='extern'
                        UID={UID}

                    />
                }
                <MyTabulator
                    rowContextMenu= {rowContextMenu}
                    index='UID'
                    columns={listColumns}
                    headerFilterPlaceholder={texts.headerFilterPlaceholder}
                    data={members}
                    replaceMode={true}
                    onClick={cellClick}
                    height={Math.max(height-250,500)}                        
                    compact="very"
                    striped
                    layout="fitColumns"  
                    onScroll={onScroll}  
                    tabRef={tabRef}
 
                />
            </>

        )


}

export default Table

