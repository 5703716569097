import React from 'react'
import {Container, Segment, Button} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom'
import useTexts from './useTexts'

const Notfound = () => {

    const history= useHistory()
    const texts=useTexts('/404')
    
    return (<><Container align='center'>

        <h1>{texts.title}</h1>
        <Button
            content={texts.Home}
            color='blue'
            onClick={(ev) =>{ history.push('/')}}
            style={{marginTop:'20px'}}
        />

    </Container></>)
}

export default Notfound