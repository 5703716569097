import React, {useState, useEffect, useRef}  from 'react'
import {Segment, Header, Form, Icon, Modal, Popup} from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'//'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {SearchSelect, useCheckForm} from '@wollo-lib/wollo-react'
//import useCheckForm from '../../../Hooks/useCheckForm'
import {useConfig, useRoot} from '../../../lib20/KPE20context'
import useTexts from '../../../lib20/useTexts'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import { DatePicker } from '@wollo-lib/wollo-react'


const EditGroup=(props)=> {

    const {apiSelect, apiUpdate,apiInsert,apiDelete}=useApi()
    const texts=useTexts('/edit/group')
    const config = useConfig()
    const {children,modalParams, resetModal, modalSubmit,modalDefaults, setBlockSave}=props
    const {createNew,UID}= modalParams[0]
    const {UIDdefault,defaultType}=modalDefaults
    const location=useLocation()

    const [root]=useRoot()

    const history= useHistory()
    const [group, setGroup] = useState({})
    const [pgroup,setPGroup] =useState({})
    const [sgroup,setSGroup] = useState({})
    const [parents,setParents]=useState([])
    const [loading, setLoading]=useState(false)
    const [timestamp,setTimestamp]=useState({timestamp:null})

    const levels = config.Hierarchie
        .filter((el,index)=>(index>1))
        .map((el,index)=>({
            text:el,
            value:index+2,
            key:index+2
        }))
    
    const stage = config.Stage
        .filter((el,index)=>(index!==0))
        .map((el,index)=>({
            text:el,
            value:index+1,
            key:index+1
        }))
    
    const gender = group.hierarchie!==undefined && group.stage!==undefined && group.stage!==null ?
         Object.keys(config.HierarchieStageGenderGroups[group.hierarchie][group.stage])
        .map((key)=>({
            text:config.HierarchieStageGenderGroups[group.hierarchie][group.stage][key],
            value:key,
            key:key
        }))
    :[]

    useEffect(()=>{
            
        const getGroup = async () => {
            setLoading(true)
            const awGroup = await apiSelect(`/kpe20/group/${UID}?parent=true&sibling=true`, 'kpe20')
            if (awGroup.success) {
                setGroup({...awGroup.result.Data, UID: UID})
                if(awGroup.result.parent)
                    setPGroup({UID:awGroup.result.parent.UID,Title: awGroup.result.parent.Title,Display:awGroup.result.parent.Display})
                if(awGroup.result.sibling)
                    setSGroup({UID:awGroup.result.sibling.UID})
            }
            setLoading(false)
        }

        const initializeDefaults =async () => {
            if(UIDdefault)
            {
                const result = await apiSelect(`/kpe20/group/${UIDdefault}&sibling=true`, 'kpe20')
                if(result.success)
                {
                    const pGroup=result.result
                    const initGroup={ hierarchie:pGroup.hierarchie+1}
                    if(pGroup.gender !=='C')
                    {
                        initGroup.gender=pGroup.gender
                    }
                    if(config.HierarchieStageGenderGroups[initGroup.hierarchie][pGroup.stage])
                    {
                        initGroup.stage=pGroup.stage
                    }
                    setGroup(initGroup)
                    setPGroup(pGroup)
                    if(pGroup.sibling)
                        setParents([{...pGroup,sibling:undefined},pGroup.sibling])
                    else
                        setParents([{...pGroup,sibling:undefined}])
                }
            }
        }

        if (!createNew) {
            getGroup()
        }
        else {
            initializeDefaults()
            if(defaultType==='group')
                setPGroup({UID:UIDdefault})
        }
    }, [UID])
    
    const {errors,checkSubmit}=useCheckForm(group,
        [
            {name:'hierarchy', error: texts.errorHierarchy, test:(val)=>(
                (group.hierarchie!==undefined && group.hierarchie!=='') ||
                val!==undefined && val !== '' )
            },
            {name:'stage', error: texts.errorStage, test:(val)=>(
                (group.stage!==undefined && group.stage!=='') ||
                val!==undefined && val !== '')
            },
            {name:'gender', error: texts.errorGender, test:(val)=>(
                (group.gender!==undefined && group.gender!=='') ||
                val!==undefined && val !== '')
            },
            {name:'name', error: texts.errorName, test:(val)=>(
                (group.location!==undefined && group.location!=='') ||
                (val !==undefined  && val !=='')
            )
            },
            {name:'location', error: texts.errorName, test:(val)=>(
                (group.name!==undefined && group.name!=='') ||
                (val !==undefined  && val !=='')
            )
            },

        ])
    
    const {errors:groupErrors,checkSubmit:checkGroupSubmit}=useCheckForm(pgroup,
        [
            {name: 'UID', error: texts.errorMissingParent,test:(val)=>{
                    return (val!==undefined && val!=='' && val!== null) || group.hierarchie===1
            }}
        ])

    const {errors:timestampErrors,checkSubmit:checkTimestampSubmit}=useCheckForm(timestamp,
        [
            {name: 'timestamp', error: texts.errorMissingTimestamp,test:(val)=>{
                    return (createNew  && val!== null) 
            }}
        ])
    

    
    

    const handleChange=(ev, {name, value})=>
    {
        if(name==='hierarchie')
        {
            setGroup((m)=>(
                {
                    ...m,
                    hierarchie : value,
                    stage : config.HierarchieStageGenderGroups[value].length<=1 ? 0 : null
                }
            ))
            setPGroup({UID: null})
        }
        else if(name==='gender')
        {
            setGroup((m)=>(
            {
                ...m,
                gender: value,
                stage : config.HierarchieStageGenderGroups[m.hierarchie].length<=1 ? 0 : m.stage

            }))
            const newGroup=parents.find(g=>g.gender===(value ==='C' ?'F':value))
            if(newGroup)
                setPGroup(newGroup)
            else
                setPGroup({UID: null})
        }
        else
        {
            setGroup((m)=>(
                {
                    ...m,
                    [name]: value,
                }
            ))
        }

    }
    

    modalSubmit.current=async (event)=> {
        
        // you have to call the checks in seperate statements, not like if(!checkSubmit() || !checkGroupSubmit()), in this case the optemiser is calling onfly the first check
        // and will not call the second one, if the first one in the or statement is already true
        const check1=checkSubmit()
        const check2=checkGroupSubmit()
        const check3=checkTimestampSubmit()
        if(!check1 || !check2 || (!check3 && createNew))
            return
        else {
            let result
            if(createNew)
            {
                if(sgroup)
                    result=await apiInsert(`/kpe20/group/${pgroup.UID}?timestamp=${timestamp.timestamp}&belongsTo=${sgroup.UID}`, group )
                else
                    result=await apiInsert(`/kpe20/group/${pgroup.UID}?timestamp=${timestamp.timestamp}`, group )

            }
            else
            {
                if(sgroup)
                    result=await apiUpdate(`/kpe20/group/${UID}?belongsTo=${sgroup.UID}`, {UID:UID,...group} )
                else
                    result=await apiUpdate(`/kpe20/group/${UID}`, {UID:UID,...group} )
            }
 
            console.log(result)
            if(result.success) {
                if(createNew)
                    history.push('/show/group/groups/'+result.result.UID)
                resetModal()
            }
            else {
                console.log('Error: '+result.message)
               
            }

        }
        
    }

    const deleteGroup=async ()=>
    {   
        if(window.confirm(texts.confirmDelete))
        {
            const result=await apiDelete(`/kpe20/group/${UID}`)
            if(result.success)
            {
                resetModal()
                history.push('/')
            }
            else
            {
                window.alert(texts.deleteFailed)
            }
        }

    }


    useEffect(()=>{
        if(group.hierarchie && group.gender && ((pgroup && pgroup.UID) || group.hierarchie===1) )
            setBlockSave(false)
        else
        {
            setBlockSave(true)
        }
           
    },[group,pgroup])


    return (<>
        <Modal.Header>
            {createNew ? texts.HeaderNewGrp : texts.HeaderEditGrp}
        </Modal.Header>
        <Modal.Content>
            <Form loading={loading}>
                {
                    group.hierarchie!==1 &&
                    <Form.Select
                        label = {texts.Hierarchy}
                        placeholder = {texts.Hierarchy}
                        options = {levels}
                        name = 'hierarchie'
                        onChange = {handleChange}
                        value = {group.hierarchie}
                        error = {errors.hierarchy}
                    />
                }
                {
                group.hierarchie && <>
                    {
                        config.HierarchieStageGenderGroups[group.hierarchie].length>1 &&
                        <Form.Select
                            label = {texts.Stage}
                            placeholder = {texts.Stage}
                            options = {stage}
                            name = 'stage'
                            onChange = {handleChange}
                            value = {group.stage}
                            error = {errors.stage}
                        />
                    }
                    {
                        config.HierarchieStageGenderGroups[group.hierarchie].length>1 && !group.stage  ? '' :<>
                        {
                            group.UID!==config.UID &&
                            <Form.Select
                                label = {texts.Gender}
                                placeholder = {texts.Gender}
                                options = {gender}
                                name = 'gender'
                                onChange = {handleChange}
                                value = {group.gender}
                                error = {errors.gender}
                            />
                        }
                        {
                            group.gender && <>
                            {

                                group.hierarchie!==1 &&
                                    createNew ?
                                        <Form.Field
                                            label = {texts.MajorGroup}
                                            placeholder = {texts.MajorGroup}
                                            control = {SearchSelect}
                                            api = 'kpe20'
                                            SuggestUrl={
                                                '/kpe20/SearchData?types=group&gender='
                                                + ('C'===group.gender ? JSON.stringify(['B','C',group.gender]) : 
                                                        'B'===group.gender ? JSON.stringify(['F','M']) : 
                                                        group.gender) +
                                                '&hierarchie='
                                                +JSON.stringify(Array(group.hierarchie-1).fill(0).map((el, i) => (i+1)))
                                                +'&changeable=true'
                                            }

                                            Value2TitleUrl='/kpe20/Value2Title'
                                            name = 'UIDgroup'
                                            value = {pgroup.UID}
                                            autoSelect
                                            onChange={(ev,{value})=>{setPGroup({UID:value})}}
                                            error={groupErrors.UID}
                                        />
                                    :
                                        <Segment>
                                            <Header as ="h4" content={texts.belongsTo}/>
                                            {pgroup.Title} {pgroup.Display}
                                            <Popup
                                                trigger={<Icon name='question'/>}
                                                content={<p>{texts.helpBelongsTo}</p>}
                                            />

                                        </Segment>
                            }
                            <Form.Group widths='2'>
                                <Form.Input
                                    label = {texts.GrpName}
                                    placeholder = {texts.GrpName}
                                    value = {group.name}
                                    onChange = {handleChange}
                                    name='name'
                                    error = {errors.name}
                                />
                                <Form.Input
                                    label = {texts.GrpLoc}
                                    placeholder = {texts.GrpLoc}
                                    value = {group.location}
                                    onChange = {handleChange}
                                    name='location'
                                    error = {errors.location}
                                />
                            </Form.Group>
                            {
                                createNew &&
                                <Form.Field
                                    control={DatePicker}
                                    label={texts.fromWhen}
                                    value={timestamp.timestamp}
                                    onChange={(ev,{value})=>setTimestamp({timestamp:value})}
                                    jsTime
                                    maxDate={Date.now() + 86400000 - (Date.now() % 86400000)}      // 1000 * 60 * 60 * 24 = 86400000; der Rest des Tages wird auf das Maximum noch aufaddiert
                                    name='timestamp'
                                    error={timestampErrors.timestamp}
                                />
                                
                            }
                           
                            {  group.gender==='C' || group.gender ==='B' || !config 
                                || !config.HierarchieStageGenderGroups || !config.HierarchieStageGenderGroups[group.hierarchie] || 
                                !config.HierarchieStageGenderGroups[group.hierarchie][group.stage]['C'] ? '' :
                                <Segment secondary>
                                    <Form.Field
                                        label = {texts.editSibling}
                                        placeholder = {texts.sibling}
                                        control = {SearchSelect}
                                        api = "kpe20"
                                        SuggestUrl={`/kpe20/SearchData?types=group&gender=C&hierarchie=${group.hierarchie}`}
                                        Value2TitleUrl={'/kpe20/Value2Title?types=group'}
                                        name = "sgroup"
                                        value = {sgroup.UID}

                                        autoSelect
                                        onChange={(ev,{value})=>{setSGroup({UID:value})}}
                                        error={groupErrors.UID}
                                    />
                                </Segment>
                            }
                            {
                                !createNew &&
                                <Form.Button
                                    content={texts.deleteGroup}
                                    icon='trash'
                                    onClick={deleteGroup}
                                    color='red'
                                />
                            }
                        </>}
                    </>}
                </>}
             
            </Form>
        </Modal.Content>
        {children}

    </>)
     


}
export default EditGroup