import  { useState, useEffect } from 'react';
import {useApi} from '@wollo-lib/kpe-context'

const useObjectAction=(UID)=>
{
    const [objectActions,setObjectActions]=useState([])
    const {apiSelect}=useApi()


    useEffect(() => {
        const getActions=async ()=>
        {
            const result= await apiSelect('/kpe20/action/trigger/'+UID, 'kpe20')
            if(result.success)
            {
                setObjectActions(result.result)
            }
        }
        getActions() 
    }, [UID])

    
    const messageHandler=(value)=>
    {
        if(value.addAction)
        {
            setObjectActions(acs=>([...acs,value.addAction]))
        }
        else if(value.removeAction)
        {
            setObjectActions(acs=>acs.filter(el=>el.UID!==value.removeAction.UID))
        }
        else if(value.changeAction)
        {
            setObjectActions(acs=>acs.map(el=>(el.UID===value.changeAction.UID ? value.changeAction : el)))
        }
    }


    return {objectActions,actionsMessageHandler:messageHandler}


}
export default useObjectAction