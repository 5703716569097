import React, { useState, useEffect, useRef } from 'react';


// form validation function
// values: array of object of values to be validated
// errors: state object of current errors in the form: const [errors,setErrors]= useState{}
// set errors: setter function for errors object
// submitted: false before if form is first submitted. Therefore no errors are generateed, if form not yet submitted
// hasError referenc eobject, telleing if there is any error in teh form: const hasError=useRef(false)
// checks: array of objects defining the checks to be  performed [{name: (index of value array),error: (error text to be displayed or semantic error object), test: (test function with value argument)}]


export const useCheckFormArray=(values,checks)=>
{
    const [errors,setErrors]=useState([]) // array of errors
    const [submitted,setSubmitted] = useState(false)  // is form submitted the first time?
    const hasError=useRef(false)
    useEffect(()=>{
        hasError.current=false
        const errorArray=[]
        let index=0
        for (const valueObject of values)
        {
            const myErrors={}
            checks.forEach(check=>
            {
                if(valueObject[check.name]!==null && valueObject[check.name]!==undefined)
                {
                    if(!check.test(valueObject[check.name],index))
                    {
                        hasError.current=true
                        if(submitted || (check.display && check.display==='always'))
                        {

                            if(myErrors[check.name])
                                myErrors[check.name]+=', '+check.error
                            else
                                myErrors[check.name]=check.error
                        }
                    }
                }
               /* else if(valueObject[check.name]===undefined)
                {
                    hasError.current=true
                    if(submitted || (check.display && check.display==='always'))
                    {

                        if(myErrors[check.name])
                            myErrors[check.name]+=', '+check.error
                        else
                            myErrors[check.name]=check.error
                    }
                    console.warn(`form check: no value of name ${check.name} provided`)
                }*/
            })
            errorArray.push(myErrors)
            ++index
        }
        setErrors(errorArray)
    },[values,submitted])



    // function for submit Chcek
    const checkSubmit =()=>
    {
        if(hasError.current)
        {
            setSubmitted(true)
                return false
        }
        return true
    }

    const getError=(Name,Index)=>
    {
        if(errors[Index])
            return errors[Index][Name] 
        return null
    }
    return {getError:getError,checkSubmit:checkSubmit,hasError:hasError.current,setSubmitted:setSubmitted}
}
