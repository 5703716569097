
import React, { useState, useEffect }  from 'react'
import {Form, Modal} from 'semantic-ui-react'
import { useConfig} from '../../lib20/KPE20context'
import { showDatum,DatePicker} from  '@wollo-lib/wollo-react'
import useTexts from '../../lib20/useTexts'


export const HistoryButton=(props)=>
{
    const {historyTime,setHistoryTime,setModalParams}=props
    const config=useConfig()
    const dateFormat=config.dateFormat
    
    const onClick=()=>{
        setModalParams({
            addLevel: {
                component: ModalHistory,
                historyTime,
                setHistoryTime,
                closeIcon:true
            }
        })
    }
    
    return(
        <Form.Button 
            content={historyTime ? 
            showDatum(historyTime/1000,dateFormat) : 
            showDatum(Date.now()/1000,dateFormat)} 
            onClick={onClick}
            icon='clock'
            color={historyTime ?'grey':'green'}
        />
    )
}

const ModalHistory=(props)=>
{
    const texts=useTexts()
    const {children, modalSubmit, resetModal, modalParams}=props
    const {setHistoryTime,historyTime}=modalParams[0]
    const config=useConfig()

    const changeHistoryTime=async (e,{name,value})=>
    {
        if(name==='reset')
        {
            setHistoryTime(null)
        }
        else
        {
            setHistoryTime(value)
        }
        resetModal()
       
    }
    return(<>
        <Modal.Content>
            <Modal.Header>{texts.changeTimestamp}</Modal.Header>
                <Form>
                    <Form.Field
                        label={texts.TimestampPlaceholder}
                        control={DatePicker}
                        placeholder={texts.TimestampPlaceholder}
                        value={historyTime}
                        name='timestamp'
                        jsTime
                        onChange={changeHistoryTime}
                        maxDate={Date.now()}
                        locale={config.locale}

                    />
                    <Form.Button 
                        label={texts.labelReset}
                        content={texts.latestState}
                        name='reset'
                        onClick={changeHistoryTime}
                    />
                </Form>
            </Modal.Content> 
              

           
    </>)

}
export default HistoryButton