import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Form,Button,Segment,Popup} from 'semantic-ui-react'
import useTexts from '../../../lib20/useTexts'
import FilterElement from '../../../lib20/FilterSources/FilterElement'
import useClipboard from '../../../lib20/useClipboard';


const EditFilter=(props)=>
{

    
    const texts=useTexts('/show/group')


    const {UID,functions,achievements,filterState,setFilterState}=props

    const [clip,setClip,updateClip]=useClipboard({alwaysUpdate:false})


    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo

    })
   
    const changeType=(ev,{name})=>
    {
        setFilterState(old=>({...old, types:{...old.types,[name]:!old.types[name]}}))
    }


  

    const changeAlgo=(ev,{value})=>
    {
        if(JSON.stringify(value)!==JSON.stringify(filterState.filterAlgo))
        setFilterState(old=>({...old,filterAlgo:value}))
    }

    const copyFilter=(ev)=>
    {
        const data={}
        if(filterState.types.person)
            data.person=filterState.filterAlgo
        if(filterState.types.guest)
            data.guest=filterState.filterAlgo
        if(filterState.types.job)
            data.job=filterState.filterAlgo
        setClip({
            type: 'filteredSource',
            data:  { 
                        UIDBelongsTo: UID,
                        Data:data
                    }
        })
    }
    
    return(
    <>
        <Segment>
               
                <Form>
                    <Form.Group>
                        <Form.Checkbox label= {texts.personSource} name='person' checked={filterState.types.person} onClick={changeType} toggle/>
                        <Form.Checkbox label= {texts.guestSource} name='guest' checked={filterState.types.guest}  onClick={changeType} toggle/>
                        <Form.Checkbox label= {texts.jobSource} name='job' checked={filterState.types.job} onClick={changeType} toggle/>
                       
                    </Form.Group>
                    <Form.Group>
                        <Popup
                            trigger={
                                    <Button
                                        icon='copy'
                                        color='blue'
                                        size='midi'
                                        onClick={copyFilter}
                                    />}
                            content={texts.clipboardCopy}
                        />
                        <Form.Field
                            control={FilterElement}
                            onChange={changeAlgo}
                            type={filterState.types.job ?'job':'person'}
                            value={filterState.filterAlgo}
                            functions={functions}
                            achievements={achievements}
                            name='filterAlgo'
                        />
                    </Form.Group>
                </Form>

        </Segment>
        

    </>
    )
}
export default EditFilter