import React,{ useState, useEffect, useRef }  from 'react';
import {Header,Loader,Button,Segment, Message} from 'semantic-ui-react'
import useTexts from '../../lib20/useTexts'
import {usePageSelect,useWindowDimensions,useApi} from '@wollo-lib/kpe-context' //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { bannerFormatter } from '../../lib/formatters'
import MyTabulator, {autoNumFormatter, autoNumEditFormatter,  dateFormatter, dateEditor, formatMoney, editMoney} from '@wollo-lib/wollo-tabulator'
import {useParams} from "react-router-dom";
import {SearchSelect} from "@wollo-lib/wollo-react"
import ModalExtraParameter from '../list/ModalExtraParameter'
import { useHistory } from 'react-router'
import { getExtraCol, getExtraField } from '../list/extraCols';
import { showDatum } from '@wollo-lib/wollo-react';
import { useConfig } from '../../lib20/KPE20context';
import useContextMenu from '../list/useContextMenu'
import Addon from '../../lib/Addon'


const ListResults=(props)=>
{
    const texts=useTexts('/edit/dlist')
    const {list,setModalParams,listOwner,tabRef,listData, isLoading,totalCount, fullRead}=props
    const {UID}= useParams()
    const {width,height}=useWindowDimensions()

    const {apiSelect,  apiUpdate}= useApi()
    const rowContextMenu= useContextMenu({setModalParams,type:'dlist'})
    const tabBuilt=useRef(false)
    const extraFields=useRef([])
    const history=useHistory()
    const config=useConfig()

    
    

    useEffect(()=>
    { 
        // makes sure, that the exra columns are displayed
        if(tabBuilt.current && list)
        {
            const extraCols=[]
            if( list.extraFields)
            {
                list.extraFields.forEach((para,Index)=>
                {
                    if(!extraFields.current.some(f=>f.alias===para.alias))
                        extraFields.current.push(para)
                    extraCols.push(getExtraField(para,texts.locale,texts.decimalPoint,  texts.currencySymbol))

                })
            }
            if( list.extraParameter)
            {
                list.extraParameter.forEach((para,Index)=>
                {
                    extraCols.push(getExtraCol(para,texts.locale,texts.decimalPoint, texts.currencySymbol))
                })
            }
           
            tabRef.current.setColumns([...listColumns,...extraCols])
        }
    },[list, tabBuilt.current])


    const tabClicked=(cell)=>
    { 
        let column=cell.getColumn()
        if(column.getField().match(/^__extra_.*/))
            return
        else
        {
            let row=cell.getRow()
            const rowData=row.getData()
            const extraField= extraFields.current.find(f=>f.alias===column.getField())
            if(extraField?.urlPrefix)
                window.open(extraField.urlPrefix+rowData[extraField.alias])
            else if (extraField?.addonUrl)
            {
                {
                    setModalParams({
                        addLevel:
                        {
                            component: Addon,
                            UID: rowData.UIDBelongsTo,
                            addon:{
                                UID: rowData.UIDBelongsTo,
                                url: extraField.addonUrl
                            },
                            closeIcon: true,
                            size: 'large'
                        
                        }
                    })
                }
            }
            else
                history.push(`/show/person/main/${rowData.UIDBelongsTo}`)
        }
    }

    var dateAccessor = function(value, data, type, params, column){
        return showDatum(value,{format:{year:"numeric", month:"2-digit", day:"2-digit", milliseconds:false, locale:texts.dateLocale}});
    }
    

    const listColumns=[
        
        { 
            title: texts.Name,
            frozen: true,
           
            columns: [
                {formatter:autoNumFormatter, width:50, hozAlign:"right", }, //incrementing row number
              //  {formatter:"buttonCross", width:30,field:'UID', hozAlign:"center", download:false},
                { title:  texts.FirstName,  minWidth: 90, field: 'firstName', maxWidth: 200,  resizable:true,  headerFilter:"input"},
                { title:  texts.LastName, minWidth: 90,  field: 'lastName', maxWidth: 200,   resizable:true,  headerFilter:"input"}
            ]
        },
        {formatter:bannerFormatter,  minWidth: 50, maxWidth: 50, hozAlign:"right", field:'banner',responsive:1, download:false},
        { title:  texts.MemberType, field: 'Title',  minWidth: 85,  headerFilter:"input"},  
        { title:  texts.pGroup, field: 'pGroup',    minWidth: 85, headerFilter:"input"},
        {formatter: dateFormatter,
                formatterParams:{format:{year:"numeric", month:"2-digit", day:"2-digit", milliseconds:false, locale:texts.dateLocale}},
                accessorDownload : dateAccessor, 
                title: texts.Date, field: 'validFrom',  minWidth: 85}
  ]


    const extraEdited=(cell)=>
    {
        const data=cell.getData()
    
        const entry={UID:data.UID}
        for (const d of Object.entries(data).filter(el =>el[0].match(/^__extra_.*/)))
        {
            entry[d[0].replace('__extra_','')]=d[1]
        }
        apiUpdate(`/kpe20/dlist/params/${UID}/${data.UID}`,entry)
    }

    const onScroll=()=>{}

    return(
    <>
        <Segment>
            {
                     fullRead ?  '' :
                    <Message warning>
                        {texts.notFullRead}
                    </Message>
            }
                <Header as='h3' >
                <>
                    {texts.HeaderList}
                    {
                        isLoading ? <Loader active inline size='small'/>
                        : <>:&nbsp;{totalCount}&nbsp;{texts.entryCount}</>
                        
                    }
                </>
                </Header>
                <Button 
                    color='green'
                    content={texts.buttonExtra} 
                    onClick={()=>
                        setModalParams(
                            {addLevel:
                                {
                                    list:list,
                                    type:'dlist',
                                    listOwner:listOwner,
                                    component:ModalExtraParameter}
                                }
                            )
                        }
                />
    
                        
           

           <MyTabulator
                index='UID'
                columns={listColumns}
                rowContextMenu= {rowContextMenu}
                headerFilterPlaceholder={texts.headerFilterPlaceholder}
                data={listData}
                height={Math.max(height-300,350)+'px'}
                compact="very"
                striped
                layout="fitColumns"
               
                replaceMode={true}
                onScroll={onScroll}
                tabRef={tabRef}
                on={[
                    {name:'cellEdited',function:extraEdited},
                    {name: 'tableBuilt', function: ()=>{
                        tabBuilt.current=true}
                    }
                   
                ]}
                onClick={tabClicked}
                rowFormatter={(row)=>{
                    //row - row component
                    var data = row.getData();
                    if(data.UIDBelongsTo === config.orgaUser.UID){
                        row.getElement().style['background-color'] = '#FFFFb0';
                      

                    }
                }}
 
            />
 
        </Segment>
    </>
    )
   
}
export default ListResults